<template>
  <div class="mx-auto">
    <v-card flat class="d-flex flex-column align-center" :disabled="busy">
      <v-card-text style="max-width: 400px">
        <storage-list
          v-if="fromCreatePanel"
          @fetch="storageLoad"
          id="storageList"
          v-model="formValues.storage_id"
        ></storage-list>

        <v-text-field
          id="appName"
          v-model="formValues.name"
          label="App name"
          v-validate="'required|max:27|min:3|name_field_validation'"
          v-bind="veeValidate('Name', '')"
          counter="27"
          filled
          placeholder="Your app name"
          messages="Can be renamed later"
        ></v-text-field>

        <!-- the filled and auto-grow prop is important -->
        <member-select
          v-if="fromCreatePanel"
          v-model="selectedMembers"
          @isInvite="inviteMember = $event"
          :app="null"
          @selected="selectedOption = $event"
        />
      </v-card-text>
      <v-btn
        :loading="busy"
        :disabled="errors.any() || busy || !formValues.name || busyStorage"
        class="main wide primary large mx-auto iconic"
        id="appSubmitButton"
        @click="onSubmit"
      >
        {{ fromCreatePanel ? "Create" : "Next" }}
        <img
          v-if="!fromCreatePanel"
          src="/media/svg/icon-arrow-right-light.svg"
          alt
          class="ml-3"
          height="20"
        />
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import veeValidate from "@/mixins/veeValidate";
import { CREATE } from "@/store/apps/createApp.module";
import StorageList from "@/view/components/App/StorageList.vue";
import { mapGetters, mapMutations } from "vuex";
import MemberSelect from "@/view/components/Common/MemberSelectSection";
import { currentTimestamp } from "@/core/services/helper.service";
import { ADD_INVITE } from "@/store/apps/app.module";
import { Validator } from "vee-validate";

Validator.extend("name_field_validation", {
  getMessage: (field) => {
    return `${field} should not include any special characters `;
  },
  // Returns a boolean value
  validate: (value) => {
    const regex = new RegExp("^[0-9A-Za-z(-_.)ds ]+$");
    return regex.test(value);
  },
});

export default {
  components: { StorageList, MemberSelect },
  mixins: [veeValidate],
  props: {
    fromCreatePanel: {
      type: Boolean,
      default: false,
    },
    newAppName: {
      type: String,
      default: null,
    },
    fromGetStarted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busyStorage: true && this.fromCreatePanel,
      formValues: {
        storage_id: "",
        name: "",
      },
      selectedMembers: [],
      err: false,
      inviteMember: false,
      selectedOption: "Current",
      sharePublic: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
    publicApp() {
      return this.getTeamPlanUsage && this.getTeamPlanUsage.public_app;
    },
  },
  watch: {
    newAppName(val) {
      this.formValues.name = val;
    },
  },
  mounted() {
    Vue.prototype.$analytics.logEvent("create_app_start");
    this.formValues.name = this.newAppName || "";
  },
  methods: {
    ...mapMutations({
      setApps: "setApps",
      appendAppStats: "appendAppStats",
      addAppToAutocomplete: "addAppToAutocomplete",
    }),
    storageLoad(e) {
      this.busyStorage = e;
    },
    async onSubmit() {
      if (await this.validateAllFields()) {
        if (this.busy) return;

        this.busy = true;
        const name = this.formValues.name;
        this.$store
          .dispatch(CREATE, {
            name,
            storage_id: this.formValues.storage_id,
          })
          .then((response) => {
            this.busy = false;
            this.$emit("close");
            this.notifyUserMessage({
              message: response.message || "Successfully created the app",
            });
            if (this.inviteMember) {
              this.addMember(response.id);
            } else if (this.fromCreatePanel && !this.inviteMember) {
              if (this.$route.name !== "releases") {
                this.$router
                  .push({
                    name: "app-releases",
                    params: {
                      app_id: response.id,
                    },
                  })
                  .catch((err) => {
                    this.err = err;
                  });
              }
            }
            this.appendAppStats({ appCount: 1 });
            const appDetail = {
              name: name,
              image: "",
              id: response.id,
              created_at: currentTimestamp(),
              platforms: [],
            };
            this.addAppToAutocomplete(appDetail);
            this.setApps(appDetail);
            this.$emit("goNext", {
              id: response.id,
              name: name,
              image: "",
              platforms: [],
            });
            Vue.prototype.$analytics.logEvent("create_app");
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(
              err.message || "Create app failed, please try again"
            );
            Vue.prototype.$analytics.logEvent("create_app_error");
          });
      }
    },
    addMember(id) {
      Vue.prototype.$analytics.logEvent("add_member_start");

      const dataToPost =
        this.selectedOption === "Specific"
          ? {
              app_id: id,
              details: {
                add_all_team_members: false,
                ids: this.selectedMembers.map((x) => x.id),
              },
            }
          : {
              app_id: id,
              details: {
                add_all_team_members: true,
              },
            };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then((response) => {
          this.notifyUserMessage(response.message || "Invited Successfully");
          this.drawer = false;
          if (this.$route.name !== "releases") {
            this.$router
              .push({
                name: "app-releases",
                params: {
                  app_id: id,
                },
              })
              .catch((err) => {
                this.err = err;
              });
          }
          Vue.prototype.$analytics.logEvent("add_member");
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(
            err.message || "Invite failed, please try again later"
          );
          if (this.$route.name !== "releases") {
            this.$router
              .push({
                name: "app-releases",
                params: {
                  app_id: id,
                },
              })
              .catch((err) => {
                this.err = err;
                this.busy = false;
              });
          }
          Vue.prototype.$analytics.logEvent("add_member_error");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
