<template>
  <div>
    <div v-if="infoError">
      <noData :firstText="infoError" permission-issues />
    </div>
    <div v-else>
      <div class="p-10">
        <v-timeline align-top dense v-if="timelineLoading">
          <v-timeline-item color="grey lighten-1" small v-for="i in 4" :key="i">
            <v-skeleton-loader
              type="article"
              class="transparent mx-n4 mt-n3"
              style="max-width: 300px"
            />
          </v-timeline-item>
        </v-timeline>
        <noData
          v-else-if="errorMessage"
          :first-text="errorMessage"
          permission-issues
        />
        <noData v-else-if="!teamTimeline" />

        <v-timeline align-top dense v-else>
          <v-timeline-item
            color="grey"
            small
            v-for="item in teamTimeline"
            :key="item.id"
          >
            <!-- <v-row class="pt-1" no-gutters>
              <v-col cols="12">
                <v-skeleton-loader
                  cols="12"
                  type="article"
                  class="transparent mb-2 mx-n4"
                  style="width:300px"
                />
              </v-col>
            </v-row> -->
            <v-row class="pt-1" no-gutters>
              <v-col cols="12" class="mb-2">
                <b>
                  <vue-hoverable-date
                    :date="item.created_at"
                  ></vue-hoverable-date>
                </b>
              </v-col>
              <v-col cols="12" class="mb-2">
                <div class="bodyFont">
                  {{ item.title }}
                </div>
                <div class="bodyFont mt-2">
                  <div v-if="item.action === 'member_invite'">
                    {{ item.user.name }} accepted the invite
                  </div>
                  <div>
                    {{ item.content }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

        <!--  -->
      </div>
      <div style="display: flex; justify-content: end">
        <v-pagination
          flat
          class="ml-auto"
          v-model="page"
          :length="totalLength || 0"
          :disabled="getTotalTeamTimeLineCount < 10"
          :total-visible="0"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import { getTimelineTime } from "@/core/services/helper.service";
import { GET_TEAM_TIMELINE } from "@/store/team/timeline.module.js";

export default {
  name: "app-timeline",
  props: {
    teamId: {
      type: String,
      default: "",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    infoError: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoaded: false,
      timelineLoading: false,
      page: 1,
      errorMessage: null,
      getTimelineTime,
    };
  },
  computed: {
    ...mapGetters({
      teamTimeline: "getTeamTimeline",
      getTotalTeamTimeLineCount: "getTotalTeamTimeLineCount",
      totalTeamTimelineList: "getTotalTeamTimelineList",
    }),
    noData() {
      return isEmpty(this.timeline) ? true : false;
    },
    totalLength() {
      return Math.ceil(this.getTotalTeamTimeLineCount / 10);
    },
    noInfo() {
      return isEmpty(this.info) ? true : false;
    },
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalTeamTimelineList.length > oldVal * 10) {
          this.setPreviousTeamTimelineData(
            this.totalTeamTimelineList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.teamTimeline[this.teamTimeline.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousTeamTimelineData(
          this.totalTeamTimelineList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    if (this.noData) {
        this.loadData();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousTeamTimelineData: "setPreviousTeamTimelineData",
    }),
    loadData(lastId) {
      this.errorMessage = null;
      if (!this.infoError) {
        if (this.teamTimelineLoading) return;
        this.teamTimelineLoading = true;
        this.$store
          .dispatch(GET_TEAM_TIMELINE, {
            teamId: this.teamId,
            lastId: lastId || "",
          })
          .then(() => {
            this.teamTimelineLoading = false;
          })
          .catch((err) => {
            this.errorMessage = err.message;
            this.teamTimelineLoading = false;
          });
      }
    },
  },
};
</script>
