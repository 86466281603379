<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <div>The ANSWER TO</div>
      <div class="font-size-h2 bold-text">Frequently Asked Questions</div>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col v-for="(item, index) in faqList" :key="index" cols="12" md="6">
          <v-row align="center">
            <v-col cols="auto">
              <v-avatar>
                <v-icon
                  color="primary"
                  v-text="'mdi-file-document-outline'"
                ></v-icon>
              </v-avatar>
            </v-col>
            <v-col>
              <div class="font-size-h3 bold-text">
                {{ item.title }}
              </div>
              <div class="dark-grey-text" v-html="item.description"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      faqList: [
        {
          title: "Is credit card required to start?",
          description: `No, you can signup for free and start distributing with your hobby plan.<br/><br/>
          If you have big apps and want to try our app distribution, you can always download our sample apps which you can find them in create release page to try it out for both Android and iOS.`,
        },
        {
          title: "What is the max app size?",
          description: `It's the size of the APK (Android) and IPA (iOS) that will be allowed to be uploaded.<br/><br/>
          If you need more, you can always upgrade or contact us for special size to provide you as add-on.`,
        },
        {
          title: "Can I transfer my team?",
          description: `Yes, you can invite members with Owner role. Multiple owners in the same team is possible.`,
        },
        {
          title: "What happens if exceed the storage limit?",
          description: `Don't worry, you can always contact us to give you add-on if needed or you can archive older releases.`,
        },
        {
          title: "Can I cancel?",
          description: `Yes! You can cancel at any time and the cancellation date will be effective on the next billing cycle.`,
        },
        {
          title: "What options of payments do you support?",
          description: `Currently: Card, PayPal and Apple Pay`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
