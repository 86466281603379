<template>
  <div>
    <div v-if="getInactiveUserMessage">
      <no-data :first-text="getInactiveUserMessage"></no-data>
    </div>
    <div v-else>
      <v-row no-gutters>
        <v-col v-if="canViewStorage" cols="12">
          <v-skeleton-loader
            class="transparent"
            type="table-thead, table-tbody"
            v-if="dataLoader"
          />
          <v-data-table
            v-else
            :headers="headers"
            :items="storageList"
            :expanded.sync="expanded"
            single-expand
            show-expand
            class="v-card v-sheet v-sheet--outlined radius-10"
            hide-default-footer
            @click:row="expendRow"
          >
            <template #item.icon="{ item }">
              <v-img
                height="32"
                width="32"
                contain
                :src="getStorageImg(item.icon)"
                :alt="item.name"
              />
            </template>

            <template #item.icon="{ item }">
              <v-img
                height="32"
                width="32"
                contain
                :src="getStorageImg(item.icon)"
                :alt="item.name"
              />
            </template>
            <template #item.usage="{ item }">
              {{ formatBytes(item.meta ? item.meta.storage : 0) }}
            </template>
            <template #item.limit="{ item }">
              {{
                item.meta.limit == 0 || !item.meta.limit
                  ? "Unlimited"
                  : formatBytes(item.meta.limit)
              }}
            </template>
            <template #item.apps="{ item }">
              {{ item.meta.total_apps }}
            </template>
            <template #item.releases="{ item }">
              {{ item.meta.total_releases }}
            </template>
            <template #item.data-table-expand="{ item, expand, isExpanded }">
              <v-icon v-if="item.value">mdi-minus</v-icon>
              <v-btn icon v-else @click.stop="expand(!isExpanded)">
                <v-icon
                  v-text="isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                />
              </v-btn>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-0">
                <v-card tile flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-icon v-text="'mdi-chevron-right'" size="20"></v-icon>
                        Origin:
                        <b>{{ item.name }}</b>
                      </v-col>
                      <v-col cols="4">
                        <v-icon v-text="'mdi-chevron-right'" size="20"></v-icon>
                        Region: <b>{{ item.region || "-" }}</b>
                      </v-col>
                      <v-col cols="4">
                        <v-icon v-text="'mdi-chevron-right'" size="20"></v-icon>
                        Endpoint: <b>{{ item.endpoint || "-" }}</b>
                      </v-col>
                      <v-col cols="4">
                        <v-icon v-text="'mdi-chevron-right'" size="20"></v-icon>
                        Key: <b>{{ item.key || "-" }}</b>
                      </v-col>
                      <v-col cols="4">
                        <v-icon v-text="'mdi-chevron-right'" size="20"></v-icon>
                        Secret: <b>{{ item.secret || "-" }}</b>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          small
                          @click="
                            [(currentStorage = item), (showRemoveModal = true)]
                          "
                          depressed
                          class="white--text font-weight-bold text-capitalize"
                          color="red"
                        >
                          Remove
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </template>
            <template slot="no-data">
              <no-data
                v-if="!(getTeamPlanUsage && getTeamPlanUsage.connect_storage)"
                first-text="Connecting external storage available for Starter & Pro plans."
              ></no-data>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else class="no-data-found">
          <no-data
            first-text="No storage to show"
            second-text="It seems that you don't have the permission to manage the team storage"
          ></no-data>
        </v-col>
        <SidePanel v-model="showStorageForm">
          <create-form
            v-if="showStorageForm"
            @refresh="showStorageForm = false"
          />
        </SidePanel>

        <Modal v-model="showRemoveModal">
          <template #message>
            <!-- add your content here -->
            <!-- for the title -->
            <v-card-title class="font-weight-bold">Remove storage</v-card-title>

            <!-- for the body -->
            <v-card-text class="message">
              <p>Removing a storage you agree for removing the following:</p>
              <div>
                <ul>
                  <li>
                    Apps (<span class="text-red">{{
                      get(currentStorage, "meta.total_apps", 0)
                    }}</span
                    >)
                  </li>
                  <ul>
                    <li>
                      Releases (<span class="text-red">{{
                        get(currentStorage, "meta.total_releases", 0)
                      }}</span
                      >)
                    </li>
                    <li>Timeline</li>
                    <li>Members</li>
                    <li>Chat</li>
                  </ul>

                  <li>Storage</li>
                  <ul>
                    <li>Name: {{ currentStorage.name }}</li>
                    <li>Bucket: {{ currentStorage.bucket }}</li>
                    <li>Region: {{ currentStorage.region }}</li>
                  </ul>
                </ul>
              </div>
              <br />
              <p>Are you sure want to proceed?</p>
            </v-card-text>
            <v-divider />

            <!-- and for the actions, submit... -->
            <v-card-actions class="pb-0">
              <v-spacer />
              <v-btn class="main" @click="showRemoveModal = false">no</v-btn>
              <v-btn
                class="main primary"
                @click="deleteStorage"
                :loading="busy"
              >
                yes
              </v-btn>
            </v-card-actions>
          </template>
        </Modal>
        <Modal
          v-model="showStartTrialModal"
          @close="showStartTrialModal = false"
          width="550"
        >
          <template #message>
            <start-trial-modal
              v-if="showStartTrialModal"
              message="Connecting external storage is available for Starter and Pro plans"
              :tracker-event="trackerEvent"
              from-connect-storage
              @close="showStartTrialModal = false"
              @open-subscribe="openSubscriptionModal = true"
            ></start-trial-modal>
          </template>
        </Modal>
        <Modal v-model="showUpgradeInfo" @close="showUpgradeInfo = false">
          <template #message>
            <upgrade-plan
              title="Connect External Storage"
              info-text="add external storage to your team"
              from-external-storage
              @close="showUpgradeInfo = false"
            >
              <template>
                <div>
                  Do you need more storage size? Connect your external cloud
                  storage.<br /><br />

                  <b>The benefits:</b> <br /><br />

                  - Unlimited releases<br />
                  - No release expiry date<br />
                  - No bandwidth restrictions<br />

                  <br />This is free of charge from our end but charges will
                  apply from the cloud storage provider.
                </div>
              </template>
            </upgrade-plan>
          </template>
        </Modal>
      </v-row>
    </div>
  </div>
</template>

<script>
import { GET_STORAGE, DELETE_STORAGE } from "@/store/team/storage.module";
import CreateForm from "@/view/components/Team/Storage/CreateForm";
import { formatBytes, isEmpty, get } from "@/core/services/helper.service";
import UpgradePlan from "@/view/components/Team/UpgradePlan.vue";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    bucket: {
      type: String,
      default: "",
    },
    usage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  components: { CreateForm, StartTrialModal, UpgradePlan },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      expanded: [],
      dataLoader: false,
      showStartTrialModal: false,
      showUpgradeInfo: false,
      headers: [
        {
          text: "",
          sortable: false,
          align: "start",
          width: "50px",
          value: "icon",
        },
        {
          text: "Name",
          sortable: false,
          align: "start",
          value: "name",
        },
        {
          text: "Bucket",
          sortable: false,
          align: "start",
          value: "bucket",
        },
        {
          text: "Usage",
          sortable: false,
          align: "start",
          value: "usage",
        },
        {
          text: "Limit",
          sortable: false,
          align: "start",
          value: "limit",
        },
        {
          text: "Apps",
          sortable: false,
          align: "start",
          value: "apps",
        },
        {
          text: "Releases",
          sortable: false,
          align: "start",
          value: "releases",
        },
        {
          text: "Action",
          sortable: false,
          align: "start",
          value: "data-table-expand",
        },
      ],
      showRemoveModal: false,
      currentStorage: {},
      trackerEvent: {},
      defaultStorage: {},
      busy: false,
      showStorageForm: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      externalStorageList: "getExternalStorageList",
      getTeamPlanUsage: "getTeamPlanUsage",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    storageList() {
      if (!isEmpty(this.defaultStorage)) {
        return [this.defaultStorage, ...this.externalStorageList];
      }
      return [];
    },
  },
  watch: {
    getTeamPlanUsage(val) {
      if (val && val.connect_storage) {
        this.getStorageList();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (
        this.getTeamPlanUsage &&
        this.getTeamPlanUsage.connect_storage &&
        !this.getInactiveUserMessage
      ) {
        if (this.canViewStorage) {
          this.getStorageList();
        }
      }
    });
  },
  methods: {
    ...mapMutations({
      removeStorageStats: "removeStorageStats",
    }),
    deleteStorage() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_STORAGE, this.currentStorage)
        .then((response) => {
          this.removeStorageStats({
            app: this.currentStorage.meta.total_apps,
            releases: this.currentStorage.meta.total_releases || 0,
            storage: this.currentStorage.meta.storage || 0,
          });
          this.notifyUserMessage(
            response.message || "Storage successfully removed"
          );
          this.busy = false;
          this.showRemoveModal = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "something went wrong!");
          this.busy = false;
          this.showRemoveModal = false;
        });
    },
    getStorageList() {
      if (this.dataLoader) return;
      this.dataLoader = true;
      this.$store
        .dispatch(GET_STORAGE)
        .then((response) => {
          this.defaultStorage = {
            name: "TestApp.io",
            icon: "testapp.io.svg",
            value: "default",
            bucket: "-",
            meta: {
              storage: response.internal.storage || 0,
              limit: this.getTeamPlanUsage.storage,
              total_apps: response.internal.total_apps,
              total_releases: response.internal.total_releases,
            },
          };
          this.dataLoader = false;
        })
        .catch(() => {
          this.dataLoader = false;
        });
    },
    openStorageForm() {
      if (!(this.getTeamPlanUsage && this.getTeamPlanUsage.connect_storage)) {
        this.trackerEvent = {
          event: "Connect Storage",
          current: "",
          requested: "",
        };
        this.showStartTrialModal = true;
      } else {
        this.showStorageForm = true;
      }
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-red {
  color: #e75146;
}
</style>
