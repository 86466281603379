<template>
  <div>
    <div v-if="getInactiveUserMessage">
      <no-data :first-text="getInactiveUserMessage"></no-data>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12">
          <v-row>
            <template v-if="loading">
              <v-col v-for="i in 2" :key="i">
                <v-skeleton-loader
                  max-width="300"
                  type="card"
                ></v-skeleton-loader>
              </v-col>
            </template>
            <template v-if="!canManageTeam">
              <no-data
                first-text="No integrations to show"
                second-text="It seems that you don't have the permission to view the integrations"
              ></no-data>
            </template>
            <template
              v-if="!loading && searchServiceList.length && canManageTeam"
            >
              <v-col class="pb-0" cols="12">
                <div class="mb-8">
                  <v-icon small left>mdi-help-circle</v-icon>
                  <span>We missed an integration that you need?</span>
                  Check our
                  <a href="https://roadmap.testapp.io/" target="_blank"
                    >public roadmap</a
                  >
                  to suggest one or
                  <span
                    @click="onContactus"
                    class="primary--text cursor-pointer"
                    >contact us</span
                  >.
                </div>
                <!-- <v-text-field
              v-model="searchText"
              placeholder="Search"
              outlined
              class="mt-8"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field> -->
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-for="(item, index) in searchServiceList"
                :key="index"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? '3' : ''"
                    outlined
                    height="100%"
                    @click="openLink(item.linkName, item.title)"
                    :id="item.title"
                    class="cursor-pointer pa-3"
                  >
                    <v-row>
                      <v-col class="text-right" cols="12">
                        <v-chip
                          v-if="item.title !== 'Slack'"
                          x-small
                          class="mx-1 bold-text"
                          :color="item.status ? 'success' : item.labels.color"
                          label
                        >
                          {{ item.status ? "Enabled" : item.labels.label }}
                        </v-chip>
                        <v-switch
                          v-else
                          v-model="slackStatus"
                          hide-details
                          readonly
                          @click.stop="startSlackIntegration"
                          style="float: right"
                          class="mb-0 pb-0 mr-auto"
                          :color="slackStatus ? 'success' : 'error'"
                        >
                          <template slot="label">
                            <span
                              :class="
                                slackStatus ? 'success--text' : 'error--text'
                              "
                            >
                              {{ slackStatus ? "Enabled" : "Disabled" }}
                            </span>
                          </template>
                        </v-switch>
                      </v-col>
                      <v-col cols="12">
                        <div>
                          <div>
                            <v-img
                              class="mb-2"
                              height="40"
                              contain
                              width="40"
                              v-if="item.img"
                              :src="item.img"
                            ></v-img>
                            <v-icon
                              v-else
                              class="mb-1"
                              size="40"
                              color="primary"
                              v-text="item.icon"
                            ></v-icon>
                          </div>
                          <div class="mx-1 bold-text font-size-h3">
                            {{ item.title }}
                          </div>
                          <div class="mx-1 text-muted">
                            {{ item.descriptions }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
            <template
              v-if="!loading && !searchServiceList.length && canManageTeam"
            >
              <v-col cols="12">
                <no-data :first-text="`No matching record found`"></no-data>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <Modal
          :fullscreen="$vuetify.breakpoint.smAndDown"
          :width="$vuetify.breakpoint.mdAndUp ? '800' : ''"
          v-model="slackInfoPopup"
          @close="slackInfoPopup = false"
        >
          <template #message>
            <v-row align="center">
              <v-col cols="12" md="6">
                <v-img src="/media/instruction/slack_integration.png"></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <v-row class="pt-8">
                  <v-col class="font-size-h3 black--text bold-text" cols="12">
                    Enable slack integration
                  </v-col>
                  <v-col class="pt-0 bodyFont" cols="12">
                    <div>
                      By connecting your Slack, your channel will be notified
                      about important events in your teams<br /><br />

                      <b>Events:</b> <br /><br />

                      <b>Team</b><br />
                      - Member accept or decline team invite<br />
                      - Removing & leaving team<br />
                      - Deleting team<br /><br />

                      <b>App</b><br />
                      - New app<br />
                      - Update app info<br />
                      - Adding member to app<br />
                      - Removing member from app<br />
                      - Removing & leaving member from app<br />
                      - Deleting app<br /><br />

                      <b>Release</b><br />
                      - New release<br />
                      - Updating release notes<br />
                      - Moving release between apps<br />

                      <br />Keep your team updated with Slack integrations!
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  v-if="!slackStatus && isPaidUser"
                  color="success"
                  class="text-transform-none"
                  @click="startSlackIntegration"
                >
                  Enable
                </v-btn>
                <v-btn
                  v-if="slackStatus && isPaidUser"
                  color="error"
                  class="text-transform-none"
                  @click="startSlackIntegration"
                >
                  Disable
                </v-btn>
                <v-btn
                  v-if="!showTrailButton && !isPaidUser"
                  depressed
                  class="text-right text-transform-none"
                  @click="openSubscriptionModal = true"
                  color="primary"
                >
                  Upgrade Plan
                </v-btn>
                <v-btn
                  v-if="showTrailButton && !isPaidUser"
                  depressed
                  class="text-right text-transform-none"
                  @click="openSubscriptionModal = true"
                  color="primary"
                >
                  Start free trial
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </Modal>
        <Modal
          v-model="popupDisabledDialog"
          @close="popupDisabledDialog = false"
        >
          <template #message>
            <v-row class="py-4">
              <v-col class="text-left text-h5 mt-4" cols="12">
                Oops! Seems like you have disabled popups for this site.
              </v-col>
              <v-col class="text-left text-h6" cols="12">
                Follow below steps to enable popups for this site.
                <ul>
                  <li>Go to your browser settings</li>
                  <li>Click on advanced setting or site permission settings</li>
                  <li>Click on popups and redirection</li>
                  <li>And allow for this site</li>
                </ul>
                Or
                <div>
                  <ul>
                    <li>
                      You can see block icon in your address bar. You can enable
                      from there.
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  class="text-transform-none"
                  color="primary"
                  depressed
                  @click="popupDisabledDialog = false"
                >
                  Ok, got it
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </Modal>
        <Modal
          :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          @close="openSubscriptionModal = false"
          v-model="openSubscriptionModal"
        >
          <template #message>
            <plan-pricing
              from-modal
              from-upgrade-component
              :upgrade-plan-text="getUpgradePlanText()"
              @success="$emit('close'), (openSubscriptionModal = false)"
              @close="openSubscriptionModal = false"
            ></plan-pricing>
          </template>
        </Modal>
        <Modal v-model="confirmationModal" @close="confirmationModal = false">
          <template #message>
            <v-row class="py-4">
              <v-col class="text-center text-h6" cols="12">
                You are about to start the 7-day free trial for the
                <div>
                  <b id="selectedPlanName"
                    >Starter <span class="text-capitalize">(Monthly) </span></b
                  >plan
                </div>
              </v-col>
              <v-col class="text-center pb-0" cols="12">
                <v-btn
                  id="upgradePlanBtn"
                  @click="startFreeTrail"
                  class="text-transform-none"
                  color="primary"
                  :loading="busy"
                  depressed
                >
                  Let's do it 💪
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </Modal>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  GENERATE_SLACK_AUTH_LINK,
  GET_INTEGRATION_LIST,
  DISABLE_SLACK,
} from "@/store/team/integrations.module.js";
import PlanPricing from "@/view/components/Team/PlanPricing";
import { isEmpty } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import { START_FREE_TRIAL } from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
export default {
  components: { PlanPricing },
  data() {
    return {
      busy: true,
      searchText: "",
      loading: false,
      slackEnabled: false,
      slackStatus: false,
      slackPopupWindow: "",
      confirmationModal: false,
      openSubscriptionModal: false,
      slackInfoPopup: false,
      popupDisabledDialog: false,
      showUpgradeInfo: false,
      serviceList: [
        {
          title: "TA-CLI",
          descriptions:
            "TestApp.io CLI to automate your releases with a single command line for any app project and both Android & iOS",
          img: "/media/logos/icon-loading.png",
          linkName: "https://github.com/testappio/cli",
          status: 1,
        },
        // {
        //   title: "Email",
        //   icon: "mdi-email",
        //   descriptions: "Send your data to the email",
        //   labels: [
        //     { label: "Enabled", color: "light-green" },
        //     { label: "Api", color: "grey" },
        //   ],
        // },
        {
          title: "Slack",
          value: "slack",
          img: "/media/svg/slack.svg",
          color: "red darken-5",
          linkName: "slack-integration",
          descriptions:
            "Connect your Slack to get your team notified about important events to stay up to date",
        },
        {
          title: "Microsoft Teams",
          value: "microsoft",
          img: "/media/logos/microsoftteams.png",
          color: "red darken-5",
          linkName: "microsoft-team",
          // descriptions:
          //   "Connect your Slack to get your team notified about important events to stay up to date",
          labels: { label: "Coming soon", color: "primary" },
        },
        {
          title: "Fastlane",
          value: "fastlane",
          img: "/media/svg/fastlane.svg",
          color: "red darken-5",
          status: 0,
          linkName: "fast-lane",
          labels: { label: "Planned", color: "info" },
          // descriptions:
          //   "Connect to fastlane which is the easiest way to automate building and releasing your iOS and Android apps",
        },
        {
          title: "Jira",
          value: "jira",
          icon: "mdi-jira",
          color: "red darken-5",
          linkName: "jira-team",
          // descriptions:
          //   "Connect your Slack to get your team notified about important events to stay up to date",
          labels: { label: "In review", color: "warning" },
        },
        {
          title: "Jenkins",
          value: "Jenkins",
          img: "/media/logos/jenkins.png",
          color: "red darken-5",
          status: 0,
          linkName: "jenkins",
          labels: { label: "In review", color: "warning" },
          // descriptions:
          //   "Connect to Jenkins which is the easiest way to automate building and releasing your iOS and Android apps",
        },
        {
          title: "Circle CI",
          value: "circle-cli",
          img: "/media/logos/circleci.png",
          color: "red darken-5",
          status: 0,
          linkName: "circle-ci",
          labels: { label: "In review", color: "warning" },
          // descriptions:
          //   "Connect to Circle CI which is the easiest way to automate building and releasing your iOS and Android apps",
        },
        {
          title: "Travis CI",
          value: "travis-cli",
          img: "/media/svg/travis_ci.svg",
          color: "red darken-5",
          labels: { label: "In review", color: "warning" },
          status: 0,
          linkName: "circle-ci",
          // descriptions:
          //   "Connect to Travis CI which is the easiest way to automate building and releasing your iOS and Android apps",
        },
        {
          title: "Gitlab CI/CD",
          value: "github-ci-cd",
          icon: "mdi-gitlab",
          color: "red darken-5",
          status: 0,
          labels: { label: "In review", color: "warning" },
          linkName: "github-ci-cd",
          // descriptions:
          //   "Connect to Gitlab CI/CD which is the easiest way to automate building and releasing your iOS and Android apps",
        },
        {
          title: "Github Actions",
          value: "github-action",
          icon: "mdi-git",
          color: "red darken-5",
          status: 0,
          labels: { label: "In review", color: "warning" },
          linkName: "github-ci-cd",
          // descriptions:
          //   "Connect to Gitlab Action which is the easiest way to automate building and releasing your iOS and Android apps",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTeamPlanUsage: "getTeamPlanUsage",
      getTeamTrial: "getTeamTrial",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    showTrailButton() {
      return !!(this.getTeamTrial && !this.getTeamTrial.status);
    },
    searchServiceList() {
      return this.serviceList.filter((label) =>
        label.title.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    isPaidUser: {
      get: function () {
        return this.getTeamPlanUsage && this.getTeamPlanUsage.integrations;
      },
      set: function () {},
    },
  },
  created() {
    if (!this.getInactiveUserMessage && this.canManageTeam) {
      this.getIntegrationsList();
    }
  },
  methods: {
    getIntegrationsList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_INTEGRATION_LIST)
        .then((response) => {
          let data =
            response.data && response.data.length ? response.data[0] : null;
          this.serviceList[1].status = data ? data.status : "";
          this.slackStatus = !!(data && data.status === 1);
          this.slackEnabled = !!(data && data.status === 1);
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    getUpgradePlanText() {
      return `${
        this.showTrailButton ? "Start your team's free trial" : "Upgrade now"
      }
         to enable slack integration and much more.`;
    },
    startSlackIntegration() {
      this.slackEnabled = !this.slackEnabled;
      if (this.slackEnabled) {
        if (this.isPaidUser) {
          this.generateSlackAuthLink();
        } else {
          if (isEmpty(this.getTeamTrial)) {
            this.confirmationModal = true;
            window.analytics.track("Viewed trial popup", {
              event: "Slack integration",
            });
          } else {
            this.slackInfoPopup = true;
            window.analytics.track("Viewed upgrade popup", {
              event: "Slack integration",
            });
          }
        }
      } else {
        this.disableSlackIntegration();
      }
      this.slackEnabled = false;
    },
    openLink(link, title) {
      if (link !== "slack-integration" && title == "TA-CLI") {
        window.open(link, "_blank");
      } else if (link == "slack-integration") {
        this.slackInfoPopup = true;
      }
    },
    generateSlackAuthLink() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GENERATE_SLACK_AUTH_LINK)
        .then((response) => {
          this.confirmationModal = false;
          this.slackInfoPopup = false;
          window.open(response.url, "_self");
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    startFreeTrail() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: "V3GoPO2xXy" })
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.$store
            .dispatch(GET_USER_ROLES)
            .then(() => {
              this.generateSlackAuthLink();
            })
            .catch((err) => {
              this.notifyErrorMessage(err.message);
            });
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    disableSlackIntegration() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DISABLE_SLACK)
        .then((response) => {
          this.loading = false;
          this.notifyUserMessage(response.message);
          this.slackInfoPopup = false;
          this.getIntegrationsList();
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>
