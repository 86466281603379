<template>
  <v-row justify="center">
    <!-- <v-col cols="12" md="4" lg="3">
      <team-info @appendUsage="(d) => (usage = d)" />
    </v-col> -->
    <v-col cols="12" md="12" lg="12">
      <div class="tab-parent" style="width: 100%">
        <v-tabs v-model="tab" class="mb-6">
          <v-tab> members</v-tab>
          <span class="mx-4"></span>
          <v-tab @click="loadEmailInvites" ref="invites"> invites</v-tab>
          <span class="mx-4"></span>
          <v-tab> Storage</v-tab>
          <span class="mx-4"></span>
          <v-tab id="plans-nav" ref="invites"> Plans</v-tab>
          <span class="mx-4"></span>
          <v-tab id="integration-nav"> Integrations </v-tab>
          <!--          <span class="mx-4"></span>-->
          <!--          <v-tab id="timeline-nav"> Timeline </v-tab>-->
        </v-tabs>
        <v-tabs-items v-model="tab" width="100%">
          <!--  -->
          <!-- the first item in the tab -->
          <v-tab-item>
            <div class="fill-width">
              <Members :team="teamError" @makeActive="setActiveTab" />
              <v-btn
                v-if="inviteProcess"
                @click="$emit('next-step')"
                class="main primary mx-auto mt-13"
              >
                Next!
              </v-btn>
            </div>
          </v-tab-item>
          <!-- the second item in the tab -->
          <v-tab-item>
            <div>
              <div v-if="getInactiveUserMessage">
                <no-data :first-text="getInactiveUserMessage"></no-data>
              </div>
              <div v-else>
                <div v-if="canViewInvite">
                  <div class="invites">
                    <v-row no-gutters class="mb-4">
                      <v-col>
                        <v-btn
                          rounded
                          :class="
                            activeTab == 'email'
                              ? 'primary white--text'
                              : 'grey primary--text'
                          "
                          @click="activeTab = 'email'"
                          class="mr-3 text-capitalize"
                        >
                          <v-icon
                            :color="activeTab === 'email' ? 'white' : 'primary'"
                            left
                            small
                            >mdi-email-outline
                          </v-icon>
                          by email
                        </v-btn>
                        <v-btn
                          rounded
                          :class="
                            activeTab == 'url'
                              ? 'primary white--text'
                              : 'grey primary--text'
                          "
                          value="url"
                          class="text-capitalize ma-2"
                          @click="loadUrlInvites"
                        >
                          <v-icon
                            small
                            left
                            :color="activeTab == 'url' ? 'white' : 'primary'"
                          >
                            link
                          </v-icon>
                          by URL
                        </v-btn>
                      </v-col>
                      <v-col v-if="canManageInvite" class="d-flex">
                        <v-spacer />
                        <InviteMembersPanel
                          :active="!!$route.query.showOption"
                          @makeActive="setActiveTab"
                        />
                      </v-col>
                    </v-row>
                    <v-tabs v-model="activeTab" hide-slider>
                      <v-tab-item id="email">
                        <invites
                          :data="emailInvites"
                          :total-count="getEmailTotalCount"
                          :page.sync="emailPage"
                          :loader="loading"
                        />
                      </v-tab-item>
                      <v-tab-item id="url">
                        <invites
                          :data="urlInvites"
                          :total-count="getUrlTotalCount"
                          :page.sync="urlPage"
                          url-list
                          :loader="loading"
                        />
                      </v-tab-item>
                      <!--  -->
                    </v-tabs>
                  </div>
                </div>
                <div v-if="!canViewInvite" class="mt-15 no-data-found">
                  <no-data
                    first-text="No invites to show"
                    second-text="It seems that you don't have the permission to view the team
                    invites"
                  ></no-data>
                </div>
              </div>
            </div>
          </v-tab-item>

          <!-- For Storage -->
          <v-tab-item>
            <List
              v-if="tab == 2"
              :usage="info && info.usage ? info.usage.storage : 0"
              :limit="
                info && info.plan && info.plan.usage
                  ? info.plan.usage.storage
                  : 0
              "
            />
          </v-tab-item>
          <v-tab-item>
            <plans v-if="tab == 3" :info="info"></plans>
          </v-tab-item>
          <v-tab-item>
            <integration-list v-if="tab == 4"></integration-list>
          </v-tab-item>
          <v-tab-item>
            <timeline-list v-if="tab == 5"></timeline-list>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { get } from "@/core/services/helper.service";
import { INVITE_BY_EMAIL } from "@/store/team/invite.module";
import { INVITE_BY_URL } from "@/store/team/invite.module";
import Members from "@/view/components/Team/Members/List";
// import TeamInfo from "@/view/components/Team/Info";
import invites from "@/view/components/Invites/invites";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import List from "@/view/components/Team/Storage/List.vue";
import Plans from "@/view/components/Team/Plans";
import IntegrationList from "@/view/components/Team/IntegrationList.vue";
import TimelineList from "@/view/components/Team/TimelineList.vue";

export default {
  props: {
    inviteProcess: {
      type: Boolean,
      default: false,
    },
    teamError: {
      type: [String, Boolean],
      default: null,
    },
  },
  components: {
    IntegrationList,
    // TeamInfo,
    Members,
    Plans,
    invites,
    InviteMembersPanel,
    TimelineList,
    List,
  },
  TABS: {
    members: 0,
    invites: 1,
    storage: 2,
    plans: 3,
    integrations: 4,
    timeline: 5,
  },
  data() {
    return {
      tab: null,
      // 0 or 1, 1 means drawer opened more than one time, 0 means drawer not opened yet
      activeTab: "email",
      emailPage: 1,
      urlPage: 1,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      storageStats: "getStorageStats",
      getEmailTotalCount: "getEmailTotalCount",
      getEmailTotalList: "getEmailTotalList",
      getUrlTotalList: "getUrlTotalList",
      getUrlTotalCount: "getUrlTotalCount",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    ...mapState({
      // inviteLoader: (state) => get(state, "invite.inviteLoader", ""),
      info: (state) => get(state, "manageTeam.info", ""),
      inviteURL: (state) => get(state, "invite.inviteURL", ""),
      emailInvites: (state) => get(state, "invite.emailInvites", ""),
      urlInvites: (state) => get(state, "invite.urlInvites", ""),
      emailsLoader: (state) => get(state, "invite.emailsLoader", ""),
      urlsLoader: (state) => get(state, "invite.urlsLoader", ""),
      inviteProcces: (state) => get(state, "invite.inviteProcces", ""),
    }),
  },
  watch: {
    emailPage(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getEmailTotalList.length > oldVal * 10) {
          this.setPreviousEmailInviteData(
            this.getEmailTotalList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.emailInvites[this.emailInvites.length - 1].id;
          this.loadEmailInvites(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousEmailInviteData(
          this.getEmailTotalList.slice(offset, limit)
        );
      }
    },
    urlPage(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getUrlTotalList.length > oldVal * 10) {
          this.setPreviousUrlInviteData(
            this.getUrlTotalList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.urlInvites[this.urlInvites.length - 1].id;
          this.loadUrlInvites(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousUrlInviteData(
          this.getUrlTotalList.slice(offset, limit)
        );
      }
    },
    "$route.path"() {
      const activeTab = this.$route.name;
      this.tab = this.$options.TABS[activeTab] || 0;
    },
    tab(tab) {
      if (
        this.$route.name.includes(Object.keys(this.$options.TABS)[tab || 0])
      ) {
        return;
      }
      this.$router
        .push(`/team/${Object.keys(this.$options.TABS)[tab || 0]}`)
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong.");
        });
    },
  },
  created() {
    const activeTab = this.$route.name;
    this.tab = this.$options.TABS[activeTab] || 0;
    if (this.tab === 1) {
      this.loadEmailInvites();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousEmailInviteData: "setPreviousEmailInviteData",
      setPreviousUrlInviteData: "setPreviousUrlInviteData",
    }),
    async setActiveTab(type) {
      this.tab = 1;
      if (type.type === "email") {
        await this.loadEmailInvites();
        this.activeTab = "email";
        if (type.reload) {
          await this.$store.dispatch(INVITE_BY_EMAIL);
          this.loading = false;
        }
      } else {
        await this.loadUrlInvites();
      }
    },
    setUserInInviteProcces(v) {
      this.$store.commit("whenUserInInviteProcess", v);
    },
    async loadEmailInvites(lastId) {
      if (this.canViewInvite) {
        if (!this.getInactiveUserMessage) {
          if (this.loading) return;
          this.loading = true;
          await this.$store
            .dispatch(INVITE_BY_EMAIL, {
              lastId: lastId && typeof lastId !== "object" ? lastId : "",
            })
            .catch((err) => {
              this.notifyErrorMessage({ message: err.message });
              this.loading = false;
            });
          this.loading = false;
        }
      }
    },
    async loadUrlInvites(lastId) {
      // we have to tabs in Invites one for urls, and one for emails
      this.activeTab = "url";
      this.urlPage = 1;
      // if there are no urlInvites call API, else don't call teh API cuz we already have the data
      if (this.loading) return;
      this.loading = true;
      await this.$store
        .dispatch(INVITE_BY_URL, {
          lastID: lastId && typeof lastId !== "object" ? lastId : null,
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
      this.loading = false;
    },
    // get emails data from the autocomplete component
    setEmails(emails) {
      this.selectedEmails = emails;
    },
    // this is for the tabs => [1]-Create Team  [2]-Plan  [3]-Members
    nextButtonStep1() {
      this.step = 2;
    },

    // as well
    nextButtonStep2() {
      this.step = 3;
    },
    // open side bar
    openInviteDrawer() {
      this.$store.commit("setInviteDrawer2", true);
    },
  },
};
</script>

<style lang="scss">
// this is for the vuetify tabs [ v-tabs ], to remove the tab nav cuz we are controle the v-tab by the filter button
.invites {
  .v-tabs-bar {
    display: none !important;
  }
}

.tab-parent {
  position: relative;

  .side-ptn {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
</style>
