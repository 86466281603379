export default {
  $_veeValidate: {
    validator: "new"
  },
  data() {
    return {
      nonFieldErrors: [],
      incrementIndex: false,
      currentIndex: 0,
      mainErrorCollection: null,
      errorIndexList: []
    };
  },
  methods: {
    collectErrors(fieldName) {
      return this.errors.collect(fieldName);
    },
    validateAllFields() {
      return this.$validator.validateAll();
    },
    pushIncrementalErrors: function(error) {
      this.incrementIndex = true;
      this.pushErrors(error);
      this.currentIndex = 0;
    },
    pushErrors: function(error) {
      this.$validator.errors.clear();
      if (!error.response) {
        return;
      }
      const errorResponse = JSON.parse(error.response.request.response);
      this.mainErrorCollection = errorResponse;
      this.getError(errorResponse);
    },
    getError(errorResponse) {
      for (const errorField in errorResponse) {
        if (typeof errorResponse[errorField] === "string") {
          this.displayErrors(errorField, errorResponse[errorField]);
        }
      }
    },
    displayErrors(fieldName, errorMessage) {
      if (this.$validator.fields.find({ name: fieldName })) {
        this.addFieldValidation(fieldName, errorMessage);
      }
    },
    addFieldValidation(field, msg) {
      this.$validator.errors.add({
        field: field,
        msg: msg.toString()
      });
    },
    veeValidate(fieldName, properFieldName) {
      return {
        id: "id_" + fieldName,
        label: properFieldName,
        "data-vv-as": properFieldName.replace("*", ""),
        "data-vv-name": fieldName.replace("*", ""),
        "data-vv-validate-on": "input",
        errors: this.collectErrors(fieldName),
        "error-messages": this.collectErrors(fieldName)
      };
    }
  }
};
