<template>
  <v-row>
    <v-slide-y-transition>
      <v-col cols="12">
        <table width="100%">
          <thead>
            <th></th>
            <th width="25%">
              <div class="py-4">
                <div class="font-size-h3">Hobby</div>
                <div>
                  <span class="text-h5">${{ "0" }}</span>
                </div>
                <div class="text-muted text-transform-none text-caption">
                  Free Forever
                </div>
              </div>
            </th>
            <template v-for="(plan, starter_index) in starterPlan">
              <th
                width="25%"
                v-if="type === plan.recurring"
                :key="starter_index + plan.id"
              >
                <div>
                  <div class="font-size-h3">{{ plan.name }}</div>
                  <div class="font-size-h3">
                    <span v-if="plan.recurring === 'annually'" class="text-h5"
                      >${{ Math.floor(plan.price / 12) }}</span
                    >
                    <span v-else class="text-h5">${{ plan.price }}</span>
                  </div>
                  <div class="text-muted text-transform-none text-caption">
                    per month billed annually
                  </div>
                </div>
              </th>
            </template>
            <template v-for="(plan, pro_index) in proPlan">
              <th
                width="25%"
                v-if="type === plan.recurring"
                :key="pro_index + plan.id"
              >
                <div>
                  <div class="font-size-h3">{{ plan.name }}</div>
                  <div class="font-size-h3">
                    <span v-if="plan.recurring === 'annually'" class="text-h5"
                      >${{ plan.price / 12 }}</span
                    >
                    <span v-else class="text-h5">${{ plan.price }}</span>
                  </div>
                  <div class="text-muted text-transform-none text-caption">
                    per month billed annually
                  </div>
                </div>
              </th>
            </template>
          </thead>
          <tr class="pa-4 lightBackground">
            <td class="pa-3 font-weight-bold" colspan="4">CORE FEATURES</td>
          </tr>
          <tr v-for="(item, core_index) in coreFeaturesList" :key="core_index">
            <td class="pa-3">
              <div class="bold-text">
                {{ item.title }}
              </div>
              <div class="text-muted" v-html="item.info"></div>
            </td>
            <td class="text-center">
              <div v-if="typeof item.hobby === 'object'">
                <div>
                  {{ item.hobby.count }}
                </div>
                <div>
                  {{ item.hobby.info }}
                </div>
              </div>
              <div v-else>
                {{ item.hobby }}
              </div>
            </td>
            <td class="text-center">
              <div v-if="typeof item.starter === 'object'">
                <div>
                  {{ item.starter.count }}
                </div>
                <div>
                  {{ item.starter.info }}
                </div>
              </div>
              <div v-else>
                {{ item.starter }}
              </div>
            </td>
            <td class="text-center">
              <div v-if="typeof item.pro === 'object'">
                <div>
                  {{ item.pro.count }}
                </div>
                <div>
                  {{ item.pro.info }}
                </div>
              </div>
              <div v-else>
                {{ item.pro }}
              </div>
            </td>
          </tr>
          <tr class="pa-4 lightBackground">
            <td class="pa-3" colspan="4">
              <div class="font-weight-bold">STORAGE</div>
              <div class="dark-grey-text">
                Your app releases will be stored with us. You can always deleted
                older releases to free some space
              </div>
            </td>
          </tr>
          <tr
            v-for="(item, storage_index) in storageSizes"
            :key="storage_index + item.title"
          >
            <td class="pa-2">
              <div class="bold-text">
                {{ item.title }}
              </div>
              <div class="text-muted" v-html="item.info"></div>
            </td>
            <td class="text-center">{{ item.hobby }}</td>
            <td class="text-center">{{ item.starter }}</td>
            <td class="text-center">{{ item.pro }}</td>
          </tr>
          <tr class="pa-4 lightBackground">
            <td class="pa-3" colspan="4">
              <div class="font-weight-bold">EXTERNAL STORAGE</div>
              <div>
                Connect your own cloud storage for unlimited storage and no
                release expiry date
              </div>
            </td>
          </tr>
          <tr
            v-for="(item, external_index) in externalStorage"
            :key="external_index + item.title"
          >
            <td class="pa-2">
              <div>
                {{ item.title }}
              </div>
              <div class="text-caption dark-grey-text">
                {{ item.info }}
              </div>
            </td>
            <td class="text-center">{{ item.hobby || "-" }}</td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
          </tr>
          <tr class="pa-4 lightBackground">
            <td class="pa-3" colspan="4">
              <div class="font-weight-bold">CI/CD</div>
              <div class="dark-grey-text">
                Automate your release deployments
              </div>
            </td>
          </tr>
          <tr
            v-for="(item, cicd_index) in cicdList"
            :key="cicd_index + item.title"
          >
            <td class="pa-2">
              <div>
                {{ item.title }}
              </div>
              <div class="text-caption dark-grey-text" v-html="item.info"></div>
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.hobby"
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
              <span v-else> - </span>
            </td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
          </tr>
          <tr class="pa-4 lightBackground">
            <td class="pa-3" colspan="4">
              <div class="font-weight-bold">INTEGRATIONS</div>
              <div class="dark-grey-text">
                Connect your favourite tools with your team
              </div>
            </td>
          </tr>
          <tr
            v-for="(item, integration_index) in integrationList"
            :key="integration_index + item.title"
          >
            <td class="pa-2">
              <div>
                {{ item.title }}
              </div>
              <div
                :class="
                  item.info === 'Soon' ? 'dark-grey-text' : 'success--text'
                "
                class="text-caption"
                v-html="item.info"
              ></div>
            </td>
            <td class="text-center">{{ item.hobby || "-" }}</td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
            <td class="text-center">
              <v-icon
                color="primary"
                v-text="'mdi-checkbox-marked-circle-outline'"
              ></v-icon>
            </td>
          </tr>
        </table>
      </v-col>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    billingType: {
      type: String,
      default: "annually",
    },
  },
  data() {
    return {
      type: "annually",
      showComparisionTable: false,
      storageSizes: [
        {
          title: "Storage",
          info:
            "Storing your app releases <br> Only active releases will be counted",
          hobby: "500 MB",
          starter: "10 GB",
          pro: "100 GB",
        },
        {
          title: "Release expire",
          info:
            "Automatic archive for the release will make it not available for install",
          hobby: "7 Days",
          starter: "60 Days",
          pro: "-",
        },
      ],
      coreFeaturesList: [
        {
          title: "Projects",
          info: "It will contain your Android & iOS releases",
          hobby: {
            count: "1",
            info: "-",
          },
          starter: {
            count: "5",
            info: "$1/m per additional project",
          },
          pro: {
            count: "15",
            info: "$3/m per additional project",
          },
        },
        {
          title: "App releases",
          info: "Android & iOS",
          hobby: "Unlimited",
          starter: "Unlimited",
          pro: "Unlimited",
        },
        {
          title: "Release installs",
          info: "Installing or Downloading",
          hobby: "Unlimited",
          starter: "Unlimited",
          pro: "Unlimited",
        },
        {
          title: "Team members",
          info: "Owner, Developer, Tester and Custom role",
          hobby: {
            count: "2",
            info: "-",
          },
          starter: {
            count: "7",
            info: "$2/m per additional member",
          },
          pro: {
            count: "25",
            info: "$4/m per additional member",
          },
        },
        {
          title: "Max app size",
          info: "APK or IPA file size per upload",
          hobby: "< 50 MB",
          starter: "< 300 MB",
          pro: "< 1 GB",
        },
        {
          title: "Share",
          info:
            "Sharing a project or release either with the team or public-link",
          hobby: "Team",
          starter: "Team & Public",
          pro: "Team & Public",
        },
      ],
      integrationList: [
        {
          title: "Slack",
          hobby: false,
          pro: true,
          starter: true,
          info: "Available",
        },
        {
          title: "JIRA",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Microsoft Teams",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
      ],
      externalStorage: [
        {
          title: "Amazon S3",
          hobby: false,
          pro: true,
          starter: true,
          info: null,
        },
        {
          title: "Google Cloud Services",
          hobby: false,
          pro: true,
          starter: true,
          info: null,
        },
        {
          title: "Microsoft Azure Blob",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Digital Ocean Space",
          hobby: false,
          pro: true,
          starter: true,
          info: null,
        },
        {
          title: "IBM",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Vultr",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Blackblaze b2",
          hobby: false,
          pro: true,
          starter: true,
          info: "BETA",
        },
        {
          title: "Alibaba OSS",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
      ],
      cicdList: [
        {
          title: "TA-CLI",
          hobby: true,
          pro: true,
          starter: true,
          info: `<span class="success--text">Install from</span> <a href='https://github.com/testappio/cli' target='_blank'>Github</a>`,
        },
        {
          title: "Fastlane",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Jenkins",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Circle CI",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Travis CI",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Gitlab CI/CD",
          hobby: false,
          pro: true,
          starter: true,
          info: "Soon",
        },
        {
          title: "Github Actions",
          hobby: false,
          pro: true,
          starter: true,
          info: "BETA",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      plans: "getPlans",
    }),
    starterPlan() {
      return this.plans.plans.filter(plan => plan.meta.category === "starter");
    },
    proPlan() {
      return this.plans.plans.filter(plan => plan.meta.category === "pro");
    },
  },
  watch: {
    billingType(val) {
      this.type = val;
    },
  },
};
</script>

<style lang="scss" scoped>
table,
td,
th {
  border: 1px solid rgb(228, 224, 224);
}

table {
  border-collapse: collapse;
  width: 100%;
}
</style>
