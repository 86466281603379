<template>
  <div>
    <div>
      <div class="members">
        <v-row v-if="canManageInvite" no-gutters>
          <v-col class="mb-8 d-flex">
            <v-spacer />
            <div>
              <InviteMembersPanel
                is-member
                @makeActive="$emit('makeActive', $event)"
              />
              <!--              <div class="text-center mt-2"-->
              <!--              >-->
              <!--                <span class="mr-2">Total: </span>-->
              <!--                <b-->
              <!--                  class="mr-2"-->
              <!--                  :class="-->
              <!--                    getTeamPlanLimits.members &&-->
              <!--                    getTeamPlanLimits.members < getTotalMembersCount-->
              <!--                      ? 'error&#45;&#45;text'-->
              <!--                      : ''-->
              <!--                  "-->
              <!--                  >{{ getTotalMembersCount }}/{{-->
              <!--                    getTeamPlanLimits.members || "∞"-->
              <!--                  }}</b-->
              <!--                >-->
              <!--                <vue-tooltip-->
              <!--                  icon-color="primary"-->
              <!--                  text="This is the limit for your total members. Check the plans page for more info."-->
              <!--                ></vue-tooltip>-->
              <!--              </div>-->
            </div>
          </v-col>
        </v-row>
        <div v-if="canViewTeamMembers">
          <v-skeleton-loader
            class="transparent"
            type="table-thead, table-tbody"
            v-if="loading"
          />
          <div v-else>
            <v-data-table
              :headers="headers"
              :items="allMembers"
              :page.sync="page"
              :server-items-length="getTotalMembersCount"
              class="
                v-card v-sheet v-sheet--outlined
                hide-v-data-footer__select
                radius-10
                release-table
              "
            >
              <template #item.avatar="{ item }">
                <ProfileImage
                  :image="item.image"
                  class="my-1"
                  :alt="item.name"
                />
              </template>

              <template #item.name="{ item }">
                <b v-if="loggedUser == item.user_id"> You </b>
                <b v-else>{{ item.name }}</b>
                <div class="text-muted">
                  {{ item.email }}
                </div>
              </template>
              <template #item.role="{ item }">
                {{ get(item, "role.name", "") }}
              </template>
              <template #item.status="{ item }">
                <span
                  :class="item.status == 1 ? 'success--text' : 'error--text'"
                >
                  {{ item.status == 1 ? "Active" : "Inactive" }}
                </span>
              </template>

              <template #item.join="{ item }">
                <vue-hoverable-date :date="item.created_at" />
              </template>

              <template #item.action="{ item }">
                <action-dropdown
                  v-if="
                    canManageTeamMembers &&
                      !getInactiveUserMessage &&
                      !getIsUserDisabled
                  "
                >
                  <template #list>
                    <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                          item.status == 2 &&
                          canManageTeam
                      "
                      @click="openUserDeactiveModal(item)"
                    >
                      <v-icon
                        v-text="
                          item.status == 1
                            ? 'mdi-account-remove'
                            : 'mdi-account-check'
                        "
                        small
                        >cancel</v-icon
                      >
                      {{ item.status == 1 ? "Deactivate" : "Activate" }}
                    </v-list-item>
                    <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                          item.status == 1 &&
                          canManageTeam
                      "
                      @click="setCurrentMemberAndUpdate(item)"
                    >
                      <v-icon small>edit</v-icon>
                      edit
                    </v-list-item>
                    <v-list-item @click="showDevice(item)">
                      <v-icon small left>devices</v-icon>
                      Devices
                    </v-list-item>
                    <v-list-item id="showAppBtnMember" @click="showApps(item)">
                      <v-icon small left>apps</v-icon>
                      Apps
                    </v-list-item>
                    <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                          item.status == 1 &&
                          canManageTeam
                      "
                      @click="openUserDeactiveModal(item)"
                    >
                      <v-icon
                        v-text="
                          item.status == 1
                            ? 'mdi-account-remove'
                            : 'mdi-account-check'
                        "
                        small
                        >cancel</v-icon
                      >
                      {{ item.status == 1 ? "Deactivate" : "Activate" }}
                    </v-list-item>
                    <v-list-item
                      v-if="loggedUser !== item.user_id && canManageTeam"
                      @click="setCurrentMemberAndRemove(item)"
                    >
                      <v-icon small>cancel</v-icon>
                      remove
                    </v-list-item>
                  </template>
                </action-dropdown>
              </template>
            </v-data-table>
            <SidePanel width="600" v-model="showDeviceInfo">
              <DeviceInfo
                :loading="drawerLoading"
                :devices="devices"
                :member="member.name"
              />
            </SidePanel>
            <SidePanel v-model="showAppInfo">
              <AppsInfo
                v-if="showAppInfo"
                :modals-apps="appInfo"
                :logged-user="loggedUser"
                :current-member="currentMember.user_id"
                :member="memberDetails"
                :selected-member="selectedMember"
                :loading="drawerLoading"
                @close="showAppInfo = false"
                @refresh="showApps"
              />
            </SidePanel>
            <!-- here we have the edit navigation drawer and the leave dialog -->
            <!-- <Modal
              v-model="dialog"
              :loading="removeLoader"
              title="Remove member"
              :message="`Are you sure you want to remove ${currentMember.name} from
                  this Team? There's no undo for this action.`"
              @onConfirm="removeMember"
              @onCancel="cancelRemoveMember"
            >
            </Modal> -->
            <Modal v-model="dialog">
              <template #message>
                <v-card-title class="font-weight-bold"
                  >Remove member</v-card-title
                >
                <v-card-text class="message">
                  <p>
                    Are you sure you want to remove
                    <b>{{ currentMember.name }}</b> from this team?
                  </p>
                  <p>There's no undo for this action.</p>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pb-0">
                  <v-spacer />
                  <v-btn class="main" @click="cancelRemoveMember">no</v-btn>
                  <v-btn
                    class="main primary"
                    @click="removeMember"
                    :loading="removeLoader"
                  >
                    yes
                  </v-btn>
                </v-card-actions>
              </template>
            </Modal>

            <SidePanel
              @close="drawer = false"
              v-model="drawer"
              :loading="updateLoading"
            >
              <!--  -->
              <h1 class="text-center text-capitalize display-1 mb-15">edit</h1>
              <h3
                v-if="currentMember"
                class="col-12 darkGrey--text text-center"
              >
                {{ currentMember.name }}
              </h3>
              <v-divider class="mb-5" />
              <Roles
                v-if="drawer"
                :update-roles="currentMember.role"
                :permission="currentMember.permissions"
                @updateTheRole="saveTheRole"
                @updateRoles="saveRoles"
              />
              <v-btn
                class="main wide large mx-auto primary mt-15"
                @click="update"
                :disabled="updateLoading"
                :loading="updateLoading"
              >
                update
              </v-btn>
              <!--  -->
            </SidePanel>
          </div>
        </div>
        <div v-else class="mt-15 no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            src="/media/placeholders/error.svg"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            No members to show
          </p>
          <p
            class="
              mt-4
              mb-5
              empty-sub-title
              font-weight-normal
              text-dark text-center
            "
          >
            It seems that you don't have the permission to view the team members
          </p>
        </div>
      </div>
    </div>
    <Modal v-model="showUserDeactiveModal" @close="showUserDeactiveModal">
      <template #message>
        <v-card-title class="font-weight-bold"
          >{{
            selectedMember.status == 1 ? "Deactivate" : "Activate"
          }}
          member</v-card-title
        >
        <v-card-text class="message">
          <p>
            Are you sure you want to
            {{ selectedMember.status == 1 ? "deactivate" : "activate" }} this
            member from this team?
          </p>

          <v-alert class="mt-3" border="left" color="info" icon="info" outlined>
            This member will lose the access to all the following:
            <div v-if="readMore">
              <br />

              <ul>
                <li>Apps</li>
                <li>Releases</li>
                <li>Invites</li>
                <li>Members</li>
                <li>Timeline</li>
                <li>Notifications</li>
              </ul>

              <br />
              This member can be reactivated at any time and one slot will be
              available for other members.
            </div>
            <b class="pointer" @click="readMore = !readMore" v-if="!readMore">
              {{ "Read more..." }}
            </b>
            <br />
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn
            :disabled="loading"
            class="main"
            @click="showUserDeactiveModal = false"
            >no</v-btn
          >
          <v-btn
            class="main primary"
            @click="enableDisableMembers"
            :loading="loading"
            >yes</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>
    <Modal v-model="showUpgradeInfo" @close="showUpgradeInfo = false">
      <template #message>
        <upgrade-plan
          title="Members"
          from-upgrade-component
          info-text="to add more members to your team and much more"
          @close="showUpgradeInfo = false"
        >
          <template>
            <div>
              <br />
              Your team has reached the maximum number of members.
              <br />
              <!-- This is on upgrade  -->
              <br /><span v-if="!IsActiveTrial">
                Upgrade today to take the advantage of our discount to add more
                members and much more!
              </span>
              <!-- This is on free trial -->
              <span v-if="IsActiveTrial">
                Give it a try with your free trial to add more member and much
                more!
              </span>
            </div>
          </template>
        </upgrade-plan>
      </template>
    </Modal>
    <Modal
      v-model="showStartTrialModal"
      @close="showStartTrialModal = false"
      width="550"
    >
      <template #message>
        <start-trial-modal
          message="Your team has reached the maximum number of members"
          from-members-page
          :tracker-event="trackerEvent"
          @close="showStartTrialModal = false"
          @open-subscribe="openSubscriptionModal = true"
        ></start-trial-modal>
      </template>
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Roles from "@/view/components/Team/Members/Roles.vue";
import {
  CHECK_MEMBERS,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
  DISABLE_ENABLE_MEMBERS
} from "@/store/team/members.module.js";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import UpgradePlan from "@/view/components/Team/UpgradePlan.vue";
import {
  GET_MEMBER_DEVICE_LIST,
  GET_MEMBER_APPS_LIST
} from "@/store/team/team.module";
import { mapState, mapGetters, mapMutations } from "vuex";
import { isEmpty, get } from "@/core/services/helper.service";
import ProfileImage from "@/view/components/User/Image.vue";
import StorageService from "@/core/services/localstorage.service";
import DeviceInfo from "@/view/components/Team/Members/DeviceInfoPanel";
import AppsInfo from "@/view/components/Team/AppsInfo";
import { currentTimestamp } from "@/core/services/helper.service";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import { CLEAR_STORE } from "@/store/users/auth.module.js";

export default {
  props: {
    teamError: {
      type: [String, Boolean],
      default: null
    }
  },
  data() {
    return {
      get,
      dialog: false,
      drawer: null,
      selectedRoles: null,
      mainRole: null,
      totalMembers: 0,
      trackerEvent: {},
      devices: [],
      showAppInfo: false,
      selectedMember: {},
      showUpgradeInfo: false,
      memberDetails: {},
      readMore: false,
      loading: false,
      updateLoading: false,
      showStartTrialModal: false,
      drawerLoading: false,
      showDeviceInfo: false,
      currentMember: {},
      member: {},
      times: 0,
      removeLoader: false,
      showUserDeactiveModal: false,
      page: 1,
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          width: "40px",
          value: "avatar"
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          width: "30%",
          value: "name"
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          width: "15%",
          value: "role"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          width: "15%",
          value: "status"
        },
        {
          text: "Joined",
          align: "start",
          sortable: false,
          width: "30%",
          value: "join"
        },
        {
          text: "",
          align: "end",
          sortable: false,
          width: "10%",
          value: "action"
        }
      ]
    };
  },
  components: {
    ActionDropdown,
    AppsInfo,
    Roles,
    DeviceInfo,
    StartTrialModal,
    UpgradePlan,
    InviteMembersPanel,
    ProfileImage
  },
  computed: {
    ...mapGetters({
      appInfo: "getMemberAppList",
      totalMembersData: "getTotalMembersData",
      allMembers: "getAllMembers",
      getTotalMembersCount: "getTotalMembersCount",
      getTeamPlanLimits: "getTeamPlanLimits",
      getTeamTrial: "getTeamTrial",
      getInactiveUserMessage: "getInactiveUserMessage"
    }),
    IsActiveTrial() {
      return this.getTeamTrial && !this.getTeamTrial.status;
    },
    ...mapState({
      memberInvitesLoader: state =>
        get(state, "manageTeam.memberInvitesLoader", ""),
      memberLoader: state => get(state, "manageTeam.memberLoader", ""),
      roles: state => get(state, "team.roles", "")
    }),
    loggedUser() {
      return StorageService.getItem("user")
        ? StorageService.getItem("user").id
        : "";
    },
    noData() {
      return isEmpty(this.allMembers);
    }
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalMembersData.length > oldVal * 10) {
          this.setPreviousMembersData(
            this.totalMembersData.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.allMembers[this.allMembers.length - 1].id;
          this.checkMember(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousMembersData(this.totalMembersData.slice(offset, limit));
      }
    }
  },
  created() {
    this.checkMember();
  },
  methods: {
    ...mapMutations({
      setAppMemberList: "setAppMemberList",
      setPreviousMembersData: "setPreviousMembersData",
      setTotalMembersCount: "setTotalMembersCount",
      setAllMembers: "setAllMembers",
      removeMemberFromList: "removeMemberFromList",
      updateAllMemebers: "updateAllMemebers"
    }),
    saveTheRole(v) {
      this.mainRole = v;
    },
    saveRoles(v) {
      this.selectedRoles = v;
    },
    openUserDeactiveModal(detail) {
      this.selectedMember = detail;
      if (detail.status == 1) {
        this.showUserDeactiveModal = true;
      } else {
        if (this.getTotalMembersCount < this.getTeamPlanLimits.members) {
          this.enableDisableMembers();
        } else {
          window.analytics.track("Members limit popup");
          if (isEmpty(this.getTeamTrial)) {
            this.trackerEvent = {
              action: "Members",
              current: this.getTeamPlanLimits.members,
              requested: 1
            };
            this.showStartTrialModal = true;
          } else {
            this.showUpgradeInfo = true;
          }
        }
      }
    },
    onConfirmAction() {},
    showDevice(member) {
      this.member = member;
      this.showDeviceInfo = true;
      if (this.drawerLoading) return;
      this.drawerLoading = true;
      this.$store
        .dispatch(GET_MEMBER_DEVICE_LIST, member.id)
        .then(response => {
          this.drawerLoading = false;
          this.devices = response.results.data;
          Vue.prototype.$analytics.logEvent("member", {
            status: "success",
            action: "devices"
          });
        })
        .catch(err => {
          this.drawerLoading = false;
          Vue.prototype.$analytics.logEvent("member", {
            status: "error",
            action: "devices",
            message: err.message
          });
        });
    },
    enableDisableMembers() {
      if (this.loading) return;
      this.loading = true;
      const dataToPost = {
        id: this.selectedMember.id,
        action:
          this.selectedMember && this.selectedMember.status == 1
            ? "deactivate"
            : "activate"
      };
      this.$store
        .dispatch(DISABLE_ENABLE_MEMBERS, dataToPost)
        .then(response => {
          let dataToAppend = {
            ...this.selectedMember
          };
          this.notifyUserMessage(response.message);
          const index = this.allMembers.findIndex(
            el => el.id === this.selectedMember.id
          );
          dataToAppend.created_at = parseInt(currentTimestamp());
          dataToAppend.status = this.selectedMember.status == 1 ? 2 : 1;
          this.updateAllMemebers({ index: index, dataToAppend: dataToAppend });
          const count =
            dataToPost.action == "deactivate"
              ? this.getTotalMembersCount - 1
              : this.getTotalMembersCount + 1;
          this.setTotalMembersCount(count);
          this.loading = false;
          this.showUserDeactiveModal = false;
        })
        .catch(err => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    setCurrentMemberAndRemove(member) {
      this.currentMember = member;
      this.dialog = true;
    },
    setCurrentMemberAndUpdate(member) {
      this.currentMember = member;
      this.drawer = true;
      Vue.prototype.$analytics.logEvent("member", {
        status: "open",
        action: "update"
      });
    },
    showApps(member) {
      this.currentMember = member;
      this.showAppInfo = true;
      if (this.drawerLoading) return;
      this.drawerLoading = true;
      if (member) {
        this.selectedMember = member;
      }
      this.$store
        .dispatch(
          GET_MEMBER_APPS_LIST,
          this.selectedMember ? this.selectedMember.id : member.id
        )
        .then(response => {
          this.drawerLoading = false;
          this.setAppMemberList(response.results.data);
          this.memberDetails = {
            name: this.selectedMember ? this.selectedMember.name : member.name,
            id: this.selectedMember ? this.selectedMember.id : member.id
          };
          Vue.prototype.$analytics.logEvent("member", {
            status: "success",
            action: "apps"
          });
        })
        .catch(err => {
          this.drawerLoading = false;
          Vue.prototype.$analytics.logEvent("member", {
            status: "error",
            action: "apps",
            message: err.message
          });
        });
    },
    cancelRemoveMember() {
      this.dialog = false;
      Vue.prototype.$analytics.logEvent("member", {
        status: "cancel",
        action: "delete"
      });
    },
    removeMember() {
      this.removeLoader = true;
      this.$store
        .dispatch(REMOVE_MEMBER, this.currentMember.id)
        .then(() => {
          this.removeMemberFromList({
            id: this.currentMember.id,
            status: this.currentMember.status
          });
          this.dialog = false;
          this.removeLoader = false;
          Vue.prototype.$analytics.logEvent("member", {
            status: "success",
            action: "delete"
          });
        })
        .catch(err => {
          this.dialog = false;
          this.removeLoader = false;
          Vue.prototype.$analytics.logEvent("member", {
            status: "error",
            action: "delete",
            message: err.message
          });
        });
    },
    update() {
      this.updateLoading = true;
      const data = {
        role_id: this.mainRole,
        permissions: this.selectedRoles
      };
      this.$store
        .dispatch(UPDATE_MEMBER, { data, id: this.currentMember.id })
        .then(() => {
          this.drawer = false;
          this.updateLoading = false;
          let dataToAppend = {
            ...this.currentMember
          };
          const index = this.allMembers.findIndex(
            el => el.id === this.currentMember.id
          );
          const allRoles = this.roles.find(el => el.id === data.role_id);
          dataToAppend.role = {
            id: data.role_id,
            name: allRoles ? allRoles.name : ""
          };
          dataToAppend.created_at = parseInt(currentTimestamp());
          dataToAppend.permissions = { ...data.permissions };
          this.updateAllMemebers({ index: index, dataToAppend: dataToAppend });

          Vue.prototype.$analytics.logEvent("member", {
            status: "success",
            action: "update"
          });
        })
        .catch(err => {
          this.updateLoading = false;
          Vue.prototype.$analytics.logEvent("member", {
            status: "error",
            action: "update",
            message: err.message
          });
        });
    },
    checkMember(lastId) {
      if (!this.teamError) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(CHECK_MEMBERS, lastId)
          .then(response => {
            if (response && response.results && response.results.meta) {
              this.totalMembers = response.results.meta.count || 0;
            }
            this.loading = false;
          })
          .catch(err => {
            this.notifyErrorMessage({ message: err.message });
            if (!err.message.includes("Invalid authorization")) {
              this.$router.push({ name: "welcome" });
            }
            this.$store.dispatch(CLEAR_STORE);
            this.loading = false;
          });
      }
    }
  }
};
</script>
