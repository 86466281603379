<template>
  <div>
    <v-row class="mt-4" wrap>
      <v-col cols="12" id="storage-select-box">
        <v-autocomplete
          ref="drop"
          v-model="selectedData"
          :items="searchItems.filter((x) => x.id !== hideAppId)"
          :label="multiple ? 'Select App(s) ' : 'Select App *'"
          item-value="id"
          item-text="name"
          placeholder="Select App"
          append-icon="mdi-chevron-down"
          :clearable="multiple"
          :loading="loading"
          :multiple="multiple"
          :deletable-chips="multiple"
          class="tag-input"
          :hide-selected="searchItems.length > 1"
          small-chips
          return-object
          :search-input.sync="search"
          filled
          hide-details
          attach
          @focusin.prevent="fetchData"
          @change="$emit('change')"
          @blur="$emit('blur')"
        >
          <template slot="item" slot-scope="data">
            <v-row no-gutters>
              <v-col class="d-flex align-center justify-center">
                <v-list-item-title
                  id="data-item"
                  v-text="data && data.item ? data.item.name : ''"
                />
              </v-col>
              <v-col class="text-right">
                <v-tooltip bottom transition="slide-y-transition">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      small
                      v-on="on"
                      :color="data && data.item.public ? 'primary' : 'success'"
                      v-text="
                        data && data.item.public
                          ? 'mdi-lock-open-outline'
                          : 'mdi-lock-outline'
                      "
                    ></v-icon>
                  </template>
                  <span>
                    {{
                      data && data.item.public
                        ? "Can be accessed publicly"
                        : "Accessed by your team members only"
                    }}
                  </span>
                </v-tooltip>
                <span
                  class="ml-2"
                  v-if="data && data.item && data.item.storage"
                >
                  <v-tooltip bottom transition="slide-y-transition">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        large
                        v-bind="attrs"
                        v-on="on"
                      >
                        <img
                          height="24"
                          :src="
                            data.item.storage.icon
                              ? getStorageImg(data.item.storage.icon)
                              : 'testapp.io'
                          "
                        />
                      </v-btn>
                    </template>
                    <span v-if="data.item.storage">
                      <div>Storage</div>
                      {{ data.item.storage.name || "testapp.io" }}
                    </span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </template>
          <template #append-item>
            <v-list-item
              @click="openApp"
              :style="searchItems.length ? null : 'border:none'"
              v-if="!loading"
            >
              <v-icon left style="width: 32px" color="primary">mdi-plus</v-icon>
              <span class="primary--text">Create new app </span>
            </v-list-item>
          </template>
          <template slot="no-data">
            <div class="">
              <div
                v-if="loading"
                class="d-flex align-center justify-center pa-4"
              >
                <ContentLoader size="25" />
              </div>
              <!-- <v-list-item v-else @click="openApp" style="border:none">
                <span class="font-weight-regular mr-1">
                  oops no apps available,
                </span>
                <span class="primary--text">
                  let's create one now
                </span>
              </v-list-item> -->
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <Modal v-model="openAppForm">
      <template slot="message">
        <v-card-title class="display-1 justify-center mb-4">
          Create app
        </v-card-title>
        <v-card-text>
          <create-app-step
            v-if="openAppForm"
            @close="openAppForm = false"
            :new-app-name="newAppName"
            @goNext="afterCreateApp"
            is-auto-complete
          ></create-app-step>
        </v-card-text>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
// import { isEmpty } from "@/core/services/helper.service";
import CreateAppStep from "@/view/components/App/CreateAppStep.vue";

export default {
  components: { CreateAppStep },
  props: {
    noChips: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Array, Object],
      default: undefined,
    },
    default: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: [Array, Object, String],
      default() {
        return "";
      },
    },
    memberId: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    addApp: {
      type: Boolean,
      default: false,
    },
    isGetStarted: {
      type: Boolean,
    },
    hideAppId: {
      type: String,
      default: "",
    },
    selectFistItem: {
      type: Boolean,
      default: false,
    },
    currentApp: {
      type: [Object, Boolean],
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectedData: "",
      search: "",
      searchItems: [],
      loading: false,
      newAppName: "",
      members: null,
      setMessage: false,
      openAppForm: false,
      invite: null,
      current: null,
    };
  },
  computed: {
    ...mapGetters({
      items: "getAppsAutocomplete",
      initialList: "getAppsInitialList",
      defaultApp: "getDefaultApp",
    }),
    showItems() {
      if (this.selectedData && this.selectedData.length) {
        return [...this.searchItems, ...this.selectedData];
      } else if (
        typeof this.selectedData === "object" &&
        Object.keys(this.selectedData).length
      ) {
        return [...this.searchItems, this.selectedData];
      } else {
        return this.searchItems;
      }
    },
  },
  watch: {
    focus(val, oldVal) {
      if (!val && oldVal) {
        this.setMessage = false;
      } else {
        this.setMessage = val;
      }
    },
    defaultApp(val) {
      if (val && this.showItems.length) {
        this.selectedData = [val];
      }
    },
    search(val) {
      if (val && val.length > 0) {
        this.newAppName = val;
        clearTimeout(this._timerId);
        this.loading = true;
        this._timerId = setTimeout(() => {
          this.getSearchValue(val);
        }, 500);
      } else {
        this.searchItems = [...this.initialList];
      }
    },
    selectedData(val) {
      if (Array.isArray(val)) {
        this.setAppsInitialList([...this.initialList, ...val]);
      }
      if (val && typeof val === "object" && val.id) {
        this.initialList.push(val);
      }
      this.$emit("input", val ? val : {});
      this.search = "";
      this.$nextTick(() => {
        this.$emit("focus", false);
        this.setMessage = false;
      });
    },
    items(val) {
      this.searchItems = [...val];
    },
    initialList(val) {
      this.searchItems = [...val];
    },
  },
  mounted() {
    if (!this.initialList.length) {
      this.fetchData();
    }
    this.setMessage = this.focus;
    if (this.defaultApp) {
      this.selectedData = [this.defaultApp];
    }
    if (this.$route.query.appId) {
      this.selectedData = [{ name: "", id: this.$route.query.appId }];
    }
    if (this.$route.params.app_id) {
      this.selectedData = [{ name: "", id: this.$route.params.app_id }];
    }
    if (this.initialData) {
      this.selectedData = [...this.initialData];
    }
  },
  methods: {
    ...mapMutations({
      setAppsInitialList: "setAppsInitialList",
    }),
    afterCreateApp($event) {
      // v-model of v-autocomplete accepts array not object
      this.current = { ...$event };
      this.searchItems = [];
      this.fetchData();

      if (Array.isArray(this.selectedData)) {
        this.selectedData.push({ ...$event });
      } else {
        this.selectedData = [{ ...$event }];
      }
      this.openAppForm = false;
    },
    // disableItem(item) {
    //   if (this.currentApp) {
    //     if (item.storage && !item.storage.name) {
    //       item.storage.name = "TestApp.io";
    //     }
    //     if (
    //       item.storage &&
    //       item.storage.name === this.currentApp.storage.name
    //     ) {
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    openList() {
      const input = this.$refs.drop.$el.querySelector("input");
      input.click();
      this.notifyInfoMessage("Select app for this release");
    },
    clearFocus() {
      this.setMessage = false;
      this.$emit("focus", false);
    },
    openApp() {
      this.openAppForm = true;
    },
    populateInitialData(val) {
      if (this.selectedData) return;
      if (val) {
        this.getSearchValue(val);
      } else {
        this.searchItems = [...this.initialList];
      }
      this.$emit("input", this.selectedData);
    },
    getSearchValue(val) {
      this.loading = true;
      this.$store
        .dispatch("getAppsAutocompletes", {
          member_id: this.addApp && this.memberId ? this.memberId : "",
          search: val,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage({ message: err.message });
        });
    },
    remove(item) {
      if (this.selectedData instanceof Object) {
        const index = this.selectedData.indexOf(item.id);
        if (index >= 0) this.selectedData.splice(index, 1);
      } else {
        this.selectedData = null;
      }
    },
    fetchData() {
      this.clearFocus();
      if (this.loading) return;
      this.loading = true;
      if (this.initialList.length && this.searchItems.length) {
        if (this.defaultApp) {
          this.selectedData = [this.defaultApp];
        }
        this.loading = false;
        return;
      }
      this.$store
        .dispatch(
          "getAppsInitialList",
          this.addApp && this.memberId ? this.memberId : ""
        )
        .then((data) => {
          this.loading = false;
          if (data) {
            !this.multiple ? (this.selectedData = this.searchItems[0]) : null;
          }
          if (this.defaultApp) {
            this.selectedData = [this.defaultApp];
          }
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
