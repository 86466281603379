<template>
  <v-row>
    <v-col v-if="fromModal" class="text-center" cols="12">
      <vue-inline-alert
        :message="infoMessage()"
        message-type="info"
        no-close-btn
        class="mt-10 mb-3"
      >
      </vue-inline-alert>
    </v-col>
    <v-col cols="12" md="12" class="text-center py-6">
      <v-btn-toggle
        active-class="primary"
        class="font-weight-bold text-capitalize"
        dense
        rounded
        mandatory
        v-model="billingType"
      >
        <v-btn
          value="monthly"
          :class="
            billingType === 'monthly' ? 'white--text primary' : 'black--text'
          "
        >
          <div class="bold-text text-capitalize">Monthly Team Plan</div>
        </v-btn>
        <v-btn
          value="annually"
          :class="
            billingType === 'annually' ? 'white--text primary' : 'black--text'
          "
          class="bold-text text-capitalize"
        >
          <div class="bold-text text-capitalize">Annual Team Plan</div>
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="text-center pb-0" cols="12">
      <div class="dark-grey-text my-2">
        You saved 2 months on annual team plan 🎉
      </div>
    </v-col>
    <template v-if="!planLoading">
      <v-col class="d-flex" cols="12" md="4">
        <v-card outlined elevation="2" class="radius-10">
          <v-row class="header-radius primary no-gutters">
            <v-col cols="12" class="py-3 text-center white--text">
              <div class="currentLabel"></div>
              <div class="mt-6 planNameFont">Hobby</div>
              <div class="text-center planDesc">
                Best for a solo developer ❤️
              </div>
              <div class="mb-3 text-center planDesc">Free forever</div>
            </v-col>
            <v-col cols="12" class="">
              <v-img src="/media/svg/imagebuttom.svg" width="100%"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-3 text-center">
              <v-chip color="white" class="">
                <span class="text-h5 bold-text">${{ "0" }}</span>
                <span>/mo</span>
              </v-chip>
            </v-col>
            <v-col cols="12" class="mt-2 pb-2 text-center">
              <v-btn
                v-if="subscription.status === 'active'"
                @click="$emit('cancelPlan')"
                color="primary"
                class="text-transform-none"
                outlined
              >
                Downgrade
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="!subscription.active ? 'mt-15' : ''"
              class="mx-6 mt-2"
              cols="12"
            >
              <div
                class="pt-2 mb-1 bodyFont"
                v-for="(feat, index) in hobbyFeatures"
                :key="index + feat.title"
              >
                <v-icon
                  color="primary"
                  v-text="'mdi-checkbox-marked-circle-outline'"
                  left
                ></v-icon>
                <span class="mr-1" v-html="feat.title"></span>
                <VueTooltip
                  v-if="feat.info"
                  icon-color="grey"
                  color="primary"
                  text-class="white--text"
                  :v-html="feat.info"
                  :max-width="200"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-6 mt-1" cols="10">
              <v-divider></v-divider>
            </v-col>
            <v-col class="mx-6 mb-15">
              <div class="mb-3">
                <a class="primary--text" @click="viewAllPlanFeatures()">
                  <span class="text-decoration-underline">{{
                    showAllFeature ? "Hide all features" : "View all features"
                  }}</span>
                </a>
              </div>
              <a
                class="primary--text"
                href="https://roadmap.testapp.io/"
                target="_blank"
              >
                <span class="text-decoration-underline"
                  >View our public roadmap</span
                >
              </a>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="canManageApp">
            <v-col v-if="!subscription.active" class="currentLabel" cols="12">
              Current Plan
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <template v-for="(plan, sindex) in getStarterPlans">
        <v-col
          v-if="plan.recurring === billingType"
          class="d-flex"
          :key="sindex + plan.id"
          cols="12"
          md="4"
        >
          <v-card class="radius-10">
            <v-row class="header-radius orange darken-4 no-gutters">
              <v-col cols="12" class="py-3 text-center white--text">
                <div class="mt-6 planNameFont">{{ plan.name }}</div>
                <div class="text-center planDesc">
                  {{ plan.description }}
                </div>
                <div>
                  No credit card required <br />
                  for <b>free trial</b>
                </div>
                <!-- <div class="mb-6">
                  {{ getDiscount(plan.price, plan.meta.previous_price) }}%
                  discount
                </div> -->
              </v-col>
              <v-col cols="12" class="">
                <v-img src="/media/svg/imagebuttom.svg" width="100%"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <div class="py-3">
                  <v-chip color="white" class="text-h5">
                    <span
                      v-if="plan.meta.previous_price"
                      class="mr-2 body-1 text-decoration-line-through"
                    >
                      <span v-if="billingType === 'annually'">
                        {{ "$ " + plan.meta.previous_price / 12 }}
                      </span>
                      <span v-else>
                        {{ "$ " + plan.meta.previous_price }}
                      </span>
                    </span>
                    <span v-if="plan.recurring === 'annually'" class="text-h5"
                      >${{ Math.floor(plan.price / 12) }}</span
                    >
                    <span v-else class="text-h5">${{ plan.price }}</span>
                    <span class="mx-2 text-muted">|</span>
                    <span class="primary--text body-1">monthly</span>
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="12" class="py-2 text-center">
                <template
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status !== 'active'
                  "
                >
                  <v-btn
                    v-if="!subscribe"
                    @click="openFreeTrailModal(plan)"
                    class="text-transform-none"
                    id="startTrialStarterPlan"
                    depressed
                    color="primary"
                  >
                    <span> Start Trial </span>
                  </v-btn>
                  <div v-if="!subscribe" class="mt-3">
                    Or
                    <b
                      class="primary--text cursor-pointer"
                      @click="upgradePlanConfirmation(plan)"
                      >Subscribe Now</b
                    >
                  </div>
                  <v-btn
                    v-else
                    @click="upgradePlanConfirmation(plan)"
                    class="text-transform-none"
                    id="startTrialStarterPlan"
                    depressed
                    color="primary"
                  >
                    <span> Subscribe </span>
                  </v-btn>
                </template>
                <template
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    !isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status === 'active' &&
                    subscription.category === 'starter'
                  "
                >
                  <v-btn
                    color="primary"
                    class="text-transform-none"
                    :class="subscription.recurring !== 'annually' ? '' : 'mb-4'"
                    depressed
                    @click="
                      openSwitchPlanDialog(
                        'Starter',
                        subscription.recurring !== 'annually'
                          ? 'annually'
                          : 'monthly',
                        plan.id
                      )
                    "
                  >
                    Switch to
                    {{
                      subscription.recurring !== "annually"
                        ? "Annually"
                        : "Monthly"
                    }}
                  </v-btn>
                  <div
                    v-if="subscription.recurring !== 'annually'"
                    class="body-1 success--text"
                  >
                    and get 2 free months
                  </div>
                </template>

                <v-btn
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    !isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status === 'active' &&
                    subscription.category !== 'starter'
                  "
                  color="primary"
                  class="text-transform-none mb-3"
                  outlined
                  @click="changeModal = true"
                >
                  Downgrade
                </v-btn>

                <v-btn
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    !isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status !== 'active'
                  "
                  @click="upgradePlanConfirmation(plan)"
                  class="text-transform-none"
                  id="starterUpgradePlanBtn"
                  depressed
                  color="primary"
                >
                  <span> Upgrade Plan </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-6 mt-1" cols="10">
                <div
                  class="mb-2 pb-1 bodyFont"
                  v-for="(feat, starter_feat_index) in plan.features"
                  style="display: flex; align-items: center"
                  :key="starter_feat_index + feat.title"
                >
                  <v-icon
                    color="primary"
                    v-text="'mdi-checkbox-marked-circle-outline'"
                    left
                  ></v-icon>
                  <span class="mr-1" v-html="feat.title"></span>
                  <VueTooltip
                    v-if="feat.info"
                    icon-color="grey"
                    color="primary"
                    text-class="white--text"
                    :v-html="feat.info"
                    :max-width="200"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-2 mx-3" cols="11">
                <v-divider></v-divider>
              </v-col>
              <v-col class="mx-6 mb-15" cols="11">
                <div class="mb-3">
                  <a class="primary--text" @click="viewAllPlanFeatures(plan)">
                    <span class="text-decoration-underline">{{
                      showAllFeature ? "Hide all features" : "View all features"
                    }}</span>
                  </a>
                </div>
                <a
                  class="primary--text"
                  href="https://roadmap.testapp.io/"
                  target="_blank"
                >
                  <span class="text-decoration-underline"
                    >View our public roadmap</span
                  >
                </a>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                v-if="
                  subscription.category === 'starter' &&
                  (subscription.status === 'active' || !subscription.status)
                "
                class="currentLabel"
                cols="12"
              >
                Current Plan
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
      <template v-for="(plan, pro_index) in getProPlans">
        <v-col
          v-if="plan.recurring === billingType"
          :key="pro_index + plan.id"
          class="d-flex"
          cols="12"
          md="4"
        >
          <v-card class="radius-10">
            <v-row class="header-radius primary no-gutters">
              <v-col cols="12" class="py-3 text-center white--text">
                <div class="mt-6 planNameFont">Pro</div>
                <div class="text-center planDesc">
                  {{ plan.description }}
                </div>
                <div class="mb-1">
                  No credit card required <br />
                  for <b> free trial</b>
                </div>
                <!-- <div class="mb-7">
                  {{ getDiscount(plan.price, plan.meta.previous_price) }}%
                  discount
                </div> -->
              </v-col>
              <v-col cols="12" class="">
                <v-img src="/media/svg/imagebuttom.svg" width="100%"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <div class="py-2">
                  <v-chip color="white" class="text-h5">
                    <span
                      v-if="plan.meta.previous_price"
                      class="mr-2 body-1 text-decoration-line-through"
                    >
                      <span v-if="plan.recurring === 'annually'">
                        {{ "$ " + plan.meta.previous_price / 12 }}
                      </span>
                      <span v-else>
                        {{ "$ " + plan.meta.previous_price }}
                      </span>
                    </span>
                    <span v-if="plan.recurring === 'annually'" class="text-h5"
                      >${{ plan.price / 12 }}</span
                    >
                    <span v-else class="text-h5">${{ plan.price }}</span>
                    <span class="mx-2 grey--text">|</span>
                    <span class="primary--text body-1">monthly</span>
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="12" class="text-center">
                <template
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status !== 'active'
                  "
                >
                  <v-btn
                    v-if="!subscribe"
                    @click="openFreeTrailModal(plan)"
                    class="text-transform-none"
                    id="startTrialProPlan"
                    depressed
                    color="primary"
                  >
                    <span> Start Trial </span>
                  </v-btn>
                  <div v-if="!subscribe" class="mt-3">
                    Or
                    <b
                      class="primary--text cursor-pointer"
                      @click="upgradePlanConfirmation(plan)"
                      >Subscribe Now</b
                    >
                  </div>
                  <v-btn
                    v-else
                    @click="upgradePlanConfirmation(plan)"
                    class="text-transform-none"
                    id="startTrialStarterPlan"
                    depressed
                    color="primary"
                  >
                    <span> Subscribe </span>
                  </v-btn>
                </template>
                <v-btn
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    !isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    (!subscription.status ||
                      subscription.status === 'active') &&
                    (subscription.category !== 'pro' || !subscription.price)
                  "
                  @click="upgradePlanConfirmation(plan)"
                  class="text-transform-none mb-4"
                  id="proSubscribeBtn"
                  color="primary"
                >
                  <span> Upgrade Plan </span>
                </v-btn>
                <template
                  v-if="
                    !getIsUserDisabled &&
                    canManageTeam &&
                    !isEmpty(getTeamTrial) &&
                    !getPromoInfo.source_name &&
                    subscription.status === 'active' &&
                    subscription.category === plan.meta.category
                  "
                >
                  <v-btn
                    color="primary"
                    class="text-transform-none"
                    depressed
                    :class="subscription.recurring !== 'annually' ? '' : 'mb-4'"
                    @click="
                      openSwitchPlanDialog(
                        'Pro',
                        subscription.recurring !== 'annually'
                          ? 'annually'
                          : 'monthly',
                        plan.id
                      )
                    "
                  >
                    Switch to
                    {{
                      subscription.recurring !== "annually"
                        ? "Annually"
                        : "Monthly"
                    }}
                  </v-btn>
                  <div
                    v-if="subscription.recurring !== 'annually'"
                    class="body-1 success--text"
                  >
                    and get 2 free months
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="subscription.recurring !== 'annually' ? 'mt-0' : ''"
                class="mx-3"
                cols="10"
              >
                <div
                  class="mb-2 pb-1 bodyFont"
                  v-for="(feat, feat_pro_index) in plan.features"
                  style="display: flex; align-items: center"
                  :key="feat_pro_index + feat.title"
                >
                  <v-icon
                    color="primary"
                    v-text="'mdi-checkbox-marked-circle-outline'"
                    left
                  ></v-icon>
                  <span class="mr-1" v-html="feat.title"></span>
                  <VueTooltip
                    v-if="feat.info"
                    icon-color="grey"
                    color="primary"
                    text-class="white--text"
                    :v-html="feat.info"
                    :max-width="200"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-2 mx-3" cols="11">
                <v-divider></v-divider>
              </v-col>
              <v-col class="mx-6 mb-15" cols="11">
                <div class="mb-3">
                  <a class="primary--text" @click="viewAllPlanFeatures(plan)">
                    <span class="text-decoration-underline">
                      {{
                        showAllFeature
                          ? "Hide all features"
                          : "View all features"
                      }}
                    </span>
                  </a>
                </div>
                <a
                  class="primary--text"
                  href="https://roadmap.testapp.io/"
                  target="_blank"
                >
                  <span class="text-decoration-underline"
                    >View our public roadmap</span
                  >
                </a>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                v-if="
                  subscription.category === plan.meta.category &&
                  (subscription.status === 'active' || !subscription.status)
                "
                class="currentLabel"
                cols="12"
              >
                Current Plan
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-row>
          <v-col v-for="i in 3" :key="i" cols="12" md="4">
            <v-skeleton-loader type="card-avatar, article"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <v-col v-if="showAllFeature" cols="12">
      <plans-table :billing-type="billingType"></plans-table>
    </v-col>
    <v-col
      v-if="fromModal && !fromGetStarted && !fromUpgradeComponent"
      cols="12"
    >
      <v-checkbox
        v-model="checkbox"
        label="Don't show this popup again."
      ></v-checkbox>
    </v-col>
    <Modal v-model="switchPlanDialog" @close="switchPlanDialog = false">
      <template #message>
        <v-row>
          <v-col cols="12" class="mt-4 bold-text font-size-h3">
            Switch to {{ changingPlan }}
            <span class="text-capitalize">({{ changingType }})</span>
          </v-col>
          <v-col cols="12" class="bodyFont">
            Are you sure you want to change {{ getTeamName }}'s team from
            {{ changingPlan }} ({{
              changingType === "monthly" ? "Annually" : "Monthly"
            }}) to {{ changingPlan }}
            <span class="text-capitalize">({{ changingType }})</span>?
            <div class="mt-6" v-if="changingType === 'monthly'">
              <v-alert color="warning" outlined>
                <span class="body-1">
                  Note: When downgrading a plan, the remaining balance will be
                  in your account for your next billing cycles.
                </span>
              </v-alert>
            </div>
            <div v-else class="mt-6">
              <v-alert outlined color="success">
                <span class="body-1">
                  Get free <b>2 months</b> when switching annual subscription 🎉
                </span>
              </v-alert>
            </div>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              @click="switchPlanDialog = false"
              class="text-transform-none mx-2"
              text
              :disabled="busy"
              color="primary"
              >Cancel</v-btn
            >
            <v-btn
              @click="changePlan"
              class="text-transform-none"
              color="primary"
              :loading="busy"
              depressed
              >Switch</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="changeModal" @close="changeModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-h6" cols="12">
            <div class="text-center">
              Your current plan is
              <b>
                {{ subscription.name + " " }}
                <span class="text-capitalize"
                  >({{ subscription.recurring }})</span
                >
              </b>
            </div>
            <div class="text-left mt-4 bodyFont">
              <div>
                - You can change your billing cycle to either Monthly or
                Annually.
              </div>
              <div class="mb-4">
                - You can upgrade or downgrade your plans at any time.
              </div>
              <div>
                Note: When downgrading a plan, the remaining balance will be in
                your account for your next billing cycles.
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 text-center" cols="12">
            <v-select
              v-model="selectedPlanId"
              item-value="id"
              filled
              id="plan"
              placeholder="Select a plan"
              :items="plansList"
            >
              <template v-slot:selection="{ item }">
                <div>
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div>
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-btn
              :disabled="subscription.id === selectedPlanId"
              color="primary"
              class="text-transform-none"
              @click="changePlan"
              :loading="busy"
              depressed
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="successModal">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h5" cols="12">
            Congratulations 🎉
          </v-col>
          <v-col class="text-center text-h6" cols="12">
            We are processing the payment <br />
            and upgrading your plan.
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="confirmationModal" @close="confirmationModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h6" cols="12">
            You are about to
            {{
              startTrial
                ? "start the 7-day free trial for the"
                : "change your plan to"
            }}
            <div>
              <b id="selectedPlanName"
                >{{ selectedPlan.name }}
                <span v-if="!startTrial" class="text-capitalize"
                  >({{ selectedPlan.recurring }})</span
                ></b
              >
              <span v-if="startTrial">plan</span>
            </div>
          </v-col>
          <v-col v-if="!startTrial" class="text-center text-h6" cols="12">
            <div v-if="selectedPlan.recurring === 'annually'">
              🎉 Congratulations you saved
              <b>${{ Math.floor((selectedPlan.price / 12) * 2) }}!</b>
              <br /><br />
              You will be billed
              <s>${{ selectedPlan.meta.previous_price }}</s>
              <b> ${{ selectedPlan.price }}</b> annually.
            </div>
            <div v-if="selectedPlan.recurring === 'monthly'">
              You will be billed <s>${{ selectedPlan.meta.previous_price }}</s>
              <b> ${{ selectedPlan.price }}</b> monthly. <br /><br />
              You could still save
              <b
                >${{
                  Math.floor((Math.ceil(selectedPlan.price * 10) / 12) * 2)
                }}</b
              >
              on annual team plan.
            </div>
          </v-col>
          <v-col class="text-center pb-0" cols="12">
            <v-btn
              id="upgradePlanBtn"
              @click="upgradePlan"
              class="text-transform-none"
              color="primary"
              :loading="loading"
              depressed
            >
              Let's do it 💪
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CHANGE_PLANS,
  GET_PLANS,
  START_FREE_TRIAL,
} from "@/store/team/team.module";
import Vue from "vue";
import dayjs from "dayjs";
import { isEmpty } from "@/core/services/helper.service";
import StorageService from "@/core/services/localstorage.service";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import PlansTable from "@/view/components/Team/PlansTable.vue";

export default {
  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
    upgradePlanText: {
      type: String,
      default: "",
    },
    fromGetStarted: {
      type: Boolean,
      default: false,
    },
    uploadedSize: {
      type: [String, Number],
      default: "",
    },
    allowedSize: {
      type: [String, Number],
      default: "",
    },
    fromUpgradeComponent: {
      type: Boolean,
      default: false,
    },
    subscribe: {
      type: Boolean,
      default: false,
    },
  },
  components: { PlansTable },
  data() {
    return {
      isEmpty,
      billingType: "annually",
      startTrial: false,
      switchPlanDialog: false,
      loading: false,
      changingPlan: "",
      changingType: "",
      confirmationModal: false,
      planLoading: false,
      busy: false,
      changeModal: false,
      showAllFeature: false,
      selectedPlanId: "",
      successModal: false,
      selectedPlan: {},
      showSubscriptionPopup: false,
      showMore: false,
    };
  },
  watch: {
    billingType(val) {
      this.$emit("updateType", val);
      window.analytics.track(`Selected ${val} plan`);
    },
  },
  computed: {
    ...mapGetters({
      plans: "getPlans",
      user: "currentUser",
      getRedirectedFromPlan: "getRedirectedFromPlan",
      getTeamId: "getTeamId",
      getTeamName: "getTeamName",
      getTeamTrial: "getTeamTrial",
    }),
    getStarterPlans() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter((x) => x.name === "Starter")
        : [];
    },
    getProPlans() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter((x) => x.name === "Pro")
        : [];
    },
    getPromoInfo() {
      return this.plans.info ? this.plans.info.promo : {};
    },
    plansList() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter(
            (plan) =>
              plan.meta.category !== this.subscription.category &&
              plan.name !== "Hobby"
          )
        : [];
    },
    hobbyFeatures() {
      return this.plans &&
        this.plans.plans &&
        this.plans.plans.find((plan) => plan.name === "Hobby")
        ? this.plans.plans.find((plan) => plan.name === "Hobby").features
        : [];
    },
    subscription() {
      return this.plans && this.plans.info ? this.plans.info.subscription : {};
    },
    checkbox: {
      get: function () {
        return (
          StorageService.getItem("popup") &&
          StorageService.getItem("popup").subscription
        );
      },
      set: function (v) {
        const popup = StorageService.getItem("popup");
        StorageService.setItem("popup", {
          ...popup,
          subscription: v,
        });
      },
    },
  },
  created() {
    if (this.fromModal) {
      this.getPlans();
      window.analytics.track("Viewed plans popup");
    }
  },
  methods: {
    getPlans() {
      if (this.planLoading) return;
      this.planLoading = true;
      this.$store
        .dispatch(GET_PLANS)
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.planLoading = false;
        });
    },
    viewAllPlanFeatures(detail) {
      this.showAllFeature = !this.showAllFeature;
      if (this.showAllFeature) {
        window.analytics.track("Viewed all plan features", {
          plan_id: detail ? detail.id : "",
        });
      }
    },
    getDiscount(currentPrice, previousPrice) {
      if (currentPrice && previousPrice) {
        return Math.floor(
          (parseInt(currentPrice) / parseInt(previousPrice)) * 100
        );
      }
    },
    startFreeTrail() {
      if (this.loading) return;
      this.loading = true;
      let plans = this.plans.plans.filter(
        (plan) => plan.meta.category === this.selectedPlan.meta.category
      );
      let selectedPlanId = plans.find((plan) => plan.recurring == "monthly").id;
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: selectedPlanId })
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.getPlans();
          this.$emit("success");
          this.loading = false;
          this.confirmationModal = false;
          this.startTrial = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    upgradePlanConfirmation(details) {
      if (this.subscription.active && this.subscription.status === "active") {
        this.confirmationModal = false;
        this.changeModal = true;
        this.selectedPlanId = details.id;
        return;
      }
      this.selectedPlan = details;
      this.selectedPlanId = details.id;
      this.confirmationModal = true;
      window.analytics.track("Opened upgrade plan popup", {
        plan_id: details.id,
        plan_name: details.name,
      });
    },
    openFreeTrailModal(details) {
      this.startTrial = true;
      this.selectedPlan = details;
      this.selectedPlanId = details.id;
      window.analytics.track("Initiated start trial", {
        plan_id: details.id,
        plan_name: details.name,
      });
      this.confirmationModal = true;
    },
    upgradePlan() {
      if (this.startTrial) {
        this.startFreeTrail();
      } else {
        if (this.subscription.active && this.subscription.status === "active") {
          this.confirmationModal = false;
          this.changeModal = true;
          this.selectedPlanId = this.selectedPlan.id;
          return;
        }

        Vue.prototype.$analytics.logEvent("upgrade_plan");
        window.analytics.track("Initiated the payment popup", {
          plan_id: this.selectedPlan.id,
          plan_name: this.selectedPlan.name,
        });

        this.confirmationModal = false;
        let self = this;
        window.Paddle.Checkout.open({
          product: this.selectedPlan.gateway_id || this.getSelectedGatewayId,
          email: this.user.email,
          passthrough: JSON.stringify({
            user_id: this.user.id,
            team_id: self.getTeamId,
          }),
          successCallback: function () {
            Vue.prototype.$analytics.logEvent("subscribe");
            window.analytics.track("subscribe", {
              plan_id: self.selectedPlan.id,
              plan_name: self.selectedPlan.name,
            });

            self.notifyUserMessage(
              "Your plan has been successfully upgraded 🎉"
            );
            self.successModal = true;
            setTimeout(() => {
              self.successModal = false;
              window.location.reload();
            }, 5000);
          },
          closeCallback: function () {
            window.analytics.track("Closed the payment popup", {
              plan_id: self.selectedPlan.id,
              plan_name: self.selectedPlan.name,
            });
          },
        });
      }
    },
    changePlan() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHANGE_PLANS, { plan_id: this.selectedPlanId })
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.$store.dispatch(GET_USER_ROLES).catch((err) => {
            this.notifyErrorMessage(err.message);
          });
          this.$emit("close");
          this.changeModal = false;
          this.upgradePlanMessage = "";
          this.switchPlanDialog = false;
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        })
        .finally(() => {
          this.$nextTick(async () => {
            await this.getPlans();
          });
        });
    },
    getFormattedDate(date) {
      return dayjs(date).format("MMM, DD YYYY");
    },
    openSwitchPlanDialog(plans, type, currentPlanId) {
      this.changingPlan = plans;
      this.changingType = type;
      if (this.plans && this.plans.plans) {
        this.selectedPlanId = this.plans.plans.find(
          (plan) => plan.name === plans && plan.recurring === type
        ).id;
      }
      window.analytics.track("Initiated switch plan", {
        plan_id: this.selectedPlanId,
        plan_name: plans,
        plan_billing: type,
        current_plan_id: currentPlanId,
        current_plan_name: plans,
        current_plan_billing: type === "monthly" ? "annually" : "monthly",
      });
      this.switchPlanDialog = true;
    },
    infoMessage() {
      if (this.upgradePlanText) {
        return this.upgradePlanText;
      } else if (this.fromGetStarted) {
        return `You can take advantage of the discount and upgrade to ${this.getRedirectedFromPlan} plan!`;
      } else {
        return `This release has exceeded your team's plan limit which is ${
          this.allowedSize
        }. You have uploaded ${this.uploadedSize}. <br> ${
          isEmpty(this.getTeamTrial)
            ? "Start your team's free trial"
            : "Upgrade now"
        } to increase the max upload limit.`;
      }
    },
  },
};
</script>

<style scoped>
.planNameFont {
  font-size: 30px;
}
.planDesc {
  font-size: 13px;
  padding-top: 10px;
}
.currentLabel {
  background-color: lightgrey;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  bottom: 0;
}
</style>
