<template>
  <v-menu
    bottom
    left
    offset-y
    v-model="menu"
    transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="pointer" v-if="displayText">
        {{ displayText || "displayText" }}
      </span>
      <v-btn
        class="main primary"
        :class="{ 'px-8 py-8 large': largeBtn }"
        v-else-if="button"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left :large="largeBtn" :class="{ 'mr-5': largeBtn }">
          {{ icon || "insert_emoticon" }}
        </v-icon>
        {{ button }}
      </v-btn>
      <v-btn
        v-else
        icon
        v-bind="attrs"
        v-on="on"
        id="AppActionsDropdown"
        :absolute="absolute"
        :top="absolute"
        :right="absolute"
      >
        <v-icon :color="color ? color : 'primary'">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense class="menu-list">
      <v-list-item-group v-model="menu" class="text-capitalize">
        <slot name="list"></slot>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "action-dropdown",
  props: [
    "defaultOpen",
    "onEdit",
    "onLeave",
    "color",
    "button",
    "icon",
    "isGetStarted",
    "absolute",
    "largeBtn",
    "displayText"
  ],
  watch: {
    defaultOpen(val) {
      this.menu = val;
    }
  },
  data() {
    return {
      menu: false
    };
  },
  created() {
    this.menu = this.defaultOpen || false;
  },
  methods: {}
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.v-menu__content {
  .menu-list {
    .v-list-item {
      // adding the border to the drpdown's listes
      border-bottom: 1px solid rgba(black, 0.1);
    }
  }
  .v-list {
    .v-list-item {
      min-width: 130px;
      padding-left: 11px;
      padding-right: 20px;
      display: flex;
      opacity: 0.8;

      &:last-child {
        border-bottom: none;
      }
      .v-icon {
        margin-right: 5px !important;
      }
      &.active {
        background: rgba($blue, 0.25);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.v-menu__content {
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5) !important;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
// romoving the padding from the dropdown
.v-list {
  padding: 0;
  font-size: 12px !important;

  .v-list-item {
  }
}
</style>
