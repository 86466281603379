<template>
  <div class="d-flex flex-column flex-root fill-height">
    <div class="d-flex flex-column fill-height">
      <div class="text-center mb-8 mt-5 display-1 bold-text">Devices</div>
      <div class="d-flex flex-column">
        <h1 v-if="appName" class="col-12 text-center">
          {{ appName }}
        </h1>
        <h3 v-if="member" class="col-12 text-center darkGrey--text">
          {{ member }}
        </h3>
        <v-divider />
      </div>
      <div v-if="loading">
        <v-card outlined class="radius-10 mt-8">
          <v-skeleton-loader
            v-for="i in 2"
            :key="i"
            type="list-item-three-line"
          />
        </v-card>
      </div>
      <div v-else class="d-flex flex-column flex-root overflow-hidden">
        <div v-if="devices.length" class="overflow-auto mt-8">
          <template v-if="release">
            <div class="user-device-list">
              <div
                v-for="(device, j) of devices"
                :key="j"
                class="user-device-info"
              >
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Name:</span>
                    {{ device.device.name }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Type:</span>
                    {{ device.device.type }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Model:</span>
                    {{ device.device.model }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Language:</span>
                    {{ device.device.language }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Manufacturer:</span>
                    {{ device.device.manufacturer }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Region:</span>
                    {{ device.device.region }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text"
                      >Notifications Enabled:</span
                    >
                    {{
                      device &&
                      device.device &&
                      device.device.app &&
                      device.device.app.push_enabled
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">SDK version:</span>
                    {{ device.device.sdk_version }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">OS version:</span>
                    <MaxText
                      v-if="device.device.os_version"
                      :text="device.device.os_version"
                      max="7"
                    />
                  </div>
                </div>
                <div class="py-1 px-3" v v-if="device.device.os == 'ios'">
                  <span class="bold-text darkGrey--text">UDID:</span>
                  {{ device.udid || "--" }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="user-device-list">
              <div
                v-for="(device, j) of devices"
                :key="j"
                class="user-device-info"
              >
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Name:</span>
                    {{ device.info.name || "--" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Type:</span>
                    {{ device.info.type || "--" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Model:</span>
                    {{ device.info.model || "--" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Language:</span>
                    {{ device.info.language || "--" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Manufacturer:</span>
                    {{ device.info.manufacturer || "--" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">Region:</span>
                    {{ device.info.region || "--" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text"
                      >Notifications Enabled:</span
                    >
                    {{
                      device &&
                      device.info &&
                      device.info.app &&
                      device.info.app.push_enabled
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">SDK version:</span>
                    {{ device.info.sdk_version || "--" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text darkGrey--text">OS version:</span>
                    <!-- check te docs for this component -->
                    <MaxText
                      v-if="device.info.os_version"
                      :text="device.info.os_version"
                      max="7"
                    />
                    <!-- {{ device.os_version }} -->
                  </div>
                </div>
                <div v-if="device.os == 'ios'">
                  <br />

                  <span class="bold-text darkGrey--text">UDID:</span>
                  {{ device.udid || "--" }}
                </div>
                <br />
              </div>
            </div>
          </template>
        </div>

        <div v-else class="no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            src="/media/placeholders/no_devices.svg"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            No devices
          </p>
          <p
            class="mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
          >
            Seems like this member haven't logged in in our app yet
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.user-device-list {
  padding: 10px;
  border: 1px solid rgba(57, 107, 219, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
}

.user-device-info {
  padding: 5px;
  border-bottom: 1px solid rgba(57, 107, 219, 0.2);
  .title {
    font-family: "Open Sans Semi Bold";
    color: rgba(70, 78, 95, 0.6);
  }
}

.user-device-info:last-of-type {
  border-bottom: none;
}
</style>

<script>
export default {
  name: "device-info",
  props: {
    app: {
      type: String,
      default: ""
    },
    appName: {
      type: String,
      default: ""
    },
    member: {
      type: String,
      default: ""
    },
    devices: {
      type: [Object, Array],
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    release: {
      type: Boolean,
      default: false
    }
  }
};
</script>
