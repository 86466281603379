<template>
  <v-row class="pa-2">
    <v-col class="font-size-h3 bold-text" cols="12">
      Cancellation Reason
    </v-col>
    <v-col class="pb-0 bodyFont">
      Providing the reason is totally optional but it's a great help for us if
      you do provide it.
    </v-col>
    <v-col cols="12">
      <v-select
        v-model="formValues.reason"
        filled
        v-validate="''"
        :items="cancelReasons"
        v-bind="veeValidate('Reason', '')"
        :hide-details="formValues.reason === 'other'"
        placeholder="Select the reason"
      ></v-select>
      <v-text-field
        v-if="formValues.reason === 'Other'"
        v-model="formValues.other_reason"
        filled
        placeholder="What is the main reason to cancel?"
        v-validate="'min:3|max:100'"
        counter="100"
        v-bind="veeValidate('reason', '')"
      ></v-text-field>
      <v-textarea
        v-model="formValues.feedback"
        filled
        v-validate="'max:600'"
        counter="600"
        v-bind="veeValidate('Feedback', '')"
        placeholder="Can you please explain the reason or maybe a feedback for us?"
      ></v-textarea>
    </v-col>
    <v-col cols="12" class="pt-0 pb-4 bodyFont">
      <div>
        If it is something that we can help with, please do
        <span
          @click="onContactus"
          class="pointer blue--text text-decoration-underline"
          >contact us</span
        >
        or
        <span
          @click="openSchedule"
          class="pointer blue--text text-decoration-underline"
        >
          schedule a call
        </span>
        and we'll gladly help you out!
      </div>
    </v-col>
    <v-col cols="12" class="text-right">
      <!-- <v-btn
        class="text-transform-none mx-2"
        depressed
        :disabled="loading"
        @click="$emit('success')"
      >
        Skip
      </v-btn> -->
      <v-btn
        color="primary"
        class="text-transform-none"
        depressed
        :loading="loading"
        :disabled="errors.any()"
        @click="cancelPlanReason"
      >
        Submit & Proceed
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import veeValidate from "@/mixins/veeValidate.js";
import { CANCEL_PLANS } from "@/store/team/team.module";
export default {
  mixins: [veeValidate],
  data() {
    return {
      cancelReasons: [
        "My project has eneded",
        "I am having issues with this service",
        "Just pausing, will be back",
        "Found alternatives",
        "Didn't see the value of it",
        "It didn't work out as per our requirements",
        "Other",
      ],
      formValues: {},
      loading: false,
    };
  },
  methods: {
    async cancelPlanReason() {
      if (await this.validateAllFields()) {
        if (this.formValues.reason === "Other") {
          this.formValues.reason = this.formValues.other_reason;
          delete this.formValues.other_reason;
        } else {
          this.formValues.other_reason
            ? delete this.formValues.other_reason
            : "";
        }

        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(CANCEL_PLANS, { ...this.formValues })
          .then((response) => {
            this.loading = false;
            this.$emit("success");
            this.notifyUserMessage(response.data.message);
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
