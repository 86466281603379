<template>
  <div>
    <!--  -->
    <invite-members-loader v-if="noRolesData" roles-only />
    <div v-else>
      <span class="mr-2">Role</span>
      <v-radio-group mandatory v-model="Roles">
        <template v-for="role in roles">
          <v-radio
            v-if="hideCustom(role)"
            :id="role.name"
            :key="role.id"
            :value="role.id"
          >
            <template v-slot:label>
              <div>
                <span class="mr-2">{{ role.name }}</span>
                <VueTooltip :text="role.description" />
              </div>
            </template>
          </v-radio>
        </template>
      </v-radio-group>
      <v-row>
        <v-col cols="4"> </v-col>
        <v-col class="text-right" cols="4"> Manage </v-col>
        <v-col class="text-center" cols="4"> View </v-col>
        <v-col v-if="activeRole.permissions" cols="12">
          <v-row
            v-for="(first, firstIndex) in Object.keys(activeRole.permissions)"
            :key="firstIndex"
            align="center"
          >
            <v-col cols="6" class="text-capitalize py-0">
              <div>
                <div>
                  {{ first }}
                </div>
                <div
                  :title="getDescriptions[first].description"
                  class="caption"
                  v-html="
                    getDescriptions ? getDescriptions[first].description : ''
                  "
                ></div>
              </div>
            </v-col>
            <v-col v-if="activeRole.permissions[first]" class="py-0" cols="6">
              <v-row>
                <template
                  v-for="(second, secondIndex) in Object.keys(
                    activeRole.permissions[first]
                  ).reverse()"
                >
                  <v-col
                    v-if="second === 'manage'"
                    class="text-right"
                    :key="secondIndex"
                    cols="6"
                  >
                    <v-checkbox
                      :disabled="!activeRole.editable"
                      hide-details
                      @change="setPermission(first, second)"
                      v-model="activeRole.permissions[first][second]"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    v-if="second === 'view'"
                    class="text-right"
                    :key="secondIndex"
                    cols="6"
                  >
                    <v-checkbox
                      :disabled="!activeRole.editable"
                      :readonly="activeRole.permissions[first]['manage']"
                      hide-details
                      v-model="activeRole.permissions[first][second]"
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import InviteMembersLoader from "@/view/components/Common/loaders/InviteMembers.vue";
import { GET_ROLES } from "@/store/team/team.module";
import * as Sentry from "@sentry/vue";

export default {
  props: {
    updateRoles: {
      type: [String, Object],
      default: "",
    },
    permission: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    InviteMembersLoader,
  },
  data() {
    return {
      Roles: null,
      allPermissions: {},
      activeRole: {},
    };
  },
  methods: {
    getActiveRole() {
      if (this.Roles) {
        return { ...this.roles.find((v) => v.id == this.Roles) };
      } else {
        return { ...this.roles[0] };
      }
    },
    hideCustom(role) {
      if (this.updateRoles && this.updateRoles.name === "Custom") {
        return true;
      } else {
        return role.name !== "Custom";
      }
    },
    setPermission(first, second) {
      if (second === "manage") {
        if (this.activeRole.permissions[first].manage === true) {
          if (
            Object.keys(this.activeRole.permissions[first]).includes("view")
          ) {
            this.activeRole.permissions[first].view = true;
          }
        } else {
          if (
            Object.keys(this.activeRole.permissions[first]).includes("view")
          ) {
            this.activeRole.permissions[first]["view"] = false;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      roles: "getRoles",
    }),
    noRolesData() {
      return isEmpty(this.roles);
    },
    getDescriptions() {
      return (
        this.roles &&
        this.roles.find((role) => role.id === this.activeRole.id).permissions
      );
    },
  },
  watch: {
    activeRole: function (v) {
      this.allPermissions = v.permissions;
      this.$emit("updateRoles", v.permissions);
      try {
        this.$emit("updateTheRole", v.id);
      } catch (error) {
        Sentry.captureMessage(error);
      }
    },
    Roles: function (v) {
      this.$emit("updateMainRole", v);
      this.activeRole = this.getActiveRole();
      const roles = this.roles.find((x) => x.name === "Custom");
      try {
        if (
          roles &&
          v === roles.id &&
          this.updateRoles &&
          this.updateRoles.name === "Custom"
        ) {
          this.activeRole.permissions = { ...this.permission };
        }
      } catch (error) {
        Sentry.captureMessage(error);
      }
    },
    updateRoles() {
      if (this.updateRoles) {
        this.Roles = this.updateRoles.id;
      }
      if (this.permission) {
        this.activeRole.permissions = { ...this.permission };
      }
    },
  },
  async created() {
    if (isEmpty(this.roles)) {
      this.$store
        .dispatch(GET_ROLES)
        .then(() => {
          this.activeRole = this.getActiveRole();
          if (this.updateRoles) {
            this.Roles = this.updateRoles.id;
            if (this.updateRoles.name === "Custom") {
              this.activeRole.permissions = { ...this.permission };
            }
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    } else {
      this.activeRole = this.getActiveRole();
    }
  },
  mounted() {
    if (this.updateRoles) {
      this.Roles = this.updateRoles.id;
      if (this.updateRoles.name === "Custom") {
        this.activeRole.permissions = { ...this.permission };
      }
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  text-align: left;
}
</style>
