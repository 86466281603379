<template>
  <v-row>
    <v-col class="bodyFont text-center mt-8" cols="12">
      <div class="mt-2">
        <v-alert outlined color="primary">
          <div v-html="message" class="text-left bodyFont"></div>
        </v-alert>
      </div>
      <div class="title" v-if="!showContactUsMessage">
        7-day free trial is ready to be activated for your team 🎉
      </div>
    </v-col>
    <template v-if="!showContactUsMessage">
      <v-col cols="12" class="success--text text-center bodyFont bold-text">
        No credit card required, just one-click away!</v-col
      >
      <v-col cols="12" class="bodyFont">
        <div class="bold-text mb-3">
          Let's review what you will unlock:
        </div>
        <ul>
          <li>
            More team members
          </li>
          <li>
            More app projects
          </li>
          <li>
            Max app upload size increased
          </li>
          <li>
            Internal storage increased
          </li>
          <li>
            Share apps publicly
          </li>
          <li>
            Integrations (TA-CLI, Slack,...)
          </li>
          <li>
            External storage
          </li>
        </ul>
        <div class="mt-3">
          More detailed info in the
          <a @click="$router.push({ name: 'plans' })">team's plan</a> page.
        </div>
      </v-col>
      <v-col class="text-center" cols="12">
        <v-btn
          class="text-transform-none"
          color="primary"
          large
          @click="startTrail"
        >
          Start Trial
        </v-btn>
        <div class="mt-2">
          Or
          <span
            class="bold-text cursor-pointer primary--text"
            @click="subscribeNow"
            >Subscribe Now</span
          >
        </div>
      </v-col>
    </template>
    <v-col v-else class="pt-0 bodyFont">
      Ops! This apps size is higher than our allowed size in our plans.
      <br /><br />
      We can still create a custom exception for your app, please
      <span @click="onContactus" class="primary--text cursor-pointer"
        >contact us</span
      >
      for more info.
    </v-col>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-upgrade-component
          :subscribe="subscribe"
          :upgrade-plan-text="getUpgradePlanText()"
          @success="$emit('close'), (openSubscriptionModal = false)"
          @close="openSubscriptionModal = false"
        ></plan-pricing>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { START_FREE_TRIAL } from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import PlanPricing from "@/view/components/Team/PlanPricing";

export default {
  components: { PlanPricing },
  props: {
    message: {
      type: String,
      default: ""
    },
    fromCreateRelease: {
      type: Boolean,
      default: false
    },
    fromAppsPage: {
      type: Boolean,
      default: false
    },
    fromMembersPage: {
      type: Boolean,
      default: false
    },
    uploadedSize: {
      type: [Number, String],
      default: ""
    },
    allowedSize: {
      type: [Number, String],
      default: ""
    },
    fileSize: {
      type: [Number, String],
      default: 0
    },
    trackerEvent: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      openSubscriptionModal: false,
      subscribe: false,
      showContactUsMessage: false
    };
  },
  created() {
    window.analytics.track("Viewed trial popup", this.trackerEvent);
    if (
      this.fromCreateRelease &&
      this.fileSize &&
      parseInt(this.fileSize) > 1000000
    ) {
      this.showContactUsMessage = true;
      window.analytics.track("Max size uploaded above 1GB");
    }
  },
  methods: {
    startTrail() {
      if (this.loading) return;
      this.loading = true;
      let planId = "";
      if (this.fromAppsPage || this.fromMembersPage) {
        planId = "V3GoPO2xXy";
      } else {
        planId =
          parseInt(this.fileSize) <= 300000 ? "V3GoPO2xXy" : "p7b2q6oz6R";
      }
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: planId })
        .then(response => {
          this.notifyUserMessage(response.message);
          this.$store.dispatch(GET_USER_ROLES).catch(err => {
            this.notifyErrorMessage(err.message);
          });
          this.loading = false;
          this.$emit("close");
          this.showStartTrialModal = false;
        })
        .catch(err => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    subscribeNow() {
      if (this.fromCreateRelease) {
        this.$emit("open-subscribe");
      } else {
        this.subscribe = true;
        this.openSubscriptionModal = true;
      }
    },
    getUpgradePlanText() {
      return "You can start a trial or directly subscribe to plan.";
    }
  }
};
</script>

<style scoped></style>
