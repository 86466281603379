<template>
  <page-wrapper
    @edit-team="drawer = true"
    @leave-team="openLeaveModal"
    from-team-page
    :bread-crumbs="breadCrumbs"
  >
    <div v-if="loading">
      <Loader />
    </div>
    <div class="mt-1" v-else>
      <membersAndinfo :team-error="errorMessage" />
    </div>
    <Modal v-model="modal">
      <template #message>
        <v-card-title class="font-weight-bold">Leave team</v-card-title>
        <v-card-text class="message">
          <p>Are you sure you want to leave the team?</p>

          <v-alert class="mt-3" border="left" color="info" icon="info" outlined>
            You will lose the access to any information regarding this team.
            <div v-if="readMore">
              <br />
              If you are the only one in the team, the team will be deleted
              along with everything attached to it such as:<br />
              <br />

              <ul>
                <li>Apps</li>
                <li>Releases</li>
                <li>Invites</li>
                <li>Members</li>
                <li>Timeline</li>
                <li>Notifications</li>
              </ul>

              <br />
              Otherwise, your data such as apps and releases will remain the
              same in the team but you will not have the access to them.
              However, you can still be invited again to the team at any time if
              it's still exits.
            </div>
            <b class="pointer" @click="readMore = !readMore" v-if="!readMore">
              {{ "Read more..." }}
            </b>
            <br />
          </v-alert>

          <p>There's no undo for this action.</p>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn class="main" @click="onCancel">no</v-btn>
          <v-btn class="main primary" @click="onConfirm" :loading="busy"
            >yes</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>
    <SidePanel v-model="drawer" @close="drawer = false">
      <!--  -->
      <update-team-info
        v-if="drawer"
        :info="info"
        @close="closeUpdateDrawer"
      ></update-team-info>
      <!--  -->
    </SidePanel>
  </page-wrapper>
</template>

<script>
import Vue from "vue";
import membersAndinfo from "@/view/components/Team/MembersAndInfo";
import { isEmpty, get } from "@/core/services/helper.service";
import { GET_TEAM_INFO } from "@/store/team/manageTeam.module";
import { mapState, mapGetters, mapMutations } from "vuex";
import Loader from "@/view/components/Common/VueLoader.vue";
import { LEAVE_TEAM } from "@/store/team/manageTeam.module";
import { CLEAR_STORE } from "@/store/users/auth.module";
import UpdateTeamInfo from "@/view/components/Team/UpdateTeamInfo";

export default {
  data() {
    return {
      loading: false,
      breadCrumbsItem: {
        "team-members": "Members",
        invites: "Invites",
        storage: "Storage",
        plans: "Plans",
        integrations: "Integrations",
        timeline: "Timeline",
      },
      errorMessage: null,
      drawer: false,
      busy: false,
      readMore: false,
      update: {},
      modal: false,
      breadCrumbs: [
        { text: "Team", disabled: false, to: { name: "team" } },
        { text: "...", disabled: false, to: { name: "team" } },
        { text: "...", disabled: true, to: { name: "team" } },
      ],
    };
  },
  components: {
    membersAndinfo,
    Loader,
    UpdateTeamInfo,
  },
  computed: {
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
    }),
    ...mapGetters({
      user: "currentUser",
      info: "getTeamInfo",
      userPermissions: "userPermissions",
    }),
    noData() {
      return isEmpty(this.info) ? true : false;
    },
  },
  watch: {
    "$route.name"(val) {
      if (Object.keys(this.breadCrumbsItem).includes(val)) {
        this.breadCrumbs[2].text = this.breadCrumbsItem[val];
      }
    },
  },
  created() {
    this.getTeamInfo();
    if (this.info && this.info.name) {
      this.breadCrumbs[1].text = this.info.name;
    }
    if (Object.keys(this.breadCrumbsItem).includes(this.$route.name)) {
      this.breadCrumbs[2].text = this.breadCrumbsItem[this.$route.name];
    }
  },

  methods: {
    getTeamInfo() {
      this.errorMessage = false;
      if (this.loading) return;
      this.loading = true;
      if (isEmpty(this.info)) {
        this.$store
          .dispatch(GET_TEAM_INFO)
          .then(() => {
            this.loading = false;
            if (this.info && this.info.name) {
              this.breadCrumbs[1].text = this.info.name;
            }
          })
          .catch((err) => {
            this.errorMessage = err.message;
            if (!this.errorMessage.includes("Invalid authorization")) {
              this.$router.push({ name: "welcome" });
            }
            this.$store.dispatch(CLEAR_STORE);
          });
      }
      this.loading = false;
    },
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
    }),
    closeUpdateDrawer(name) {
      this.drawer = false;
      this.breadCrumbs[1].text = name;
    },
    openLeaveModal() {
      this.modal = true;
      Vue.prototype.$analytics.logEvent("team", {
        status: "open",
        action: "leave",
      });
    },

    onCancel() {
      this.modal = false;
      Vue.prototype.$analytics.logEvent("team", {
        status: "cancel",
        action: "leave",
      });
    },

    onConfirm() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(LEAVE_TEAM)
        .then((response) => {
          this.busy = false;
          this.notifyUserMessage(
            response.data.message || "Successfully left the Team"
          );
          this.$store.dispatch(CLEAR_STORE);
          this.updateUserPermission({});
          this.$nextTick(() => {
            this.$router.push({ name: "welcome" }).catch(() => {});
          });
          Vue.prototype.$analytics.logEvent("team", {
            status: "success",
            action: "leave",
          });
        })
        .catch((err) => {
          this.busy = false;
          this.modal = false;
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          Vue.prototype.$analytics.logEvent("team", {
            status: "error",
            action: "leave",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/sass/pages/manage-Team";
// @import "@/assets/sass/custom/table-theme";
</style>
