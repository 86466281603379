<template>
  <v-row>
    <v-col cols="12">
      <v-img contain height="200" :src="imagePath"></v-img>
    </v-col>
    <v-col class="font-size-h3 black--text bold-text" cols="12">
      {{ title }}
    </v-col>
    <v-col class="pt-0 bodyFont" cols="12">
      <slot></slot>
    </v-col>
    <v-col cols="12">
      <v-row justify="end">
        <v-col class="text-right py-0" cols="12">
          <v-btn
            v-if="!showTrailButton && !fromExternalStorage && !isActiveTrail"
            depressed
            class="text-right mx-2 text-transform-none"
            @click="openAddAddonPopup = true"
            color="primary"
          >
            Add Addons
          </v-btn>
          <v-btn
            v-if="!showTrailButton"
            depressed
            class="text-right text-transform-none"
            @click="openSubscriptionModal = true"
            color="primary"
          >
            Upgrade Plan
          </v-btn>
          <v-btn
            v-else
            depressed
            class="text-right text-transform-none"
            @click="openSubscriptionModal = true"
            color="primary"
          >
            Start free trial
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <Modal
      v-model="openAddAddonPopup"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openAddAddonPopup = false"
    >
      <template #message>
        <add-addons
          from-upgrade
          :addon-type="fromAppPage ? 'apps' : 'members'"
          @close="openAddAddonPopup = false"
          @success="(openAddAddonPopup = false), $emit('close')"
        ></add-addons>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-upgrade-component
          :upgrade-plan-text="getUpgradePlanText()"
          @success="$emit('close'), (openSubscriptionModal = false)"
          @close="openSubscriptionModal = false"
        ></plan-pricing>
      </template>
    </Modal>
  </v-row>
</template> 

<script>
// import { isEmpty } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import PlanPricing from "@/view/components/Team/PlanPricing";
import AddAddons from "@/view/components/Team/AddAddons.vue";
export default {
  components: { PlanPricing, AddAddons },
  props: {
    title: {
      type: String,
      default: "",
    },
    fromExternalStorage: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: "",
    },
    imagePath: {
      type: String,
      default: "/media/svg/teamImage.svg",
    },
    fromAppPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSubscriptionModal: false,
      openAddAddonPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamTrial: "getTeamTrial",
    }),
    showTrailButton() {
      return this.getTeamTrial && !this.getTeamTrial.status;
    },
    isActiveTrail() {
      return this.getTeamTrial && this.getTeamTrial.status === "active";
    },
  },
  methods: {
    getUpgradePlanText() {
      return `${
        this.showTrailButton ? "Start your team's free trial" : "Upgrade now"
      }
         to ${this.infoText}.`;
    },
  },
};
</script>

<style>
</style>