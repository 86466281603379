<template>
  <v-card flat :disabled="loading">
    <v-card-title class="display-1 justify-center mb-8 mt-n3">
      Add storage
    </v-card-title>
    <v-card-text class="pa-0">
      <v-select
        v-model="formValues.s3_id"
        v-validate="'required'"
        :menu-props="{ offsetY: true }"
        attach
        @focusin.prevent="getProviderList"
        append-icon="mdi-chevron-down"
        class="mb-4"
        filled
        :items="providerList"
        :item-disabled="disableItem"
        item-value="id"
        item-text="name"
        v-bind="veeValidate('Providers', 'Providers')"
        placeholder="Select Providers"
      >
        <template #item="{ item }">
          <div class="d-flex align-center fill-width">
            <v-card
              tile
              flat
              :disabled="item.status !== 1"
              class="transparent d-flex justify-center align-center mr-4"
              style="width: 32px"
            >
              <img
                v-if="item.icon"
                height="32"
                :src="item.icon ? getStorageImg(item.icon) : null"
              />
            </v-card>
            {{ item.name }}
            <v-spacer />
            <v-chip
              v-if="item.status === 2"
              style="height: 26px; padding-bottom: 2px"
              class="radius-10 bold-text px-3"
            >
              In beta
            </v-chip>
            <v-chip
              v-if="item.status === 3"
              style="height: 26px; padding-bottom: 2px"
              class="radius-10 bold-text px-3"
            >
              Soon
            </v-chip>
            <v-chip
              v-if="item.status === 4"
              style="height: 26px; padding-bottom: 2px"
              class="radius-10 bold-text px-3"
            >
              Future
            </v-chip>
          </div>
        </template>
        <template #no-data>
          <div class="pa-4">
            <div
              v-if="storageLoading"
              class="d-flex align-center justify-center"
            >
              <ContentLoader size="25" />
            </div>
            <div v-else>No data available</div>
          </div>
        </template>
      </v-select>
      <v-text-field
        v-model="formValues.key"
        v-validate="'required'"
        filled
        :disabled="!formValues.s3_id"
        v-bind="veeValidate('Key', 'Key')"
        placeholder="Enter Key"
        class="mb-4"
      />
      <v-text-field
        v-model="formValues.secret"
        v-validate="'required'"
        filled
        :disabled="!formValues.s3_id"
        v-bind="veeValidate('Secret', 'Secret')"
        placeholder="Enter Secret"
        class="mb-4"
      />
    </v-card-text>
    <div>
      <v-btn
        @click="createStorage"
        :disabled="
          loading ||
          errors.any() ||
          !formValues.s3_id ||
          !formValues.key ||
          !formValues.secret ||
          !(getTeamPlanUsage && getTeamPlanUsage.connect_storage)
        "
        :loading="loading"
        class="main large mx-auto wide primary"
      >
        Add
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import {
  GET_PROVIDERS,
  CREATE_STORAGE,
  UPDATE_STORAGE,
} from "@/store/team/storage.module";
import { mapGetters, mapMutations } from "vuex";
import veeValidate from "@/mixins/veeValidate";

export default {
  mixins: [veeValidate],
  props: {
    actionsData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formValues: {},
      loading: false,
      storageLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      providerList: "getProvidersList",
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
  },
  created() {
    this.formValues = this.actionsData;
    // if (!(this.providerList && this.providerList.length)) {
    //   this.getProviderList();
    // }
  },
  methods: {
    ...mapMutations({
      appendExternalStorage: "appendExternalStorage",
    }),
    disableItem(item) {
      if (item.status !== 1) {
        return true;
      }
      return false;
    },
    async getProviderList() {
      if (!(this.providerList && this.providerList.length)) {
        this.storageLoading = true;
        await this.$store.dispatch(GET_PROVIDERS);
        this.storageLoading = false;
      }
    },
    async createStorage() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        if (this.actionsData && this.actionsData.id) {
          this.$store
            .dispatch(UPDATE_STORAGE, this.formValues)
            .then((response) => {
              this.loading = false;
              this.notifyUserMessage(response.message);
              this.$emit("refresh");
            })
            .catch((err) => {
              this.loading = false;
              this.notifyErrorMessage(err.message);
            });
        } else {
          this.$store
            .dispatch(CREATE_STORAGE, this.formValues)
            .then((response) => {
              const providerInfo = this.providerList.find(
                (provider) => provider.id === this.formValues.s3_id
              );
              const dataToAppend = {
                id: response.id,
                bucket: response.bucket || "-",
                name: providerInfo.name,
                icon: providerInfo.icon,
                endpoint: response.endpoint,
                region: providerInfo.region,
                key: this.formValues.key,
                secret: "**********",
                meta: {
                  storage: 0,
                  total_apps: 0,
                  total_releases: 0,
                },
              };
              this.appendExternalStorage(dataToAppend);
              this.notifyUserMessage(response.message);
              this.$emit("refresh", response);
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.notifyErrorMessage(err.message);
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
