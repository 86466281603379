<template>
  <div class="">
    <v-row v-if="planLoading">
      <v-col class="text-center">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-for="i in 3" :key="i" cols="12" md="4">
            <v-skeleton-loader type="card-avatar, article"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-center">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-if="!isEmpty(getTeamTrial) && getTeamTrial.status === 'active'"
        cols="12"
        id="plansPageActiveTrialInfo"
      >
        <v-alert outlined color="primary">
          <v-row class="bodyFont">
            <v-col cols="12" class="mt-3">
              <b
                >Your team's trial period is going to be ended
                <vue-hoverable-date
                  :date="getTeamTrial.end_at"
                  with-ending-dot
                ></vue-hoverable-date>
              </b>
              <br />
              Upgrade now to stay on this plan.</v-col
            >
            <v-col cols="12">
              <div>Once it's ended:</div>
              <ul>
                <li>All releases above the storage limit will be archived.</li>
                <li>
                  All the unlocked features will be locked on the free plan.
                </li>
                <li>
                  All the members over limit will be deactivate on the free
                  plan.
                </li>
                <li>
                  All the apps project over limit will be deleted on the free
                  plan.
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>

      <v-col cols="12" v-if="canManageApp">
        <v-card outlined>
          <v-card-title class="text-h5"> Current subscription </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-5 py-5" flat color="">
                  <v-row>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont"> Plan: </span>
                        <span class="bold-text mx-2 bodyFont">{{
                          subscription.name
                        }}</span>
                      </div>
                    </v-col>
                    <v-col
                      v-if="getPromoInfo && getPromoInfo.source_name"
                      cols="12"
                      md="3"
                    >
                      <div>
                        <span class="bodyFont"> Source Name: </span>
                        <span class="bold-text mx-2 bodyFont">{{
                          getPromoInfo.source_name
                        }}</span>
                      </div>
                    </v-col>
                    <v-col
                      v-if="getPromoInfo && getPromoInfo.price"
                      cols="12"
                      md="3"
                    >
                      <div>
                        <span class="bodyFont"> Price: </span>
                        <span class="bold-text mx-2 bodyFont"
                          ><span
                            v-if="subscription.active"
                            id="currentPlanName"
                          >
                            ${{ getPromoInfo.price }}/annually
                          </span>
                          <span v-else>$0</span></span
                        >
                      </div>
                    </v-col>
                    <v-col
                      v-if="getPromoInfo && getPromoInfo.original_price"
                      cols="12"
                      md="3"
                    >
                      <div>
                        <span class="bodyFont"> Original Price: </span>
                        <span class="bold-text mx-2 bodyFont"
                          >${{ getPromoInfo.original_price }}</span
                        >
                      </div>
                    </v-col>
                    <template v-if="getPromoInfo && !getPromoInfo.source_name">
                      <v-col cols="12" md="3">
                        <div>
                          <span class="bodyFont"> Price: </span>
                          <span class="bold-text mx-2 bodyFont">
                            <span>
                              <span id="currentPlanPrice">
                                ${{ subscription.price || "0" }}</span
                              ><span v-if="subscription.active"
                                >/{{ subscription.recurring }}
                              </span>
                            </span></span
                          >
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          getSelectedPlanPrice &&
                          getSelectedPlanPrice !== parseInt(subscription.price)
                        "
                        cols="12"
                        md="3"
                      >
                        <div>
                          <span class="bodyFont">Original Price: </span>
                          <span class="bold-text mx-2 bodyFont">
                            <span v-if="subscription.active">
                              <span id="originalPlanPrice">
                                ${{ getSelectedPlanPrice }}</span
                              >/{{ subscription.recurring }}
                            </span>
                            <span v-else>$0</span></span
                          >
                        </div>
                      </v-col>
                      <v-col
                        v-if="getTeamTrial && getTeamTrial.status !== 'active'"
                        cols="12"
                        md="3"
                      >
                        <div>
                          <span class="bodyFont">Next Billing Date: </span>
                          <span class="bold-text mx-2 bodyFont">
                            <span class="">
                              <span
                                v-if="subscription.next_bill_date"
                                id="planNextBillingDate"
                              >
                                {{
                                  getFormattedDate(subscription.next_bill_date)
                                }}
                              </span>
                              <span v-else class="">-</span>
                            </span></span
                          >
                        </div> </v-col
                      ><v-col
                        v-if="getTeamTrial && getTeamTrial.status === 'active'"
                        cols="12"
                        md="3"
                      >
                        <div>
                          <span class="bodyFont">Trail End Date: </span>
                          <span class="bold-text mx-2 bodyFont">
                            <span class="">
                              <span
                                v-if="getTeamTrial.end_at"
                                id="planEndAt"
                                class="font-weight-bold"
                              >
                                {{
                                  getFormattedDate(
                                    parseInt(getTeamTrial.end_at)
                                  )
                                }}
                              </span>
                              <span v-else class="">-</span>
                            </span></span
                          >
                        </div>
                      </v-col>
                    </template>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">App Projects: </span>
                        <span class="bold-text mx-2 bodyFont">
                          {{ info ? info.total_apps || "0" : "0" }}/{{
                            get(currentPlanData, "limits.apps", "∞")
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Max App Size: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <span class="">
                            {{
                              formatBytes(
                                currentPlanData
                                  ? currentPlanData.max_upload_size / 1000
                                  : 0
                              )
                            }}/release
                          </span></span
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Releases: </span>
                        <span class="bold-text mx-2 bodyFont">
                          {{ info ? info.total_releases || "0" : "0" }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Release Expiry: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <span
                            v-if="
                              currentPlanData && currentPlanData.release_expire
                            "
                          >
                            {{
                              getDurationOnDays(currentPlanData.release_expire)
                            }}
                          </span>
                          <span v-else>-</span>
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Total Members: </span>
                        <span class="bold-text mx-2 bodyFont">
                          {{ info ? info.total_members || "0" : "0" }}/{{
                            get(currentPlanData, "limits.members", "∞")
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Connect Storage: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <v-icon
                            v-text="
                              currentPlanData && currentPlanData.connect_storage
                                ? 'mdi-check'
                                : 'mdi-close'
                            "
                            :color="
                              currentPlanData && currentPlanData.connect_storage
                                ? 'primary'
                                : 'error'
                            "
                          ></v-icon>
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Storage: </span>
                        <span class="bold-text mx-2 bodyFont">
                          {{ formatBytes(info ? info.storage : 0) }}/{{
                            formatBytes(
                              get(plans, "info.plan_usage.storage", 0)
                            )
                          }}
                          ({{
                            (
                              ((info ? info.storage : 0) /
                                (plans && plans.info && plans.info.plan_usage
                                  ? plans.info.plan_usage.storage
                                  : 0)) *
                              100
                            ).toFixed(2)
                          }}%)
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">External Storage: </span>
                        <span class="bold-text mx-2 bodyFont">
                          {{ formatBytes(info ? info.external_storage : 0) }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Integrations: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <v-icon
                            v-text="
                              currentPlanData && currentPlanData.integrations
                                ? 'mdi-check'
                                : 'mdi-close'
                            "
                            :color="
                              currentPlanData && currentPlanData.integrations
                                ? 'primary'
                                : 'error'
                            "
                          ></v-icon>
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">TA-CLI: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <v-icon
                            v-text="
                              currentPlanData && currentPlanData.ta_cli
                                ? 'mdi-check'
                                : 'mdi-close'
                            "
                            :color="
                              currentPlanData && currentPlanData.ta_cli
                                ? 'primary'
                                : 'error'
                            "
                          ></v-icon>
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" md="3">
                      <div>
                        <span class="bodyFont">Push Notifications: </span>
                        <span class="bold-text mx-2 bodyFont">
                          <v-icon
                            v-text="
                              currentPlanData &&
                              currentPlanData.push_notifications
                                ? 'mdi-check'
                                : 'mdi-close'
                            "
                            :color="
                              currentPlanData &&
                              currentPlanData.push_notifications
                                ? 'primary'
                                : 'error'
                            "
                          ></v-icon>
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="font-size-h3 bold-text">Addons</div>
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col class="bodyFont" md="8" cols="12">
                    <div class="mb-6" v-if="extraFreeApp > 0">
                      We have added <b>{{ extraFreeApp }}</b> app project<span
                        v-if="extraFreeApp !== 1"
                        >s</span
                      >
                      worth of ${{ extraFreeApp * addonsPrice.apps }}/m for
                      <b>free</b>
                      {{
                        get(currentPlanData, "limits.message", "") ||
                        "as thank you for being an early subscriber."
                      }}
                    </div>
                    <div class="mb-6" v-if="extraFreeMember > 0">
                      We have added <b>{{ extraFreeMember }}</b> team
                      member<span v-if="extraFreeMember !== 1">s</span> worth of
                      ${{ extraFreeMember * addonsPrice.members }}/m for
                      <b>free</b>
                      {{
                        get(currentPlanData, "limits.message", "") ||
                        "as thank you for being an early subscriber."
                      }}
                    </div>
                    <div class="mb-6">
                      Your team have
                      <b>{{ addons.apps || 0 }}</b> extra app<span
                        v-if="addons.apps && addons.apps !== 1"
                        >s</span
                      >
                      project.
                      <span
                        v-if="
                          canManageTeam &&
                          getTeamTrial.status !== 'active' &&
                          !getIsUserDisabled
                        "
                      >
                        <span
                          @click="openAddonsDialog('apps')"
                          class="cursor-pointer primary--text"
                        >
                          Click here
                        </span>

                        to manage.
                      </span>

                      <span
                        v-else-if="
                          !getIsUserDisabled &&
                          canManageTeam &&
                          (getTeamTrial.status === 'active' ||
                            !subscription.active)
                        "
                      >
                        <span
                          @click="scrollToPlans"
                          class="primary--text cursor-pointer"
                          >Upgrade</span
                        >
                        to manage addons.
                      </span>
                    </div>

                    <div>
                      Your team have
                      <b>{{ addons.members || 0 }}</b> extra member<span
                        v-if="addons.members && addons.members > 1"
                        >s</span
                      >.
                      <span
                        v-if="
                          canManageTeam &&
                          getTeamTrial.status !== 'active' &&
                          !getIsUserDisabled
                        "
                      >
                        <span
                          @click="openAddonsDialog('members')"
                          class="cursor-pointer primary--text"
                          >Click here</span
                        >
                        to manage.
                      </span>
                      <span
                        v-else-if="
                          !getIsUserDisabled &&
                          canManageTeam &&
                          (getTeamTrial.status === 'active' ||
                            !subscription.active)
                        "
                      >
                        <span
                          @click="scrollToPlans"
                          class="primary--text cursor-pointer"
                          >Upgrade</span
                        >
                        to manage addons.
                      </span>
                    </div>
                  </v-col>
                  <!--                  <v-col md="4" cols="12">-->
                  <!--                    <v-card outlined class="pa-3">-->
                  <!--                      <v-card-title>-->
                  <!--                        Invoice-->
                  <!--                      </v-card-title>-->
                  <!--                      <v-card-text>-->
                  <!--                        <div class="bodyFont">-->
                  <!--                          <v-row align="center">-->
                  <!--                            <v-col cols="6">Base plan price</v-col>-->
                  <!--                            <v-col class="text-right" cols="6">-->
                  <!--                              <b> ${{ subscription.price || 0 }} </b>-->
                  <!--                            </v-col>-->
                  <!--                          </v-row>-->
                  <!--                          <v-row align="center">-->
                  <!--                            <v-col cols="6">App projects addons</v-col>-->
                  <!--                            <v-col class="text-right" cols="6">-->
                  <!--                              <b> ${{ appsAddonPrice }} </b>-->
                  <!--                            </v-col>-->
                  <!--                          </v-row>-->
                  <!--                          <v-row align="center">-->
                  <!--                            <v-col cols="6">Members addons</v-col>-->
                  <!--                            <v-col class="text-right" cols="6">-->
                  <!--                              <b> ${{ membersAddonPrice }} </b>-->
                  <!--                            </v-col>-->
                  <!--                          </v-row>-->
                  <!--                          <v-row>-->
                  <!--                            <v-col cols="12">-->
                  <!--                              <v-divider></v-divider>-->
                  <!--                            </v-col>-->
                  <!--                          </v-row>-->
                  <!--                          <v-row align="center">-->
                  <!--                            <v-col cols="6">Total Price</v-col>-->
                  <!--                            <v-col class="text-right" cols="6">-->
                  <!--                              <b>-->
                  <!--                                ${{-->
                  <!--                                  parseInt(subscription.price || 0) +-->
                  <!--                                    membersAddonPrice +-->
                  <!--                                    appsAddonPrice-->
                  <!--                                }}-->
                  <!--                              </b>-->
                  <!--                            </v-col>-->
                  <!--                          </v-row>-->
                  <!--                        </div>-->
                  <!--                      </v-card-text>-->
                  <!--                    </v-card>-->
                  <!--                  </v-col>-->
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="body-1 dark-grey-text">
                  If you have any questions or need help, please
                  <span
                    @click="onContactus"
                    class="pointer blue--text text-decoration-underline"
                    >contact us</span
                  >
                  at any time.
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4" id="managePlanActions">
            <v-row>
              <v-col cols="12">
                <v-btn
                  class="text-transform-none"
                  depressed
                  :disabled="
                    getIsUserDisabled ||
                    subscription.status !== 'active' ||
                    !subscription.urls ||
                    !subscription.urls.update_url
                  "
                  color="primary"
                  @click="openUpdateUrl"
                >
                  Update billing info</v-btn
                >
                <!-- <v-btn
                  v-if="getPromoInfo && !getPromoInfo.source_name"
                  class="text-transform-none mx-2"
                  depressed
                  id="openChangePlanBtn"
                  color="primary"
                  :disabled="
                    !canManageTeam ||
                      getIsUserDisabled ||
                      subscription.status !== 'active'
                  "
                  @click="openChangePlanModal"
                >
                  Change plan</v-btn
                > -->
                <v-btn
                  v-if="
                    getPromoInfo &&
                    !getPromoInfo.source_name &&
                    subscription.email
                  "
                  @click="emailInvoices"
                  class="text-transform-none mx-2"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
                  :disabled="
                    !canManageTeam ||
                    getIsUserDisabled ||
                    subscription.status !== 'active'
                  "
                  depressed
                  color="primary"
                >
                  Email me my invoice history</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="mb-7" cols="12" md="12">
        <v-card flat>
          <div
            class="
              font-weight-bold
              text-center text-decoration-underline
              mb-7
              titleFont
            "
          >
            Features
          </div>
          <v-row align="center">
            <v-col class="my-5" cols="9">
              <v-row>
                <v-col
                  class="bodyFont py-4"
                  v-for="(feat, index) in features"
                  :key="index"
                  cols="12"
                  md="6"
                >
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-icon
                        size="30"
                        v-text="'mdi-checkbox-marked-circle-outline'"
                        color="primary"
                      ></v-icon>
                    </v-col>
                    <v-col>
                      <div class="font-size-h3 bold-text">
                        {{ feat.title }}
                      </div>
                      <div
                        v-html="feat.description"
                        class="dark-grey-text"
                      ></div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-card
                class="radius-10 white--text"
                color="primary"
                elevation="4"
                outlined
              >
                <v-card-title>
                  <div class="bold-text font-size-h3">
                    <v-icon
                      left
                      color="white"
                      v-text="'mdi-trophy-outline'"
                    ></v-icon>
                    Early subscribers
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row class="white--text bodyFont">
                    <v-col cols="12">
                      Enjoy the benefits of being an early subscriber with a
                      discount that will be applied to all your recurring bills
                      and much more ❤️ <br /><br />
                      With your help, we can achieve our mission to enhance the
                      app distribution and make it easier for everyone for a
                      better quality app.
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn @click="onContactus" class="pa-4 text-transform-none"
                    >Have a question?</v-btn
                  >
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="mt-15 mb-7">
          <v-col cols="12" md="12" class="pb-0 text-center">
            <div
              class="font-weight-bold text-decoration-underline mb-5 titleFont"
            >
              Pricing
            </div>
            <div class="font-size-h4 dark-grey-text">
              <div>
                We are constantly equipping <b>TestApp.io</b> with more features
              </div>
              <div>
                to help all developers around the world and make their lives
                much easier.
              </div>
            </div>
          </v-col>
        </v-row>
        <plan-pricing
          @updateType="billingType = $event"
          @cancelPlan="openCancelDialog"
          id="plansCards"
        ></plan-pricing>
      </v-col>
      <v-col cols="12" class="dark-grey-text">
        <div class="my-2">
          You can change your billing cycle or switch between the plans at any
          time.
        </div>

        <div class="my-2">
          We have add-ons available on request to increase the limits for
          Starter and Pro plans.
          <span
            @click="onContactus"
            class="primary--text pointer text-decoration-underline"
            >Contact us
          </span>
          for more info.
        </div>
      </v-col>
      <v-col cols="12" class="my-6">
        <v-card outlined class="pa-3 pt-1 lightBackground">
          <v-row>
            <v-col class="pl-3 text-left" cols="12" md="8">
              <v-row class="pa-5">
                <v-col cols="12">
                  <div class="text-h5">Enterprise</div>
                </v-col>
                <v-col class="dark-grey-text" cols="12">
                  A whitelabel solution to be hosted in your servers for
                  internal and external testing or sharing for your all apps and
                  releases.
                </v-col>
                <v-col class="dark-grey-text" cols="12">
                  <div>
                    Coming Soon.
                    <!-- <span
                      @click="onContactus"
                      class="pointer blue--text text-decoration-underline"
                      >Reserve a seat</span
                    >. -->
                  </div>
                  <div>
                    No limitations. All features available. Unlimited updates.
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0" md="4">
              <v-img
                class="mx-auto my-auto"
                src="/media/svg/teamImage.svg"
                width="50%"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="
          canManageApp &&
          getPromoInfo &&
          !getPromoInfo.source_name &&
          !getIsUserDisabled
        "
        class="mb-8"
        cols="12"
        id="cancelSubscriptionComponent"
      >
        <v-card class="pa-3 pt-1 transparentBackground lightBorder" outlined>
          <v-card-title class="text-h5"> Cancel subscription </v-card-title>
          <div
            class="dark-grey-text mx-4 pb-3"
            v-if="subscription.status && subscription.status === 'cancel'"
          >
            You have cancelled this subscription which will be effective from
            <b>{{
              getFormattedDate(subscription.cancellation_effective_date)
            }}</b
            >.
          </div>
          <div class="dark-grey-text mx-4 pb-3" v-else>
            Cancelling subscription will implement by next billing cycle<span
              v-if="subscription.active && subscription.next_bill_date"
            >
              on
              <b>{{ getFormattedDate(subscription.next_bill_date) }}</b></span
            >.
          </div>
          <v-card-actions>
            <v-btn
              :disabled="
                subscription &&
                subscription.urls &&
                (!subscription.urls.cancel_url ||
                  subscription.status !== 'active')
              "
              id="cancelSubscriptionBtn"
              class="text-transform-none mx-2"
              depressed
              color="primary"
              @click="openCancelDialog"
            >
              Cancel subscription</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="my-15" cols="12">
        <plans-questions></plans-questions>
      </v-col>
    </v-row>
    <Modal
      v-if="openCancelReasonModal"
      v-model="openCancelReasonModal"
      @close="openCancelReasonModal = false"
    >
      <template slot="message">
        <cancel-plan-modal @success="openCancelUrl"></cancel-plan-modal>
      </template>
    </Modal>
    <Modal v-if="cancelDialog" v-model="cancelDialog">
      <template #message>
        <div class="my-n3">
          <v-card-title class="font-weight-bold">
            Cancel subscription
          </v-card-title>
          <v-divider />
          <v-card-text class="message">
            Cancelling the subscription will be implemented
            <span v-if="subscription.cancellation_effective_date">
              on
              <b
                >{{
                  getFormattedDate(subscription.cancellation_effective_date)
                }}.</b
              >
            </span>
            <span v-else>
              by the next billing cycle
              <b>{{ getFormattedDate(subscription.next_bill_date) }}</b
              >.
            </span>
            <br />
            <br />
            The team will be downgraded to the free plan. Members might lose
            access to certain features when downgrading as well as archiving the
            over-the-limit releases.
            <br />
            <br />
            Once the cancellation implemented, there's no undo but until then
            you can still subscribe again to stop the cancellation order before
            the cancellation date.
            <br />

            <br />

            <v-alert
              class="mt-3"
              border="left"
              icon="info"
              color="primary"
              outlined
            >
              If it is something that we can help with, please do
              <span
                @click="onContactus"
                class="pointer blue--text text-decoration-underline"
                >contact us</span
              >
              or
              <span
                @click="openSchedule"
                class="pointer blue--text text-decoration-underline"
              >
                schedule a call
              </span>
              and we'll gladly help you out!
            </v-alert>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pb-0">
            <v-spacer />
            <v-btn
              text
              id="cancelDeclineBtn"
              @click="cancelDialog = false"
              class="main primary"
              >Close</v-btn
            >
            <v-btn
              id="cancelConfirmBtn"
              @click="openCancelReason"
              class="text-transform-none"
              >Got it, cancel it please</v-btn
            >
          </v-card-actions>
        </div>
      </template>
    </Modal>
    <Modal v-model="changeModal" @close="changeModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-h6" cols="12">
            <div class="text-center">
              Your current plan is
              <b id="changePlanSubscriptionName">
                {{ subscription.name + " " }}
                <span class="text-capitalize"
                  >({{ subscription.recurring }})</span
                >
              </b>
            </div>
            <div class="text-left mt-4 bodyFont">
              <div>
                - You can change your billing cycle to either Monthly or
                Annually.
              </div>
              <div class="mb-4">
                - You can upgrade or downgrade your plans at any time.
              </div>
              <div>
                Note: When downgrading a plan, the remaining balance will be in
                your account for your next billing cycles.
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 text-center" cols="12">
            <v-select
              v-model="selectedPlanId"
              item-value="id"
              filled
              id="changePlanSelect"
              placeholder="Select a plan"
              :items="plansList"
            >
              <template v-slot:selection="{ item }">
                <div>
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div :id="item.name + item.recurring">
                  {{ item.name + " " }}
                  <span class="text-capitalize">({{ item.recurring }})</span>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-btn
              :disabled="subscription.id === selectedPlanId"
              color="primary"
              id="changePlanBtn"
              class="text-transform-none"
              @click="changePlan"
              :loading="busy"
              depressed
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      v-model="showAddonsForm"
      :width="$vuetify.breakpoint.mdAndUp ? '600' : ''"
      @close="showAddonsForm = false"
    >
      <template #message>
        <add-addons
          v-if="showAddonsForm"
          :addon-type="addonsType"
          :addon-stats="addons"
          @success="getPlans"
          @close="showAddonsForm = false"
        ></add-addons>
      </template>
    </Modal>

    <Modal v-model="showRemoveAddon" @close="showRemoveAddon = false">
      <template #message>
        <v-row>
          <v-col cols="12" class="bold-text font-size-h3 mt-4"
            >Remove Addons</v-col
          >
          <v-col cols="12" class="bodyFont">
            <div>
              Are you sure to remove this addons ? <br /><br />
              It will be cancelled by next billing cycle and all your apps and
              members after the limit will be disabled.
            </div>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              :disabled="loading"
              color="primary"
              text
              @click="showRemoveAddon = false"
              class="text-transform-none mx-3"
              >Cancel</v-btn
            >
            <v-btn
              :loading="loading"
              class="text-transform-none"
              color="primary"
              @click="removeAddons"
              >Remove</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </Modal>
  </div>
</template>
<script>
import Vue from "vue";
import PlanPricing from "@/view/components/Team/PlanPricing";
import {
  CHANGE_PLANS,
  REMOVE_ADDONS,
  GET_PLANS,
} from "@/store/team/team.module";
import { CLEAR_STORE } from "@/store/users/auth.module";
import { mapGetters } from "vuex";
import {
  formatBytes,
  get,
  isEmpty,
  getDurationOnDays,
} from "@/core/services/helper.service";
import dayjs from "dayjs";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import PlansQuestions from "@/view/components/Team/PlansQuestions.vue";
import CancelPlanModal from "@/view/components/Team/CancelPlanModal.vue";
import AddAddons from "@/view/components/Team/AddAddons.vue";

export default {
  name: "plans",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { PlanPricing, PlansQuestions, CancelPlanModal, AddAddons },
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      getDurationOnDays,
      successModal: false,
      showAddonsForm: false,
      showRemoveAddon: false,
      billingType: "annually",
      busy: false,
      clickedId: "",
      apps: 0,
      members: 0,
      changeModal: false,
      selectedPlan: {},
      addonsHeader: [
        {
          text: "Amount",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {
          text: "Currency",
          align: "start",
          sortable: false,
          value: "currency",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Is Recurring",
          align: "start",
          sortable: false,
          value: "is_recurring",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
      confirmationModal: false,
      selectedPlanId: "",
      addonsType: "",
      selectedAddonId: "",
      cancelDialog: false,
      openCancelReasonModal: false,
      features: [
        {
          title: "Android & iOS",
          description:
            "One platform. Upload your app and install from our mobile app",
        },
        {
          title: "App projects",
          description:
            "Apps or projects it's a place where your app releases will be hosted in",
        },
        {
          title: "Notify everyone",
          description:
            "Notify your team members about your new releases for feedback",
        },
        {
          title: "Multiple teams",
          description:
            "Create or join multiple teams.Team will hold your apps & releases",
        },
        {
          title: "Unlimited app releases",
          description:
            "Release for both Android & iOS at the same time with a simple drag & drop",
        },
        {
          title: "Automate your releases",
          description: `With our <a href="https://github.com/testappio/cli" target="_blank">TA CLI</a>, you can deploy to both Android & iOS with a single command-line`,
        },
      ],
      planLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      plans: "getPlans",
      user: "currentUser",
      getTeamTrial: "getTeamTrial",
    }),
    info() {
      return this.plans.info ? this.plans.info.team_usage : {};
    },
    currentPlanData() {
      return this.plans.info ? this.plans.info.plan_usage : {};
    },
    addons() {
      return this.plans.info && this.plans.info.addons
        ? this.plans.info.addons
        : {};
    },
    plansList() {
      return this.plans && this.plans.plans
        ? this.plans.plans.filter(
            (plan) => plan.id !== this.subscription.id && plan.name !== "Hobby"
          )
        : [];
    },
    getPromoInfo() {
      return this.plans.info ? this.plans.info.promo : {};
    },
    extraFreeApp() {
      let totalLimit =
        parseInt(
          this.subscription && this.subscription.limits
            ? this.subscription.limits.apps || 0
            : 0
        ) + parseInt(this.addons ? this.addons.apps || 0 : 0);
      return (
        parseInt(
          this.currentPlanData && this.currentPlanData.limits
            ? this.currentPlanData.limits.apps || 0
            : 0
        ) - totalLimit
      );
    },
    appsAddonPrice() {
      return (
        (this.addons.apps || 0) * (this.subscription.name === "Starter" ? 1 : 3)
      );
    },
    membersAddonPrice() {
      return (
        (this.addons.members || 0) *
        (this.subscription.name === "Starter" ? 2 : 4)
      );
    },
    extraFreeMember() {
      let totalLimit =
        parseInt(
          this.subscription && this.subscription.limits
            ? this.subscription.limits.members || 0
            : 0
        ) + parseInt(this.addons ? this.addons.members || 0 : 0);
      return (
        parseInt(
          this.currentPlanData && this.currentPlanData.limits
            ? this.currentPlanData.limits.members || 0
            : 0
        ) - totalLimit
      );
    },
    getSelectedPlanPrice() {
      return this.plans.plans &&
        this.plans.plans.find((plan) => plan.id === this.subscription.id)
        ? this.plans.plans.find((plan) => plan.id === this.subscription.id)
            .price
        : "";
    },
    subscription() {
      return this.plans && this.plans.info ? this.plans.info.subscription : {};
    },
    addonsPrice() {
      const currentActivePlan = this.plans.plans.find(
        (plan) => plan.id === this.subscription.id
      );
      return currentActivePlan ? currentActivePlan.addons_price : {};
    },
  },
  created() {
    Vue.prototype.$analytics.logEvent("list_plans");
    this.getPlans();
  },
  methods: {
    openChangePlanModal() {
      this.selectedPlanId = this.subscription.id;
      this.upgradePlanMessage = "Please select new plan to continue";
      this.changeModal = true;
      window.analytics.track("Opened change plan popup", {
        plan_id: this.subscription.id,
        plan_name: this.subscription.name,
      });
    },
    openCancelReason() {
      this.cancelDialog = false;
      this.openCancelReasonModal = true;
    },
    openAddonsDialog(type) {
      this.addonsType = type;
      this.showAddonsForm = true;
    },
    getPlans() {
      if (this.planLoading) return;
      this.planLoading = true;
      this.$store
        .dispatch(GET_PLANS)
        .then(() => {
          this.planLoading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.$router.push({ name: "welcome" }).catch();
          this.$store.dispatch(CLEAR_STORE);
          this.planLoading = false;
        });
    },
    changePlan() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(CHANGE_PLANS, { plan_id: this.selectedPlanId })
        .then((response) => {
          setTimeout(() => {
            this.getPlans();
            this.changeModal = false;
            this.notifyUserMessage(response.message);
            this.$store.dispatch(GET_USER_ROLES).catch((err) => {
              this.notifyErrorMessage(err.message);
            });
            this.upgradePlanMessage = "";
            this.busy = false;
          }, 3000);
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    openCancelDialog() {
      this.cancelDialog = true;
      window.analytics.track("Opened cancelled popup");
    },
    emailInvoices() {
      window.Paddle.User.History(this.subscription.email, null, (response) => {
        if (response.success) {
          window.analytics.track("Email me my invoice success", {
            from: "plans",
          });
          this.notifyUserMessage({
            message: "Invoice successfully mailed to your email",
          });
        } else {
          window.analytics.track("Email me my invoice failed", {
            from: "plans",
          });
        }
      });
    },
    removeAddons() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(REMOVE_ADDONS, { id: this.selectedAddonId })
        .then((response) => {
          this.loading = false;
          this.notifyUserMessage(response.message);
          this.showRemoveAddon = false;
          this.getPlans();
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openUpdateUrl() {
      Vue.prototype.$analytics.logEvent("open_update_plan");
      window.analytics.track("Opened update billing info popup");

      let self = this;
      window.Paddle.Checkout.open({
        override:
          this.subscription && this.subscription.urls
            ? this.subscription.urls.update_url
            : "",
        successCallback: function () {
          Vue.prototype.$analytics.logEvent("update_plan");
          window.analytics.track("Updated billing info");
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage("Your plan has been updated");
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          window.analytics.track("Closed the update popup", {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
        },
      });
    },
    getFormattedDate(date) {
      if (!Number.isInteger(date)) {
        return dayjs(date).format("MMM, DD YYYY");
      }
      return dayjs(date * 1000).format("MMM, DD YYYY");
    },
    openCancelUrl() {
      this.openCancelReasonModal = false;
      window.analytics.track("initiated cancel plan");
      Vue.prototype.$analytics.logEvent("open_cancel_plan");

      this.cancelDialog = false;
      let self = this;
      window.Paddle.Checkout.open({
        override: this.subscription.urls.cancel_url,
        successCallback: function () {
          window.analytics.track("Cancelled the plan popup", {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
          Vue.prototype.$analytics.logEvent("cancel_plan");
          window.Paddle.Spinner.show();
          setTimeout(() => {
            self.notifyUserMessage(
              "Your plan has been scheduled for cancellation"
            );
            self.getPlans();
            window.Paddle.Spinner.hide();
          }, 5000);
        },
        closeCallback: function () {
          window.analytics.track("Closed the cancelled popup", {
            plan_id: self.subscription.id,
            plan_name: self.subscription.name,
          });
        },
      });
    },
    scrollToPlans() {
      document.getElementById("plansCards").scrollIntoView();
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad",
      });
    },
  },
};
</script>

<style scoped></style>
