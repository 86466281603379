<template>
  <div class="members mt-4">
    <v-skeleton-loader
      class="transparent"
      type="table-thead, table-tbody"
      v-if="loader"
    />

    <!-- <noData v-else-if="noData" firstText="sorry you have no Invites..." /> -->
    <div v-else-if="noData" class="no-data-found">
      <inline-svg
        class="mt-6 svg-icon empty_icon"
        src="/media/placeholders/no_app_members.svg"
      />
      <p class="mt-4 bold-text bodyFont text-dark text-center">No invites</p>
      <p class="mt-4 mb-5 bodyFont text-dark text-center">
        Invite team members to either manage your app or to test your app
        internally from our App
      </p>
    </div>
    <div v-else>
      <v-data-table
        :headers="headers"
        :items="data"
        class="
          v-card v-sheet v-sheet--outlined
          hide-v-data-footer__select
          radius-10
          release-table
        "
        :page.sync="pageValue"
        :server-items-length="totalItems"
      >
        <template #item.idAndEmail="{ item }">
          <b>
            {{ item.email ? item.email : item.id }}
          </b>
          <div class="text-muted">
            {{ "By" + " " + item.invited_by.name }}
          </div>
        </template>

        <template #item.role="{ item }">
          {{ item.role.name }}
        </template>

        <template #item.totalApps="{ item }">
          <v-btn icon @click="openAppsModal(item.apps)" class="primary--text">
            <b>
              {{ item.apps.length }}
            </b>
          </v-btn>
        </template>
        <template v-if="urlList" #item.total_joins="{ item }">
          {{ item && item.meta ? item.meta.total_joins || "-" : "-" }}
        </template>
        <template #item.status="{ item }">
          {{ item.status == 1 ? "Active" : "In active" }}
        </template>
        <template #item.lastUpdate="{ item }">
          <vue-hoverable-date
            :date="parseInt(item.created_at)"
          ></vue-hoverable-date>
        </template>

        <template #item.action="{ item }">
          <action-dropdown v-if="canManageInvite">
            <template #list>
              <v-list-item @click="setEdit(item)">
                <v-icon small>edit</v-icon>
                edit
              </v-list-item>
              <v-list-item :id="item.id" @click="copyText(item.id)">
                <v-icon small v-text="'mdi-content-copy'" />
                Copy URL
              </v-list-item>
              <v-list-item @click="openDelete(item)">
                <v-icon small>cancel</v-icon>
                Withdraw invite
              </v-list-item>
            </template>
          </action-dropdown>
        </template>
      </v-data-table>
    </div>

    <!-- here we have the edit navigation drawer and the leave dialog -->
    <SidePanel @close="drawer = false" v-model="drawer" :loading="busy">
      <!--  -->
      <div v-if="drawer">
        <v-card-title class="display-1 justify-center mb-8 mt-n3">
          Edit
        </v-card-title>
        <div class="mt-4">
          <v-layout class="mt-4" wrap>
            <v-flex xs12>
              <AppAutoComplete
                class="mb-4"
                v-model="selectedapps"
                :initial-data="initailData"
                multiple
              />
            </v-flex>
          </v-layout>
        </div>
        <Roles
          v-if="drawer"
          :update-roles="updateroles"
          :permission="selectedPermission"
          @updateTheRole="saveTheRole"
          @updateRoles="saveRoles"
        />
        <div>
          <v-btn
            @click="updateInvite"
            :loading="busy"
            class="main primary large wide mx-auto mt-13"
            >Save
          </v-btn>
        </div>
      </div>
    </SidePanel>

    <!-- <Modal
      title="Delete invite"
      message="Are you sure you want to delete this invite? There's no undo for this action."
      @onConfirm="onConfirm"
      @onCancel="onCancel"
      :loading="loading"
      v-model="modal"
    /> -->

    <Modal v-model="modal">
      <template #message>
        <v-card-title class="font-weight-bold">Delete invite</v-card-title>
        <v-card-text class="message">
          <p>Are you sure you want to delete this invite?</p>
          <p>There's no undo for this action.</p>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn class="main" @click="onCancel">no</v-btn>
          <v-btn class="main primary" @click="onConfirm" :loading="loading"
            >yes</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>

    <v-snackbar v-model="notify.show" :color="notify.color">
      {{ notify.message }}
    </v-snackbar>
    <Modal v-model="showAppModal" scrollable width="560" persistent>
      <template #message v-if="showAppModal">
        <v-card-title>
          <!-- <form class="input-group-search-in-apps mr-2">
              <div
                class="input-group input-group-sm input-group-solid"
                style="height: 100%"
              >
                <div class="input-group-append">
                  <span class="input-group-text pr-0">
                    <span class="svg-icon">
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                    </span>
                  </span>
                </div>
                <v-text-field
                  filled
                  placeholder="Search"
                  v-model="search"
                ></v-text-field>
              </div>
            </form> -->
          <div class="mr-1 mb-4">
            <b>{{ modalsApps.length + " " }} </b> Apps
          </div>
          <v-spacer />
        </v-card-title>
        <v-card-text class="pb-3">
          <div
            class="text-center bold-text title"
            v-if="!searchedModalsApp.length"
          >
            {{
              !search
                ? "No apps added in this invite"
                : "No app match " + search + " keys"
            }}
          </div>
          <div v-else>
            <v-card
              class="mb-2 px-2"
              v-for="(app, index) in searchedModalsApp"
              :key="index"
              outlined
            >
              <v-row align="center">
                <v-col>
                  <div class="d-flex align-center">
                    <div class="flex-shrink-0 mr-4">
                      <v-avatar size="50">
                        <img
                          :src="getAppImage(app)"
                          @error="replaceDefaultUserImage"
                        />
                      </v-avatar>
                    </div>
                    <div class="ff-open-sans-bold">{{ app.name }}</div>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                  :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
                >
                  <PlatformIcon
                    android
                    :color="
                      app.platforms.includes('android') ? 'grey' : 'primary'
                    "
                  />
                  <span class="mx-2"></span>
                  <PlatformIcon
                    apple
                    :color="app.platforms.includes('ios') ? 'grey' : 'primary'"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </template>
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import AppAutoComplete from "@/view/components/Common/AppAutoComplete.vue";
import Roles from "@/view/components/Team/Members/Roles.vue";
import { DELETE_INVITES, UPDATE_INVITES } from "@/store/team/invite.module";
import { currentTimestamp } from "@/core/services/helper.service";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapState, mapMutations, mapGetters } from "vuex";
import ActionDropdown from "@/view/components/Common/ActionDropdown";

export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    page: {
      type: [Number, String],
      default: 1,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    urlList: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: [Number, String],
      default: "",
    },
  },
  components: { ActionDropdown, AppAutoComplete, Roles },
  data() {
    return {
      dialog: null,
      drawer: null,
      modal: false,
      busy: false,
      loading: false,
      selectedRoles: "",
      search: "",
      copyAlert: false,
      show2: false,
      selectedapps: "",
      initailData: [],
      slectedId: "",
      notify: { show: false },
      inviteMessage: "",
      selectedPermission: null,
      modalsApps: [],
      pageValue: 1,
      updateroles: "",
      selectedDetails: {},
      showAppModal: false,
      headers: [
        {
          text:
            this.data && this.data.length && this.data[0].email
              ? "Email"
              : "ID",
          align: "start",
          sortable: false,
          width: "30%",
          value: "idAndEmail",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
        },
        {
          text: "Total Apps",
          align: "center",
          sortable: false,
          width: "25%",
          value: "totalApps",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          width: "25%",
          value: "status",
        },
        {
          text: "Last Update",
          align: "start",
          sortable: false,
          width: "25%",
          value: "lastUpdate",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "10%",
          value: "action",
        },
      ],
    };
  },
  computed: {
    totalItems() {
      return this.totalCount;
    },
    noData() {
      return !this.data || (this.data && !this.data.length);
    },
    ...mapState({
      roles: (state) => get(state, "team.roles", ""),
    }),
    ...mapGetters({
      getInviteUrl: "getInviteUrl",
    }),
    searchedModalsApp() {
      return this.modalsApps.filter(
        (app) =>
          app &&
          app.name &&
          app.name.toLowerCase().includes(this.search.toLowerCase() || "")
      );
    },
  },
  watch: {
    pageValue(val) {
      this.$emit("update:page", val);
    },
    page(val) {
      this.pageValue = val;
    },
    copyAlert() {
      this.notifyUserMessage("Invite URL copied successfully");
    },
    drawer() {
      if (!this.drawer) {
        (this.selectedPermission = null), (this.selectedRoles = null);
      }
    },
  },
  created() {
    if (this.urlList) {
      this.headers.splice(3, 0, {
        text: "Total joins",
        align: "start",
        width: "10%",
        sortable: false,
        value: "total_joins",
      });
    }
  },
  methods: {
    copyText(id) {
      let input = document.createElement("input");
      let copyText = document.body.appendChild(input);
      copyText.value = `${this.getInviteUrl}${id}`;
      copyText.select();
      document.execCommand("copy");
      this.notifyUserMessage("Invite URL copied successfully");
      if (input) {
        document.body.removeChild(input);
      }
      Vue.prototype.$analytics.logEvent("copy_invite_url");
    },
    openAppsModal(details) {
      this.modalsApps = [...details];
      this.showAppModal = true;
    },
    setEdit(details) {
      this.selectedDetails = { ...details };
      this.initailData = [...details.apps];
      this.inviteMessage = details.message;
      this.updateroles = { ...details.role };
      this.selectedPermission = { ...details.permissions };
      this.slectedId = details.id;
      this.$nextTick(() => {
        this.drawer = true;
      });
    },
    ...mapMutations({
      appendEmail: "appendInviteByEmail",
      appendUrl: "appendInviteByUrl",
      removeFromEmail: "removeFromEmail",
      removeFromUrl: "removeFromUrl",
    }),
    updateInvite() {
      if (this.busy) return;
      this.busy = true;
      let data = {
        apps: this.selectedapps.map((app) => app.id),
        role_id: this.mainRole,
        permissions: this.selectedRoles,
      };

      data.role_id == null || false || "" ? delete data["role_id"] : "";
      isEmpty(data.permissions) ? (data.permissions = this.selectedRoles) : "";
      this.$store
        .dispatch(UPDATE_INVITES, {
          id: this.slectedId,
          data: JSON.stringify(data),
        })
        .then(() => {
          this.busy = false;
          const dataToAppend = {
            id: this.slectedId,
            invited_by: { ...this.selectedDetails.invited_by },
            apps: [...this.selectedapps],
            status: 1,
            meta: {},
            email: this.selectedDetails.email || "",
            permissions: { ...data.permissions },
            role: {
              id: data.role_id,
              name: this.roles.find((el) => el.id === data.role_id).name,
            },
            created_at: currentTimestamp(),
          };
          if (this.selectedDetails.email) {
            this.appendEmail(dataToAppend);
            this.$emit("makeActive", "email");
          } else {
            this.appendUrl(dataToAppend);
            this.$emit("makeActive", "url");
          }
          this.drawer = false;

          Vue.prototype.$analytics.logEvent("update_invite");
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
          Vue.prototype.$analytics.logEvent("update_invite_error");
        });
    },
    openDelete(details) {
      this.slectedId = details.id;
      this.selectedDetails = { ...details };
      this.modal = true;
      Vue.prototype.$analytics.logEvent("delete_invite_start");
    },
    onCancel() {
      this.modal = false;
      Vue.prototype.$analytics.logEvent("cancel_delete_invite");
    },
    onConfirm() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_INVITES, this.slectedId)
        .then((response) => {
          this.modal = false;
          this.loading = false;
          this.notifyUserMessage(response.message);
          if (this.selectedDetails && this.selectedDetails.email) {
            this.removeFromEmail(this.slectedId);
          } else if (this.selectedDetails) {
            this.removeFromUrl(this.slectedId);
          }

          Vue.prototype.$analytics.logEvent("delete_invite");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
          this.modal = false;
          Vue.prototype.$analytics.logEvent("delete_invite_error");
        });
    },
    saveTheRole(v) {
      this.mainRole = v;
    },
    saveRoles(v) {
      this.selectedRoles = v;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/sass/pages/manage-Team";
// @import "@/assets/sass/custom/table-theme";
</style>
<style lang="scss">
.col-with-dropdown {
  position: relative;
  padding: 2px;

  .the-drop-down {
    position: absolute;
    bottom: calc(50% - 19px);
    right: 10px;
  }
}
</style>
