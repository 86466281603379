<template>
  <div>
    <v-card-title
      id="updateTeamTitle"
      class="display-1 justify-center mb-8 mt-n3"
    >
      Edit
    </v-card-title>
    <div>
      <div class="mt-4">
        <v-row class="mt-4" wrap>
          <v-col cols="12">
            <v-text-field
              v-model="update.name"
              filled
              placeholder="Team name"
              counter="30"
              id="updateTeamName"
              v-validate="'required|min:3|max:27|name_field_validation'"
              v-bind="veeValidate('Name', 'Name')"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="mt-4">
        <template>
          <v-textarea
            class="mt-8"
            v-model="update.description"
            v-validate="'max:600'"
            id="updateTeamDesc"
            counter="600"
            v-bind="veeValidate('Description', 'Description')"
            placeholder="Description about this team  (Optional)"
            filled
            auto-grow
          ></v-textarea>
        </template>
      </div> -->
    </div>
    <v-btn
      :loading="busy"
      :disabled="!update.name || errors.any()"
      id="updateTeamBtn"
      @click="updateTeam"
      class="main large wide primary mx-auto my-3"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { UPDATE_TEAM } from "@/store/team/manageTeam.module";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import { Validator } from "vee-validate";

Validator.extend("name_field_validation", {
  getMessage: (field) => {
    return `${field} should not include any special characters `;
  },
  // Returns a boolean value
  validate: (value) => {
    const regex = new RegExp("^[0-9A-Za-z(-_.)ds ]+$");
    return regex.test(value);
  },
});

export default {
  mixins: [veeValidate],
  props: {
    info: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      update: {
        name: "",
        description: "",
      },
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
    }),
  },
  created() {
    if (this.info) {
      this.update = this.info;
    }
  },
  methods: {
    ...mapMutations({
      setTeamName: "setTeamName",
      updateTeamName: "updateTeamName",
    }),
    async updateTeam() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        this.$store
          .dispatch(UPDATE_TEAM, this.update)
          .then((response) => {
            this.setTeamName(this.update.name);
            this.updateTeamName({ id: this.getTeamId, name: this.update.name });
            this.$emit("close", this.update.name);
            this.notifyUserMessage({
              message: response.message || "Successfully updated",
              timeout: "6000",
            });
            this.busy = false;
            this.update = {
              name: "",
              description: "",
            };
            Vue.prototype.$analytics.logEvent("update_team");
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(err.message);
            Vue.prototype.$analytics.logEvent("update_team_error");
          });
      }
    },
  },
};
</script>

<style scoped></style>
