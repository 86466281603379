<template>
  <v-card
    tile
    flat
    class="white d-flex justify-center align-center page-loader"
  >
    <div class="text-center">
      <img alt="Logo" :src="logo || loaderLogo" width="150" class="mb-4" />
      <br />
      <v-progress-linear size="30" indeterminate color="primary" height="3" />
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    }
  }
};
</script>
<style lang="scss" scoped>
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999999;
}
</style>
