<template>
  <v-card flat>
    <v-card-title>
      App List
      <v-spacer />
      <v-btn
        depressed
        id="addAppMemberBtn"
        class="main primary"
        :disabled="selectedMember.status == 2"
        @click="showMemberAddFormModal"
        v-if="loggedUser !== currentMember"
      >
        <v-icon left>mdi-plus</v-icon> Add App
      </v-btn>
    </v-card-title>
    <!--  -->
    <template v-if="loading">
      <div class="mx-4 mt-4">
        <v-skeleton-loader type="heading" class="mb-3" />
        <v-card v-for="i in 8" :key="i" outlined class="mb-2 px-3">
          <v-skeleton-loader
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-card>
      </div>
    </template>
    <template v-else>
      <div id="addAppForm" v-if="showMemberAddForm">
        <v-card-text class="message">
          <app-auto-complete
            id="appSelectAutocomplete"
            v-model="selectedApps"
            :exclude-list="modalsApps"
            add-app
            :member-id="member.id"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!selectedApps"
            depressed
            :loading="busy"
            @click="addMember"
            color="primary"
            class="mx-2 text-transform-none"
            id="createAppBtn"
          >
            Add
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title v-if="searchedModalsApp.length">
        <b class="mr-1">{{ modalsApps.length + " " }} </b> Apps
      </v-card-title>
      <v-card-text>
        <h3 v-if="member" class="col-12 darkGrey--text text-center">
          {{ member.name }}
        </h3>
        <v-divider class="mb-5" />
        <div v-if="modalsApps.length">
          <div
            class="text-center bold-text title"
            v-if="!searchedModalsApp.length"
          >
            {{
              !search
                ? "No apps added in this invite"
                : "No app match " + search + " keys"
            }}
          </div>
          <div v-else>
            <v-card
              class="mb-2 px-2"
              v-for="(app, index) in searchedModalsApp"
              :key="index"
              outlined
            >
              <v-row align="center">
                <v-col cols="7">
                  <div class="d-flex align-center">
                    <div class="flex-shrink-0 mr-4 ml-1" style="height: 50px">
                      <img
                        :src="getAppImage(app)"
                        width="50"
                        @error="replaceDefaultAppImage"
                      />
                    </div>
                    <div class="ff-open-sans-bold">{{ app.name }}</div>
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  class="pr-0"
                  :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
                >
                  <PlatformIcon
                    class="mr-3"
                    android
                    :color="
                      app.platforms.includes('android') ? 'primary' : 'grey'
                    "
                  />
                  <PlatformIcon
                    apple
                    :color="app.platforms.includes('ios') ? 'primary' : 'grey'"
                  />
                </v-col>
                <v-col class="px-0" cols="2">
                  <ActionDropdown v-if="loggedUser !== currentMember">
                    <template #list>
                      <v-list-item
                        id="removeMemberBtn"
                        @click="(selectedApp = app.id), (removeDialog = true)"
                      >
                        <v-icon small>cancel</v-icon>
                        remove
                      </v-list-item>
                    </template>
                  </ActionDropdown>
                  <!-- <v-btn
                    id="remove-app-from-list"
                    icon
                    @click="(selectedApp = app.id), (removeDialog = true)"
                  >
                    <v-icon color="grey">cancel</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
        <div v-else class="no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            src="/media/placeholders/no_apps.svg"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            No apps
          </p>
          <p
            class="
              mt-4
              mb-5
              empty-sub-title
              font-weight-normal
              text-dark text-center
            "
          >
            Add apps to members to grant them access to install or manage it
          </p>
        </div>
      </v-card-text>
    </template>
    <Modal
      v-model="removeDialog"
      title="Remove app"
      message="Are you sure you want to remove this app from this member? There's no undo for this action."
      @onConfirm="removeApps"
      @onCancel="cancelRemoveDialog"
      :loading="loadingConfirm"
    >
    </Modal>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import AppAutoComplete from "@/view/components/Common/AppAutoComplete";
import { ADD_INVITE } from "@/store/apps/app.module";
import { DELETE_MEMBER } from "@/store/apps/appmember.module.js";
export default {
  components: { AppAutoComplete },
  props: {
    modalsApps: {
      type: Array,
      default() {
        return {};
      },
    },
    member: {
      type: Object,
      required: true,
    },
    selectedMember: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // this both are IDs
    loggedUser: String,
    currentMember: String,
    // // //
  },
  data() {
    return {
      search: "",
      selectedApps: null,
      loadingConfirm: false,
      selectedApp: "",
      busy: false,
      removeDialog: false,
      notify: {
        show: false,
        message: "Something went wrong",
        color: "",
      },
      showMemberAddForm: false,
    };
  },
  computed: {
    searchedModalsApp() {
      return this.modalsApps.filter((app) =>
        app.name.toLowerCase().includes(this.search.toLowerCase() || "")
      );
    },
  },
  watch: {
    member() {},
  },
  created() {
    this.setAppsInitialList([]);
  },
  methods: {
    ...mapMutations({
      setAppsInitialList: "setAppsInitialList",
    }),
    cancelRemoveDialog() {
      this.removeDialog = false;
      Vue.prototype.$analytics.logEvent("cancel_remove_member");
    },
    showMemberAddFormModal() {
      this.showMemberAddForm = !this.showMemberAddForm;
      Vue.prototype.$analytics.logEvent("start_add_member");
    },
    addMember() {
      if (this.busy) return;
      this.busy = true;
      const dataToPost = {
        app_id: this.selectedApps.id,
        name: this.selectedApps.name,
        platforms: this.selectedApps.platforms || [],
        details: {
          add_all_team_members: false,
          ids: [this.member.id],
        },
      };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then((response) => {
          this.notifyUserMessage(
            response.message || "Successfully added the member"
          );
          this.selectedApps = null;
          this.setAppsInitialList([]);
          this.showMemberAddForm = false;
          this.busy = false;
          Vue.prototype.$analytics.logEvent("open_add_member");
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          Vue.prototype.$analytics.logEvent("open_add_member_error");
          this.busy = false;
        });
    },
    removeApps() {
      if (this.loadingConfirm) return;
      this.loadingConfirm = true;
      this.$store
        .dispatch(DELETE_MEMBER, {
          app_id: this.selectedApp,
          member_id: this.member.id,
        })
        .then((response) => {
          this.loadingConfirm = false;
          this.notifyUserMessage(
            response.message || "Successfully deleted the app"
          );
          this.setAppsInitialList([]);
          this.removeDialog = false;

          Vue.prototype.$analytics.logEvent("remove_member_from_app");
        })
        .catch((err) => {
          this.loadingConfirm = false;
          this.removeDialog = false;
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          Vue.prototype.$analytics.logEvent("remove_member_from_app_error");
        });
    },
  },
};
</script>

<style scoped></style>
