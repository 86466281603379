var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-4",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","id":"storage-select-box"}},[_c('v-autocomplete',{ref:"drop",staticClass:"tag-input",attrs:{"items":_vm.searchItems.filter(function (x) { return x.id !== _vm.hideAppId; }),"label":_vm.multiple ? 'Select App(s) ' : 'Select App *',"item-value":"id","item-text":"name","placeholder":"Select App","append-icon":"mdi-chevron-down","clearable":_vm.multiple,"loading":_vm.loading,"multiple":_vm.multiple,"deletable-chips":_vm.multiple,"hide-selected":_vm.searchItems.length > 1,"small-chips":"","return-object":"","search-input":_vm.search,"filled":"","hide-details":"","attach":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"focusin":function($event){$event.preventDefault();return _vm.fetchData($event)},"change":function($event){return _vm.$emit('change')},"blur":function($event){return _vm.$emit('blur')}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('v-list-item-title',{attrs:{"id":"data-item"},domProps:{"textContent":_vm._s(data && data.item ? data.item.name : '')}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":data && data.item.public ? 'primary' : 'success'},domProps:{"textContent":_vm._s(
                      data && data.item.public
                        ? 'mdi-lock-open-outline'
                        : 'mdi-lock-outline'
                    )}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(data && data.item.public ? "Can be accessed publicly" : "Accessed by your team members only")+" ")])]),(data && data.item && data.item.storage)?_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","large":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"height":"24","src":data.item.storage.icon
                            ? _vm.getStorageImg(data.item.storage.icon)
                            : 'testapp.io'}})])]}}],null,true)},[(data.item.storage)?_c('span',[_c('div',[_vm._v("Storage")]),_vm._v(" "+_vm._s(data.item.storage.name || "testapp.io")+" ")]):_vm._e()])],1):_vm._e()],1)],1)]}},{key:"append-item",fn:function(){return [(!_vm.loading)?_c('v-list-item',{style:(_vm.searchItems.length ? null : 'border:none'),on:{"click":_vm.openApp}},[_c('v-icon',{staticStyle:{"width":"32px"},attrs:{"left":"","color":"primary"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"primary--text"},[_vm._v("Create new app ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}},[_c('template',{slot:"no-data"},[_c('div',{},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center pa-4"},[_c('ContentLoader',{attrs:{"size":"25"}})],1):_vm._e()])])],2)],1)],1),_c('Modal',{model:{value:(_vm.openAppForm),callback:function ($$v) {_vm.openAppForm=$$v},expression:"openAppForm"}},[_c('template',{slot:"message"},[_c('v-card-title',{staticClass:"display-1 justify-center mb-4"},[_vm._v(" Create app ")]),_c('v-card-text',[(_vm.openAppForm)?_c('create-app-step',{attrs:{"new-app-name":_vm.newAppName,"is-auto-complete":""},on:{"close":function($event){_vm.openAppForm = false},"goNext":_vm.afterCreateApp}}):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }