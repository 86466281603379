<template>
  <div>
    <action-dropdown
      v-if="!getInactiveUserMessage && !getIsUserDisabled"
      button="Invite Member"
      icon="person_add_alt_1"
      :largeBtn="iGetStarted"
      :default-open="active"
      :display-text="displayText"
    >
      <template #list>
        <!-- item in the drop down -->

        <v-list-item id="inviteByEmailBtn" @click="openEmailDrawer">
          <v-icon small>mdi-email-outline</v-icon>by email
        </v-list-item>

        <!-- item in the drop down -->

        <v-list-item id="inviteByUrlBtn" @click="openURLDrawer">
          <v-icon small>mdi-link</v-icon>
          by URL
        </v-list-item>
      </template>
    </action-dropdown>
    <!-- this is a sidepanel for invite by URL it should be in the dashboardEmpty components but here fine -->
    <SidePanel
      v-model="inviteDrawer"
      :loading="inviteLoader"
      :hide-overlay="hideOverlay"
    >
      <div v-if="inviteDrawer" class="invite-drawer">
        <div class="text-center mb-13 display-1 bold-text">
          {{ activeDrawer == "byEmail" ? "Invite by Email" : "Invite by URL" }}
        </div>
        <h2 class="mt-12 text-center">{{ info.name }}</h2>
        <!--        <div class="text-center mb-8">-->
        <!--          <span class="mr-2">Total member: </span>-->
        <!--          <b-->
        <!--            >{{ getTotalMembersCount }}/{{-->
        <!--              getTeamPlanLimits.members || "∞"-->
        <!--            }}</b-->
        <!--          >-->
        <!--        </div>-->
        <!--  -->
        <invite-members-loader v-if="loading" />
        <!-- <div>
          <ContentLoader></ContentLoader>
        </div> -->
        <div v-else>
          <div v-if="inviteURL && activeDrawer == 'byURL'">
            <v-btn id="invite-url-back" class="main mb-7" @click="refreshUrl"
              ><v-icon left>keyboard_arrow_left</v-icon> back</v-btn
            >

            <v-text-field
              disabled
              label="Invite URL"
              filled
              id="inviteUrlField"
              v-model="inviteURL"
              placeholder="invite's URL field"
              messages="copy it, and send it to your friends"
            ></v-text-field>
            <v-btn
              class="main primary mx-auto mt-6"
              id="copy-invite-url"
              @click="copy(inviteURL)"
            >
              copy
            </v-btn>
          </div>

          <!--  -->
          <div v-else>
            <div class="mt-4" v-if="activeDrawer == 'byEmail'">
              <v-layout class="mt-4" wrap>
                <v-flex xs12>
                  <AutoComplete
                    label="Emails"
                    id="inviteEmailField"
                    placeholder="Add email(s) with space or comma separated"
                    @update="setEmails"
                    :email="true"
                  />
                </v-flex>
              </v-layout>
            </div>
            <div class="mt-4">
              <v-row class="mt-4" wrap>
                <v-col xs12>
                  <AppAutoComplete
                    class="mb-4"
                    v-model="selectedapps"
                    id="inviteAppAutocomplete"
                    ref="app-autocomplete"
                    multiple
                  />
                </v-col>
              </v-row>
            </div>
            <div class="mt-4">
              <template>
                <v-textarea
                  class="mt-8"
                  v-model="inviteMessage"
                  label="Message"
                  id="inviteMessageField"
                  counter="200"
                  v-validate="'max:200'"
                  v-bind="veeValidate('Message', 'Message')"
                  placeholder="Your message for this invite... (Optional)"
                  filled
                  auto-grow
                ></v-textarea>
              </template>
            </div>

            <Roles
              ref="theRoleComponent"
              @updateTheRole="saveTheRole"
              @updateRoles="saveRoles"
            />

            <div>
              <v-btn
                :disabled="!ifallDone || inviteLoader"
                :loading="inviteLoader"
                class="main primary large wide mx-auto mt-13"
                @click="getAll"
                id="inviteCreateBtn"
                data-test="invite-btn"
                >Invite</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  </div>
</template>

<script>
import Vue from "vue";
import Roles from "@/view/components/Team/Members/Roles.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
import { isEmpty, get } from "@/core/services/helper.service";
import { currentTimestamp } from "@/core/services/helper.service";
import { CREAT_INVITE } from "@/store/team/invite.module";
import { INVITE_BY_URL } from "@/store/team/invite.module";
import { GET_ROLES } from "@/store/team/team.module";
import AppAutoComplete from "@/view/components/Common/AppAutoComplete.vue";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import veeValidate from "@/mixins/veeValidate";
import InviteMembersLoader from "@/view/components/Common/loaders/InviteMembers.vue";
export default {
  props: {
    isMember: {
      type: Boolean,
      default: false,
    },
    iGetStarted: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hideOverlay: Boolean,
    displayText: String,
  },
  mixins: [veeValidate],
  data() {
    return {
      // these show for the tooltips
      show2: null,
      show3: null,
      search: null,
      inviteMessage: null,
      selectedEmails: null,
      selectedapps: [],
      selectedRoles: null,
      loading: false,
      mainRole: null,
      // two drawer, byEmail, byURL
      activeDrawer: null,
      inviteDrawer: false,
      URL: null,
      copyAlert: null,
    };
  },
  watch: {
    inviteDrawer() {
      this.selectedEmails = null;
      this.refreshUrl();
    },
    copyAlert() {
      this.notifyUserMessage("Invite URL copied successfully");
    },
  },
  components: {
    ActionDropdown,
    Roles,
    AppAutoComplete,
    InviteMembersLoader,
  },
  computed: {
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
      appsDropdown: (state) => get(state, "apps.appsDropdown", []),
      errMsg: (state) => get(state, "invite.inviteErrMsg", ""),
      succMsg: (state) => get(state, "invite.inviteSuccMsg", ""),
      inviteURL: (state) => get(state, "invite.inviteURL", ""),
      roles: (state) => get(state, "team.roles", ""),
      inviteLoader: (state) => get(state, "invite.inviteLoader", ""),
    }),
    ...mapGetters({
      currentUser: "currentUser",
      emailList: "getEmailList",
      getTotalMembersCount: "getTotalMembersCount",
      getTeamPlanLimits: "getTeamPlanLimits",
      urlList: "getUrlList",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    // this is for disbling the invite button if ther is no email
    ifallDone() {
      // let done = false;
      // if (this.activeDrawer == "byEmail") {
      //   this.selectedEmails ? (done = true) : (done = false);
      // } else {
      //   done = true;
      // }
      // return done;
      return true;
    },
  },
  created() {},
  methods: {
    setUserInInviteProcces(v) {
      this.$store.commit("whenUserInInviteProcess", v);
    },
    refreshUrl() {
      this.$store.commit("setInviteURL", null);
    },
    loadUrlInvites() {
      this.loadData();
      // we have to tabs in Invites one for urls, and one for emails
      this.activeTab = "url";

      // if ther are no urlInvites call API, else don't call teh API cuz we alraedy have the data
      isEmpty(this.urlInvites)
        ? this.$store
            .dispatch(INVITE_BY_URL)
            .catch((err) => this.notifyErrorMessage({ message: err.message }))
        : "";
    },
    async loadData() {
      if (isEmpty(this.roles)) {
        if (this.loading) return;
        this.loading = true;
        await this.$store.dispatch(GET_ROLES).catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
        this.loading = false;
      }
      // when you open the drawer, you need to empty the fields
      this.selectedEmails = {};
      this.selectedapps = {};
      if (this.$route.query.appId) {
        this.selectedapps = [{ name: "", id: this.$route.query.appId }];
      }
      this.inviteMessage = "";
    },
    // we have one drawer for each of byEmail & byURL
    openEmailDrawer() {
      this.inviteDrawer = true;
      this.loadData();
      this.activeDrawer = "byEmail";
    },
    openURLDrawer() {
      this.inviteDrawer = true;
      this.loadData();
      this.activeDrawer = "byURL";
    },
    ...mapMutations({
      appendEmail: "appendInviteByEmail",
      appendUrl: "appendInviteByUrl",
    }),

    // this getAll method call when submit
    getAll() {
      // if (this.activeDrawer == "byEmail" && !this.selectedEmails.length) {
      //   this.notifyErrorMessage({ message: "Please enter email to invite." });
      //   return;
      // }
      // get all the data
      let data = {};
      // why trycatch block? because .map((app) => app.id) give us an error if no apps
      try {
        data = {
          emails: this.selectedEmails,
          apps: this.selectedapps.map((app) => app.id),
          message: this.inviteMessage,
          role_id: this.mainRole,
          permissions: this.selectedRoles,
        };
      } catch (error) {
        data = {
          emails: this.selectedEmails,
          apps: "",
          message: this.inviteMessage,
          role_id: this.mainRole,
          permissions: this.selectedRoles,
        };
      }

      // if there are no emails delete the propertie email from the object, cuz we don't need to send email=""
      isEmpty(data.emails) ? delete data["emails"] : "";

      // if no apps delete it
      isEmpty(data.apps) ? delete data["apps"] : "";

      // msg as well
      isEmpty(data.message) ? delete data["message"] : "";

      // roles as well
      isEmpty(data.role_id) ? delete data["role_id"] : "";

      // if no permissions set the selected roles to the permissions
      isEmpty(data.permissions) ? (data.permissions = this.selectedRoles) : "";
      let dataToAppend = {};
      this.$store
        .dispatch(CREAT_INVITE, JSON.stringify(data))
        .then((response) => {
          // this.activeDrawer = false;
          dataToAppend = {
            id: response.id,
            invited_by: { name: this.currentUser.name },
            apps:
              this.selectedapps && this.selectedapps.length
                ? [...this.selectedapps]
                : "",
            email:
              data.emails && data.emails.length ? data.emails.join(",") : null,
            permissions: { ...data.permissions },
            role: {
              id: data.role_id,
              name: this.roles.find((el) => el.id === data.role_id).name,
            },
            create: true,
            status: 1,
            append:
              this.isMember && !this.emailList && !this.urlList ? false : true,
            created_at: currentTimestamp(),
          };
          if (data.emails && data.emails.length) {
            data.emails.forEach((email) => {
              dataToAppend = {
                id: response.id,
                invited_by: { name: this.currentUser.name },
                apps:
                  this.selectedapps && this.selectedapps.length
                    ? [...this.selectedapps]
                    : "",
                email: email,
                status: 1,
                permissions: { ...data.permissions },
                role: {
                  id: data.role_id,
                  name: this.roles.find((el) => el.id === data.role_id).name,
                },
                create: true,
                append:
                  this.isMember && !this.emailList && !this.urlList
                    ? false
                    : true,
                created_at: currentTimestamp(),
              };
              this.appendEmail(dataToAppend);
            });
            this.inviteDrawer = false;

            // solving scroll issue that <SidePanel /> causing!
            document.documentElement.classList.remove("overflow-y-hidden");
            this.$emit("makeActive", {
              type: "email",
              reload: data.emails.length > 1,
            });
          } else {
            this.appendUrl(dataToAppend);
            this.$emit("makeActive", { type: "url", reload: false });
            if (this.isMember) {
              this.inviteDrawer = false;

              // solving scroll issue that <SidePanel /> causing!
              document.documentElement.classList.remove("overflow-y-hidden");
            }
          }
          this.notifyUserMessage(response.message || "Invited successfully");

          Vue.prototype.$analytics.logEvent("new_invite");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong");
          Vue.prototype.$analytics.logEvent("new_invite_error");
        });

      // this.activeDrawer === "byURL" ? "" : (this.inviteDrawer = false);
      // this.drawer = false
    },

    // get emails data from the autocomplete component
    setEmails(emails) {
      this.selectedEmails = [];
      this.selectedEmails = [...new Set(emails)];
    },

    // set the apps
    setApps(apps) {
      this.selectedapps = apps;
    },

    // set the role name
    saveTheRole(v) {
      this.mainRole = v;
    },

    // set the selected roles
    saveRoles(v) {
      this.selectedRoles = v;
    },

    // this is for the tabs => [1]-Create Team  [2]-Plan  [3]-Members
    nextButtonStep1() {
      this.step = 2;
    },

    // as well
    nextButtonStep2() {
      this.step = 3;
    },

    // open side bar
    openInviteDrawer() {
      this.$store.commit("setInviteDrawer2", true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
